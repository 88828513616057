import { ChangeDetectionStrategy, Component, Injector, Input, OnInit}  from '@angular/core';
import { UserResult } from 'app/api/models';
import { UsersService } from 'app/api/services';
import { BaseDashboardComponent } from 'app/home/dashboard/base-dashboard.component';
import { BehaviorSubject } from 'rxjs';
import { ActiveMenu, Menu } from 'app/shared/menu';
import { SearchUsersParams } from '../../api/models/search-users-params';

/**
 * Displays the latest users
 */
@Component({
  selector: 'featured-users',
  templateUrl: 'featured-users.component.html',
  // Edited to use its own stylesheet, was previously latest-ads
  styleUrls: ['featured-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedUsersComponent extends BaseDashboardComponent implements OnInit {

  @Input() groups: string[];
  @Input() max: number;

  users$ = new BehaviorSubject<UserResult[]>(null);

  constructor(injector: Injector,
    private usersService: UsersService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.addSub(this.usersService.searchUsers( { body: this.searchUsersParams }).subscribe(ads => {
      this.users$.next(ads);
    }));
  }

  get searchUsersParams(): SearchUsersParams {
    return {
      // addressResult: UserAddressResultEnum.NONE,
      groups: this.groups,
      ignoreProfileFieldsInList: true,
      // orderBy: UserOrderByEnum.CREATION_DATE,
      profileFields: ['image:true'],
      // fields: ['id', 'display', 'image'],
      skipTotalCount: true,
      pageSize: this.max,
      driver: 'DRIVER'
    };
  }

  path(user: UserResult): string {
    return `/users/${user.id}/profile`;
  }

  navigate(user: UserResult, event: MouseEvent) {
    this.menu.navigate({
      url: this.path(user),
      menu: new ActiveMenu(Menu.SEARCH_USERS),
      clear: false,
      event: event
    });
  }
}
