import { Component, Optional, Input, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractComponent } from '../../shared/abstract.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnInit } from "@angular/core";

@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent extends AbstractComponent implements OnInit {

  @Input() name: string;
  @Input() url: string;
  safeUrl: any;

  constructor(
      injector: Injector,
      private sanitizer: DomSanitizer,
      @Optional() public modalRef: BsModalRef,
  ) { super(injector) }

  ngOnInit() {
    super.ngOnInit();
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
