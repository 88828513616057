/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Agreement } from '../models/agreement';


/**
 * Provides access to agreements the authenticated user must accept in order to use the system.
 */
@Injectable({
  providedIn: 'root',
})
export class AgreementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listPendingAgreements
   */
  static readonly ListPendingAgreementsPath = '/agreements/pending';

  /**
   * Returns a list with all agreements the authenticated user is pending to accept. Until accepting the pending agreements, the usage of the system is limited.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPendingAgreements()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPendingAgreements$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

  }): Observable<StrictHttpResponse<Array<Agreement>>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.ListPendingAgreementsPath, 'get');
    if (params) {

      rb.query('fields', params.fields);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Agreement>>;
      })
    );
  }

  /**
   * Returns a list with all agreements the authenticated user is pending to accept. Until accepting the pending agreements, the usage of the system is limited.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listPendingAgreements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPendingAgreements(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

  }): Observable<Array<Agreement>> {

    return this.listPendingAgreements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Agreement>>) => r.body as Array<Agreement>)
    );
  }

  /**
   * Path part for operation acceptPendingAgreement
   */
  static readonly AcceptPendingAgreementPath = '/agreements/accept';

  /**
   * Accept all the given agreements
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptPendingAgreement()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptPendingAgreement$Response(params?: {

    /**
     * The identifiers or internal names of the agreements to be accepted
     */
    agreements?: Array<string>;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.AcceptPendingAgreementPath, 'post');
    if (params) {

      rb.query('agreements', params.agreements);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Accept all the given agreements
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptPendingAgreement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptPendingAgreement(params?: {

    /**
     * The identifiers or internal names of the agreements to be accepted
     */
    agreements?: Array<string>;

  }): Observable<void> {

    return this.acceptPendingAgreement$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
