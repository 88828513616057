/* tslint:disable */

/**
 * Error codes for 400 Bad request HTTP status
 * Possible values are:
 * - `general`: Bad request format
 * - `json`: Error in the JSON format
 * - 'invalidCredentials': Error in credentials
 * - 'maxAmountPerDay': Error for max amount per day
 * - 'notEnoughCredits': Error for not enough credtis
 * - 'transferMinimumPayment': Error for transfer minimum payment
 * - 'upperCreditLimitReached': Error for upper credit limit
 * - 'genericError': Error for generic error
 * - 'invalidCaptchaError': Error for wrong captcha
 * - 'illegalArgument': Error for wrong submitted value
 * - 'channel_disabled': Error when user is not UI channel enabled
 */
export enum BadRequestErrorCode {
  GENERAL = 'general',
  JSON = 'json',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  BLOCKED_CREDENTIALS = 'BLOCKED_CREDENTIALS',
  MAX_AMOUNT_PER_DAY_EXCEEDED = 'MAX_AMOUNT_PER_DAY_EXCEEDED',
  NOT_ENOUGH_CREDITS = 'NOT_ENOUGH_CREDITS',
  TRANSFER_MINIMUM_PAYMENT = 'TRANSFER_MINIMUM_PAYMENT',
  UPPER_CREDIT_LIMIT_REACHED = 'UPPER_CREDIT_LIMIT_REACHED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  INVALID_CAPTCHA = 'INVALID_CAPTCHA',
  ILLEGAL_ARGUMENT = 'ILLEGAL_ARGUMENT',
  CHANNEL_DISABLED = 'CHANNEL_DISABLED',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT'
}
