import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  AddressConfiguration,
  AddressConfigurationForUserProfile,
  AddressFieldEnum,
  Country,
  CustomFieldBinaryValues,
  Town
} from 'app/api/models';
import {AddressHelperService} from 'app/core/address-helper.service';
import {CountriesResolve} from 'app/countries.resolve';
import {BaseComponent} from 'app/shared/base.component';
import {truthyAttr} from 'app/shared/helper';
import {ProvincesResolve} from '../provinces.resolve';
import {SubcategoriesResolve} from '../subcategories.resolve';
import {FieldOption} from './field-option';
import {AddressesService} from '../api/services/addresses.service';

/**
 * Form used to input all fields of an address
 */
@Component({
  selector: 'address-form',
  templateUrl: 'address-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent extends BaseComponent implements OnInit {

  towns: Array<Town>;

  constructor(
    injector: Injector,
    private addressHelper: AddressHelperService,
    public countriesResolve: CountriesResolve,
    public provincesResolve: ProvincesResolve,
    public subcategoryResolve: SubcategoriesResolve,
    public addressesService: AddressesService
  ) {
    super(injector);
  }

  @Input() configuration: AddressConfiguration;
  @Input() addressForm: FormGroup;
  @Input() countries: Country[];
  @Input() idPrefix = '';
  @Input() idSuffix = '';
  @Input() binaryValues: CustomFieldBinaryValues;

  profileConfiguration: AddressConfigurationForUserProfile;
  fieldOption: FieldOption[];
  savedTown: any = null;

  private _managePrivacy: boolean | string = false;

  @Input() get managePrivacy(): boolean | string {
    return this._managePrivacy;
  }

  set managePrivacy(manage: boolean | string) {
    this._managePrivacy = truthyAttr(manage);
  }

  getLabel(field: AddressFieldEnum): string {
    return this.addressHelper.addressFieldLabel(field);
  }

  isRequired(field: AddressFieldEnum): boolean {
    return (this.configuration.requiredFields || []).includes(field);
  }

  loadTowns(provinceCode: string): void {
    this.addSub(this.addressesService.listTownsByProvinceCode({id: provinceCode}).subscribe(data => {
      this.towns = data;
    }));
    this.addressForm.get('town').reset();
  }

  onProvinceChange(provinceCode: string) {
    this.loadTowns(provinceCode);
  }

  ngOnInit() {
    super.ngOnInit();
    this.profileConfiguration = this.configuration as AddressConfigurationForUserProfile;
    this.savedTown = this.addressForm.get('town').value;
    if (this.addressForm.get('region').value) {
      this.addSub(this.addressesService.listTownsByProvinceCode({id: this.addressForm.get('region').value}).subscribe(data => {
          this.towns = data;
          if (this.savedTown) {
            this.addressForm.patchValue({town: this.savedTown});
          }
        })
      );

    }
  }

}
