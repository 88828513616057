import { Injectable } from '@angular/core';
import { Town } from 'app/api/models';

@Injectable({
  providedIn: 'root'
})
export class TownsResolve {
  towns: Array<Town>;

  /**
   * Returns the towns name for the given id
   * @param id The town code
   * @param towns The array of towns
   */
  name(id: string, towns: Array<Town>): string {
    const name = towns ? towns[id] : null;
    return name == null ? id : name;
  }
}
