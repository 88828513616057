import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressesService } from 'app/api/services';
import { SingletonResolve } from 'app/singleton.resolve';
import {Province} from './api/models/province';

/**
 * Loads the possible groups for registration
 */
@Injectable({
  providedIn: 'root'
})
export class ProvincesResolve extends SingletonResolve<Province[]> {
  provinces: { [code: string]: string };

  constructor(
    private addressesService: AddressesService
  ) {
    super();
  }

  fetch(): Observable<Province[]> {
    return this.addressesService.listProvinces();
  }

  protected onFetched(data: Province[]) {
    this.provinces = {};
    data.forEach(c => this.provinces[c.code] = c.name);
  }

  /**
   * Returns the province name for the given code
   * @param code The province code
   */
  name(code: string): string {
    const name = this.provinces[code];
    return name == null ? code : name;
  }
}
