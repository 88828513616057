import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { UserOrderByEnum, UserResult } from 'app/api/models';
import { CircuitsService, UsersService } from 'app/api/services';
import { BaseDashboardComponent } from 'app/home/dashboard/base-dashboard.component';
import { BehaviorSubject } from 'rxjs';
import { Menu, ActiveMenu } from 'app/shared/menu';
import { ApiHelper } from '../../shared/api-helper';
import { SearchUsersParams } from '../../api/models/search-users-params';
import { UserCircuitsData } from 'app/api/models';

/**
 * Displays the latest users
 */
@Component({
  selector: 'latest-users',
  templateUrl: 'latest-users.component.html',
  // Edited to use its own stylesheet, was previously latest-ads
  styleUrls: ['latest-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestUsersComponent extends BaseDashboardComponent implements OnInit {

  @Input() groups: string[];
  @Input() max: number;

  users$ = new BehaviorSubject<UserResult[]>(null);
  param: string;
  loggedUserCircuits: UserCircuitsData;

  constructor(injector: Injector,
    private usersService: UsersService,
    private circuitsService: CircuitsService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.param = this.route.snapshot.params.user || ApiHelper.SELF;

    this.circuitsService.getMemberCircuits({ user: this.param })
        .subscribe(circuit => {
          this.loggedUserCircuits = circuit;
          this.usersService.searchUsers({ body: this.searchUsersParams } ).subscribe(ads => {
            this.users$.next(ads);
          })
        });
  }

  get searchUsersParams(): SearchUsersParams {
    // if the user has a circuit then filter for it too, otherwise not
    let params: SearchUsersParams;
    if (this.loggedUserCircuits.circuits[0]) {
      params = {
        // addressResult: UserAddressResultEnum.NONE,
        groups: this.groups,
        ignoreProfileFieldsInList: true,
        orderBy: UserOrderByEnum.CREATION_DATE,
        profileFields: ['image:true'],
        // fields: ['id', 'display', 'image'],
        skipTotalCount: true,
        pageSize: this.max,
        onlyCompanies: true,
        circuit: this.loggedUserCircuits.circuits[0].id
      };
    } else {
      params = {
        // addressResult: UserAddressResultEnum.NONE,
        groups: this.groups,
        ignoreProfileFieldsInList: true,
        orderBy: UserOrderByEnum.CREATION_DATE,
        profileFields: ['image:true'],
        // fields: ['id', 'display', 'image'],
        skipTotalCount: true,
        pageSize: this.max,
        onlyCompanies: true
      };
    }
    return params;
  }

  path(user: UserResult): string {
    return `/users/${user.id}/profile`;
  }

  navigate(user: UserResult, event: MouseEvent) {
    this.menu.navigate({
      url: this.path(user),
      menu: new ActiveMenu(Menu.SEARCH_USERS),
      clear: false,
      event: event
    });
  }
}
