/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Circuit } from '../models/circuit';
import { UserCircuitsData } from '../models/user-circuits-data';


/**
 * Provides access to circuits
 */
@Injectable({
  providedIn: 'root',
})
export class CircuitsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMemberCircuits
   */
  static readonly GetMemberCircuitsPath = '/{user}/circuits';

  /**
   * Returns data containing information regarding the user circuit(s)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMemberCircuits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMemberCircuits$Response(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

  }): Observable<StrictHttpResponse<UserCircuitsData>> {

    const rb = new RequestBuilder(this.rootUrl, CircuitsService.GetMemberCircuitsPath, 'get');
    if (params) {

      rb.path('user', params.user);
      rb.query('fields', params.fields);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserCircuitsData>;
      })
    );
  }

  /**
   * Returns data containing information regarding the user circuit(s)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMemberCircuits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMemberCircuits(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

  }): Observable<UserCircuitsData> {

    return this.getMemberCircuits$Response(params).pipe(
      map((r: StrictHttpResponse<UserCircuitsData>) => r.body as UserCircuitsData)
    );
  }

  /**
   * Path part for operation addCircuit
   */
  static readonly AddCircuitPath = '/{user}/circuits/{circuit}';

  /**
   * Adds a circuits relation between the given user and circuit.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCircuit()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCircuit$Response(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * The id or identifier of the circuit
     */
    circuit: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CircuitsService.AddCircuitPath, 'post');
    if (params) {

      rb.path('user', params.user);
      rb.path('circuit', params.circuit);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adds a circuits relation between the given user and circuit.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCircuit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCircuit(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * The id or identifier of the circuit
     */
    circuit: string;

  }): Observable<void> {

    return this.addCircuit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeCircuit
   */
  static readonly RemoveCircuitPath = '/{user}/circuits/{circuit}';

  /**
   * Removes a circuits relation between the given user and circuit.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCircuit()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCircuit$Response(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * The id or identifier of the circuit
     */
    circuit: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CircuitsService.RemoveCircuitPath, 'delete');
    if (params) {

      rb.path('user', params.user);
      rb.path('circuit', params.circuit);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes a circuits relation between the given user and circuit.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCircuit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCircuit(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * The id or identifier of the circuit
     */
    circuit: string;

  }): Observable<void> {

    return this.removeCircuit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCircuitDataForAdd
   */
  static readonly GetCircuitDataForAddPath = '/{user}/circuits/data-for-add';

  /**
   * Returns configuration data to add another circuit to a user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCircuitDataForAdd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircuitDataForAdd$Response(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

  }): Observable<StrictHttpResponse<UserCircuitsData>> {

    const rb = new RequestBuilder(this.rootUrl, CircuitsService.GetCircuitDataForAddPath, 'get');
    if (params) {

      rb.path('user', params.user);
      rb.query('fields', params.fields);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserCircuitsData>;
      })
    );
  }

  /**
   * Returns configuration data to add another circuit to a user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCircuitDataForAdd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircuitDataForAdd(params: {

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user: string;

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

  }): Observable<UserCircuitsData> {

    return this.getCircuitDataForAdd$Response(params).pipe(
      map((r: StrictHttpResponse<UserCircuitsData>) => r.body as UserCircuitsData)
    );
  }

  /**
   * Path part for operation getCircuits
   */
  static readonly GetCircuitsPath = '/circuits';

  /**
   * Returns circuits information.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCircuits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircuits$Response(params?: {

  }): Observable<StrictHttpResponse<Array<Circuit>>> {

    const rb = new RequestBuilder(this.rootUrl, CircuitsService.GetCircuitsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Circuit>>;
      })
    );
  }

  /**
   * Returns circuits information.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCircuits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircuits(params?: {

  }): Observable<Array<Circuit>> {

    return this.getCircuits$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Circuit>>) => r.body as Array<Circuit>)
    );
  }

}
