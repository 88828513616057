import { Component } from '@angular/core';
import { Input } from '@angular/core';

/**
 * @title Mat chips view
 */
@Component({
  selector: 'mat-chips-value',
  templateUrl: 'mat-chips-value.component.html',
  styleUrls: ['mat-chips-value.component.scss']
})
export class MatChipsValueComponent {
  @Input() value: string;

  separatedValues(): string[] {
    return this.value.split(' | ');
  }
}