/* tslint:disable */

/**
 * Type of phone
 * Possible values are:
 * - `landLine`: A landline phone
 * - `mobilePhone`: A mobile phone
 */
export enum PhoneKind {
  LAND_LINE = 'landLine',
  MOBILE_PHONE = 'mobilePhone'
}
