import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Address, AddressContactInfo, AddressView, Town} from 'app/api/models';
import {AddressHelperService} from 'app/core/address-helper.service';
import {CountriesResolve} from 'app/countries.resolve';
import {BaseComponent} from 'app/shared/base.component';
import {ProvincesResolve} from '../provinces.resolve';
import {SubcategoriesResolve} from '../subcategories.resolve';
import {AddressesService} from '../api/services/addresses.service';
import { TownsResolve } from '../towns.resolve';


/**
 * Component used to show the details of an address
 */
@Component({
  selector: 'address-details',
  templateUrl: 'address-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDetailsComponent extends BaseComponent implements OnInit {

  towns: Array<Town>;

  constructor(
    injector: Injector,
    public addressHelper: AddressHelperService,
    public countriesResolve: CountriesResolve,
    public provincesResolve: ProvincesResolve,
    public subcategoryResolve: SubcategoriesResolve,
    public townsResolve: TownsResolve,
    public addressesService: AddressesService
  ) {
    super(injector);
  }

  @Input() address: Address;
  @Input() elementClass: string;
  /** Whether the value type is boolean or string */
  @Input() type: 'boolean' | 'string' = 'boolean';
  @Output() click = new EventEmitter<Event>();

  contactInfo: AddressContactInfo;

  ngOnInit() {
    super.ngOnInit();
    const view = this.address as AddressView;
    if (view && view.contactInfo) {
      this.contactInfo = view.contactInfo;
    }
    if (this.address.region) {
      this.addSub(this.addressesService.listTownsByProvinceCode({id: this.address.region}).subscribe(data => {
        this.towns = data;
      }));
    }
  }

  get classNames() {
    return this.elementClass ? [this.elementClass] : 'mb-1';
  }

  get country(): string {
    if (this.address.country) {
      return this.countriesResolve.name(this.address.country);
    }
  }

  get region(): string {
    if (this.address.region) {
      return this.provincesResolve.name(this.address.region);
    }
  }

  get subcategory(): string {
    if (this.address.subcategory) {
      return this.subcategoryResolve.name(this.address.subcategory);
    }
  }

  get town(): string {
    if (this.address.region) {
      return this.townsResolve.name(this.address.town, this.towns);
    }
  }

}
