import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressesService } from 'app/api/services';
import { SingletonResolve } from 'app/singleton.resolve';
import {Subcategory} from './api/models/subcategory';

/**
 * Loads the possible groups for registration
 */
@Injectable({
  providedIn: 'root'
})
export class SubcategoriesResolve extends SingletonResolve<Subcategory[]> {
  subcategories: { [code: string]: string };

  constructor(
    private addressesService: AddressesService
  ) {
    super();
  }

  fetch(): Observable<Subcategory[]> {
    return this.addressesService.listSubcategories();
  }

  protected onFetched(data: Subcategory[]) {
    this.subcategories = {};
    data.forEach(c => this.subcategories[c.id] = c.name);
  }

  /**
   * Returns the subcategory name for the given code
   * @param id The Subcategory id
   */
  name(id: any): any {
    const name = this.subcategories[id];
    return name == null ? id : name;
  }
}
