import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mxc-dashboard',
  templateUrl: './mxc-dashboard.component.html',
  styleUrls: ['./mxc-dashboard.component.scss'],
  // ngx-bootstrap offers no control over the carousel css, so we have to inject it using ng-deep
  styles: [`
    ::ng-deep .carousel-control-prev, ::ng-deep .carousel-control-next {
      background-color: rgba(0, 0, 0, 0.3);
      height: 50px;
      top: 45%;
      border-radius: 50%;
      width: 50px;
      margin-left: 10px;
      margin-right: 10px;
    }
  `]
})
export class MxcDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
