import { Configuration } from 'app/configuration';

/**
 * Set all desired Cyclos configuration options
 */
export function setup() {
  Configuration.apiRoot = 'api';
  Configuration.appTitle = 'Mexnet';
  Configuration.appTitleSmall = 'Mexnet';
  Configuration.appTitleMenu = 'Menu';
  Configuration.shortcutIcons = [{ url: 'images/favicon.png' }];
  Configuration.operations = {
    thingNew: {
      icon: 'add'
    }
  };
}

