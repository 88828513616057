/* tslint:disable */

/**
 * Indicates the reason the transfer was created
 * Possible values are:
 * - `accountFee`: A transfer generated by an account fee charge
 * - `chargeback`: A transfer which is a chargeback of another transfer
 * - `import`: An imported transfer
 * - `initialCredit`: A transfer which is the initial credit for a newly created account
 * - `installment`: A transfer generated when processing a scheduled / recurring payment installment / occurrence
 * - `payment`: A transfer generated by a direct payment or accepting a webshop order
 * - `transferFee`: A transfer generated by a transfer fee charge
 */
export enum TransferKind {
  ACCOUNT_FEE = 'accountFee',
  CHARGEBACK = 'chargeback',
  IMPORT = 'import',
  INITIAL_CREDIT = 'initialCredit',
  INSTALLMENT = 'installment',
  PAYMENT = 'payment',
  TRANSFER_FEE = 'transferFee'
}
