import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.scss']
})
export class QrScanComponent implements OnInit {

  @Output() scan = new EventEmitter<string>();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log('hello world!');
  }

  onScanSuccess(event: any) {
    this.scan.emit(event);
    // console.log('Scan success: ', event);
    this.bsModalRef.hide();
  }

  onScanError(event: any) {
    console.log('Scan failed: ', event);
  }

}
