import {
  ChangeDetectionStrategy, Component, ElementRef, Host, Injector,
  Input, NgZone, OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PrincipalType, RoleEnum, User, UserQueryFilters} from 'app/api/models';
import {UsersService} from 'app/api/services';
import {Configuration} from 'app/configuration';
import {LoginService} from 'app/core/login.service';
import {NextRequestState} from 'app/core/next-request-state';
import {UserCacheService} from 'app/core/user-cache.service';
import {ApiHelper} from 'app/shared/api-helper';
import {BaseAutocompleteFieldComponent} from 'app/shared/base-autocomplete-field.component';
import {empty, focus} from 'app/shared/helper';
import {PickContactComponent} from 'app/shared/pick-contact.component';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, first} from 'rxjs/operators';
import {QrScanComponent} from './qr-scan.component';
import { SearchUsersParams } from '../api/models/search-users-params';
/**
 * Field used to select a user
 */
@Component({
  selector: 'user-field',
  templateUrl: 'user-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: UserFieldComponent, multi: true }
  ]
})
export class UserFieldComponent
  extends BaseAutocompleteFieldComponent<string, User>
  implements OnInit, OnDestroy {

  bsModalRef: BsModalRef;

  @Input() get user(): User {
    return this.selection;
  }
  set user(user: User) {
    this.selection = user;
  }

  @Input() allowPrincipal = false;
  @Input() principalTypes: PrincipalType[];
  @Input() allowSearch = true;
  @Input() allowContacts = false;
  @Input() fromProfile = false;
  @Input() fromContact = false;
  @Input() fromPayment = false;
  @Input() selectedAccountId: string;
  @Input() fromRequestPayment = false;
  @Input() filters: UserQueryFilters;

  @ViewChild('contactListButton', { static: false }) contactListButton: ElementRef;
  private fieldSub: Subscription;

  placeholder: string;

  constructor(
    injector: Injector,
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    private userCache: UserCacheService,
    private usersService: UsersService,
    private login: LoginService,
    private nextRequestState: NextRequestState,
    private modal: BsModalService,
    private ngZone: NgZone) {
    super(injector, controlContainer);
  }

   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  ngOnInit() {
    super.ngOnInit();
    this.fieldSub = this.inputFieldControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value != null && this.allowPrincipal) {
          value = ApiHelper.escapeNumeric(value);
          if (!/^\w+\:/.test(value)) {
            // When not already using a specific principal (such as id: when searching), use a wildcard
            value = '*:' + value;
          }
          this.value = value;
        }
      });
    this.placeholder = this.i18n.field.user.placeholderAllowSearch;
    this.delay(1000).then(() => {
      this.ngZone.run(() => {
        if (this.allowSearch) {
          this.placeholder = this.i18n.field.user.placeholderAllowSearch;
        } else if (this.allowPrincipal) {
          if (empty(this.principalTypes) || this.principalTypes.length > 3) {
            // Show a generic principal placeholder
            this.placeholder = this.i18n.field.user.placeholderPrincipal;
          } else {
            this.placeholder = this.principalTypes.map(t => t.name).join(' / ');
          }
        }

        const permissions = this.login.auth.permissions || {};

        // When the user has no permissions for search, disable the option
        const users = permissions.users || {};
        if (!users.search) {
          this.allowSearch = false;
        }

        // When the user has no permissions for contacts, disable the option
        const contacts = permissions.contacts || {};
        if (contacts.enable) {
          this.allowContacts = true;
        }

        if (!this.allowSearch) {
          this.allowOptions = false;
        }

      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.fieldSub.unsubscribe();
  }

  onDisabledChange(isDisabled: boolean): void {
    super.onDisabledChange(isDisabled);
    if (this.contactListButton && this.contactListButton.nativeElement) {
      this.contactListButton.nativeElement.disabled = isDisabled;
    }
  }

  protected fetch(value: string): Observable<User> {
    return this.userCache.get(value);
  }

  protected query(text: string): Observable<User[]> {
    text = (text || '').trim();
    if (text.startsWith('*:')) {
      text = text.substr(2);
    }
    if (!this.allowSearch || empty(text)) {
      return of([]);
    }

    const role = this.dataForUiHolder.role;
    const filters: UserQueryFilters = this.filters ? { ...this.filters } : {};
    filters.ignoreProfileFieldsInList = true;
    filters.pageSize = Configuration.quickSearchPageSize;
    filters.businessName = text;
    if ([RoleEnum.MEMBER, RoleEnum.BROKER].includes(role)) {
      filters.usersToExclude = [...(filters.usersToExclude || []), ApiHelper.SELF];
    }
    if (this.fromContact) {
      filters.section = 'CONTACT';
    }

    if (this.fromPayment) {
      filters.section = 'PAYMENT';
      filters.selectedAccountId = this.selectedAccountId;
    }

    if (this.fromRequestPayment) {
      filters.section = 'REQUEST_PAYMENT';
      filters.selectedAccountId = this.selectedAccountId;
    }

    if (this.fromProfile) {
      filters.section = 'PROFILE';
    }

    this.nextRequestState.leaveNotification = true;
    return this.usersService.searchUsers({ body: this.searchUsersParams(filters) });
  }

  searchUsersParams(filters: UserQueryFilters): SearchUsersParams {
    return {
      groups: filters.groups,
      roles: filters.roles,
      ignoreProfileFieldsInList: filters.ignoreProfileFieldsInList,
      pageSize: filters.pageSize,
      keywords: filters.keywords,
      businessName: filters.businessName,
      usersToExclude: filters.usersToExclude,
      section: filters.section,
      selectedAccountId: filters.selectedAccountId
    };
  }

  toDisplay(user: User): string {
    return user.display;
  }

  toValue(user: User): string {
    return `id:${user.id}`;
  }

  showContactList() {
    const ref = this.modal.show(PickContactComponent, {
      class: 'modal-form',
      initialState: {
        exclude: (this.filters || {}).usersToExclude || []
      }
    });
    const component = ref.content as PickContactComponent;
    component.select.pipe(first()).subscribe(u => this.select(u));
    this.modal.onHide.pipe(first()).subscribe(() => focus(this.inputField, true));
  }

  showQRScanner() {
    this.bsModalRef = this.modal.show(QrScanComponent);
    const scanner = this.bsModalRef.content as QrScanComponent;
    scanner.scan.subscribe(val => {
      console.log('Modal scanner', val); // {"systemName":"MEXNET","systemVersion":"1.0.0","id":"15","userId":"12"}
      const data = JSON.parse(val);
      // TODO: remove MEXNET value.
      if (data.systemName === 'MEXNET' && data.userId) {
        this.inputFieldControl.setValue('id:' + data.userId);
      }
    });
  }

  onInputFocus() {
    this.allowOptions = this.allowSearch;
  }

  onInputBlur() {
    this.allowOptions = false;
  }

  onEscapePressed() {
    // When a principal is allowed, pressing esc will just close the popup, and leave the value there
    if (this.allowPrincipal) {
      this.close();
    } else {
      this.select(null);
    }
  }

}
