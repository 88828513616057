/* tslint:disable */
import { Injectable } from '@angular/core';
import { Translations } from "./translations";
import { BehaviorSubject } from 'rxjs';

/**
 * Translation messages accessor
 */
@Injectable()
export class I18n {

  /**
   * Locales for which translations should be available
   */
  static locales(): string[] {
    return [
      'en',
      'es',
      'nl',
      'pt_BR',
      'fr',
      'it'
    ];
  }

  /**
   * Returns the file name which contains the translations for the given locale
   */
  static fileName(locale: string): string {
    switch (locale) {
      case 'es': return 'i18n.es.json';
      case 'nl': return 'i18n.nl.json';
      case 'pt_BR': return 'i18n.pt_BR.json';
      case 'fr': return 'i18n.fr.json';
      case 'it': return 'i18n.it.json';
      default: return 'i18n.json';
    }
  }

  /**
   * Returns a hash for the file contents on the moment it was compiled
   */
  static contentHash(locale: string): string {
    switch (locale) {
      case 'es': return 'd2986d46e6e254d6a5d60f1c62c90368b7281107';
      case 'nl': return 'b94c68e2be1a30ddd387e86eab4705b7a8164823';
      case 'pt_BR': return '7b0e674402dd50f0b98325b87926434d8f9ea59d';
      case 'fr': return '65c0d3dea3e3f70e2fabebb5946aad75eaa0641e';
      case 'it': return 'ce14cecf45fd2cdd85b40d0be56ae4bd8432cd67';
      default: return '5f2a070deb5507a624a9a4cfbe061f7c771e4bf1';
    }
  }

  /** Indicates whether the translation values have been initialized **/
  initialized$ = new BehaviorSubject(false);

  private _translations: Translations = new Translations();

  private _nested = {
    general: new I18n$General(),
    error: new I18n$Error(),
    menu: new I18n$Menu(),
    login: new I18n$Login(),
    password: new I18n$Password(),
    pendingAgreements: new I18n$PendingAgreements(),
    securityQuestion: new I18n$SecurityQuestion(),
    dashboard: new I18n$Dashboard(),
    account: new I18n$Account(),
    transaction: new I18n$Transaction(),
    voucher: new I18n$Voucher(),
    field: new I18n$Field(),
    user: new I18n$User(),
    operatorGroup: new I18n$OperatorGroup(),
    userStatus: new I18n$UserStatus(),
    groupMembership: new I18n$GroupMembership(),
    brokers: new I18n$Brokers(),
    circuits: new I18n$Circuits(),
    phone: new I18n$Phone(),
    address: new I18n$Address(),
    ad: new I18n$Ad(),
    contact: new I18n$Contact(),
    notification: new I18n$Notification(),
    notificationSettings: new I18n$NotificationSettings(),
    connectedUser: new I18n$ConnectedUser(),
    identityProvider: new I18n$IdentityProvider(),
    operation: new I18n$Operation(),
    setting: new I18n$Setting(),
    record: new I18n$Record(),
    userAlert: new I18n$UserAlert(),
    systemAlert: new I18n$SystemAlert(),
    reports: new I18n$Reports()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.general.defaultValues = defaultValues['general'];
    this._nested.error.defaultValues = defaultValues['error'];
    this._nested.menu.defaultValues = defaultValues['menu'];
    this._nested.login.defaultValues = defaultValues['login'];
    this._nested.password.defaultValues = defaultValues['password'];
    this._nested.pendingAgreements.defaultValues = defaultValues['pendingAgreements'];
    this._nested.securityQuestion.defaultValues = defaultValues['securityQuestion'];
    this._nested.dashboard.defaultValues = defaultValues['dashboard'];
    this._nested.account.defaultValues = defaultValues['account'];
    this._nested.transaction.defaultValues = defaultValues['transaction'];
    this._nested.voucher.defaultValues = defaultValues['voucher'];
    this._nested.field.defaultValues = defaultValues['field'];
    this._nested.user.defaultValues = defaultValues['user'];
    this._nested.operatorGroup.defaultValues = defaultValues['operatorGroup'];
    this._nested.userStatus.defaultValues = defaultValues['userStatus'];
    this._nested.groupMembership.defaultValues = defaultValues['groupMembership'];
    this._nested.brokers.defaultValues = defaultValues['brokers'];
    this._nested.circuits.defaultValues = defaultValues['circuits'];
    this._nested.phone.defaultValues = defaultValues['phone'];
    this._nested.address.defaultValues = defaultValues['address'];
    this._nested.ad.defaultValues = defaultValues['ad'];
    this._nested.contact.defaultValues = defaultValues['contact'];
    this._nested.notification.defaultValues = defaultValues['notification'];
    this._nested.notificationSettings.defaultValues = defaultValues['notificationSettings'];
    this._nested.connectedUser.defaultValues = defaultValues['connectedUser'];
    this._nested.identityProvider.defaultValues = defaultValues['identityProvider'];
    this._nested.operation.defaultValues = defaultValues['operation'];
    this._nested.setting.defaultValues = defaultValues['setting'];
    this._nested.record.defaultValues = defaultValues['record'];
    this._nested.userAlert.defaultValues = defaultValues['userAlert'];
    this._nested.systemAlert.defaultValues = defaultValues['systemAlert'];
    this._nested.reports.defaultValues = defaultValues['reports'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.general.initialize(values['general']);
    this._nested.error.initialize(values['error']);
    this._nested.menu.initialize(values['menu']);
    this._nested.login.initialize(values['login']);
    this._nested.password.initialize(values['password']);
    this._nested.pendingAgreements.initialize(values['pendingAgreements']);
    this._nested.securityQuestion.initialize(values['securityQuestion']);
    this._nested.dashboard.initialize(values['dashboard']);
    this._nested.account.initialize(values['account']);
    this._nested.transaction.initialize(values['transaction']);
    this._nested.voucher.initialize(values['voucher']);
    this._nested.field.initialize(values['field']);
    this._nested.user.initialize(values['user']);
    this._nested.operatorGroup.initialize(values['operatorGroup']);
    this._nested.userStatus.initialize(values['userStatus']);
    this._nested.groupMembership.initialize(values['groupMembership']);
    this._nested.brokers.initialize(values['brokers']);
    this._nested.circuits.initialize(values['circuits']);
    this._nested.phone.initialize(values['phone']);
    this._nested.address.initialize(values['address']);
    this._nested.ad.initialize(values['ad']);
    this._nested.contact.initialize(values['contact']);
    this._nested.notification.initialize(values['notification']);
    this._nested.notificationSettings.initialize(values['notificationSettings']);
    this._nested.connectedUser.initialize(values['connectedUser']);
    this._nested.identityProvider.initialize(values['identityProvider']);
    this._nested.operation.initialize(values['operation']);
    this._nested.setting.initialize(values['setting']);
    this._nested.record.initialize(values['record']);
    this._nested.userAlert.initialize(values['userAlert']);
    this._nested.systemAlert.initialize(values['systemAlert']);
    this._nested.reports.initialize(values['reports']);
    this.initialized$.next(true);
  }

  /**
   * Returns the value of translation message for key `registrationAgreement`.
   * Default value: `Termini e condizioni`
   */
  get registrationAgreement(): string {
    return this._translations.get('registrationAgreement');
  }

  /**
   * Returns the nested accessor for translation messages in `general`.
   */
  get general(): I18n$General {
    return this._nested.general;
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Error {
    return this._nested.error;
  }

  /**
   * Returns the nested accessor for translation messages in `menu`.
   */
  get menu(): I18n$Menu {
    return this._nested.menu;
  }

  /**
   * Returns the nested accessor for translation messages in `login`.
   */
  get login(): I18n$Login {
    return this._nested.login;
  }

  /**
   * Returns the nested accessor for translation messages in `password`.
   */
  get password(): I18n$Password {
    return this._nested.password;
  }

  /**
   * Returns the nested accessor for translation messages in `pendingAgreements`.
   */
  get pendingAgreements(): I18n$PendingAgreements {
    return this._nested.pendingAgreements;
  }

  /**
   * Returns the nested accessor for translation messages in `securityQuestion`.
   */
  get securityQuestion(): I18n$SecurityQuestion {
    return this._nested.securityQuestion;
  }

  /**
   * Returns the nested accessor for translation messages in `dashboard`.
   */
  get dashboard(): I18n$Dashboard {
    return this._nested.dashboard;
  }

  /**
   * Returns the nested accessor for translation messages in `account`.
   */
  get account(): I18n$Account {
    return this._nested.account;
  }

  /**
   * Returns the nested accessor for translation messages in `transaction`.
   */
  get transaction(): I18n$Transaction {
    return this._nested.transaction;
  }

  /**
   * Returns the nested accessor for translation messages in `voucher`.
   */
  get voucher(): I18n$Voucher {
    return this._nested.voucher;
  }

  /**
   * Returns the nested accessor for translation messages in `field`.
   */
  get field(): I18n$Field {
    return this._nested.field;
  }

  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  get user(): I18n$User {
    return this._nested.user;
  }

  /**
   * Returns the nested accessor for translation messages in `operatorGroup`.
   */
  get operatorGroup(): I18n$OperatorGroup {
    return this._nested.operatorGroup;
  }

  /**
   * Returns the nested accessor for translation messages in `userStatus`.
   */
  get userStatus(): I18n$UserStatus {
    return this._nested.userStatus;
  }

  /**
   * Returns the nested accessor for translation messages in `groupMembership`.
   */
  get groupMembership(): I18n$GroupMembership {
    return this._nested.groupMembership;
  }

  /**
   * Returns the nested accessor for translation messages in `brokers`.
   */
  get brokers(): I18n$Brokers {
    return this._nested.brokers;
  }

  /**
   * Returns the nested accessor for translation messages in `circuits`.
   */
  get circuits(): I18n$Circuits {
    return this._nested.circuits;
  }

  /**
   * Returns the nested accessor for translation messages in `phone`.
   */
  get phone(): I18n$Phone {
    return this._nested.phone;
  }

  /**
   * Returns the nested accessor for translation messages in `address`.
   */
  get address(): I18n$Address {
    return this._nested.address;
  }

  /**
   * Returns the nested accessor for translation messages in `ad`.
   */
  get ad(): I18n$Ad {
    return this._nested.ad;
  }

  /**
   * Returns the nested accessor for translation messages in `contact`.
   */
  get contact(): I18n$Contact {
    return this._nested.contact;
  }

  /**
   * Returns the nested accessor for translation messages in `notification`.
   */
  get notification(): I18n$Notification {
    return this._nested.notification;
  }

  /**
   * Returns the nested accessor for translation messages in `notificationSettings`.
   */
  get notificationSettings(): I18n$NotificationSettings {
    return this._nested.notificationSettings;
  }

  /**
   * Returns the nested accessor for translation messages in `connectedUser`.
   */
  get connectedUser(): I18n$ConnectedUser {
    return this._nested.connectedUser;
  }

  /**
   * Returns the nested accessor for translation messages in `identityProvider`.
   */
  get identityProvider(): I18n$IdentityProvider {
    return this._nested.identityProvider;
  }

  /**
   * Returns the nested accessor for translation messages in `operation`.
   */
  get operation(): I18n$Operation {
    return this._nested.operation;
  }

  /**
   * Returns the nested accessor for translation messages in `setting`.
   */
  get setting(): I18n$Setting {
    return this._nested.setting;
  }

  /**
   * Returns the nested accessor for translation messages in `record`.
   */
  get record(): I18n$Record {
    return this._nested.record;
  }

  /**
   * Returns the nested accessor for translation messages in `userAlert`.
   */
  get userAlert(): I18n$UserAlert {
    return this._nested.userAlert;
  }

  /**
   * Returns the nested accessor for translation messages in `systemAlert`.
   */
  get systemAlert(): I18n$SystemAlert {
    return this._nested.systemAlert;
  }

  /**
   * Returns the nested accessor for translation messages in `reports`.
   */
  get reports(): I18n$Reports {
    return this._nested.reports;
  }
}

export class I18n$General {

  private _translations: Translations = new Translations('general');

  private _nested = {
    sendMedium: new I18n$General$SendMedium(),
    sessionExpired: new I18n$General$SessionExpired(),
    datePart: new I18n$General$DatePart(),
    month: new I18n$General$Month(),
    timeField: new I18n$General$TimeField(),
    weekday: new I18n$General$Weekday(),
    range: new I18n$General$Range(),
    fileSize: new I18n$General$FileSize(),
    results: new I18n$General$Results(),
    geolocation: new I18n$General$Geolocation()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.sendMedium.defaultValues = defaultValues['sendMedium'];
    this._nested.sessionExpired.defaultValues = defaultValues['sessionExpired'];
    this._nested.datePart.defaultValues = defaultValues['datePart'];
    this._nested.month.defaultValues = defaultValues['month'];
    this._nested.timeField.defaultValues = defaultValues['timeField'];
    this._nested.weekday.defaultValues = defaultValues['weekday'];
    this._nested.range.defaultValues = defaultValues['range'];
    this._nested.fileSize.defaultValues = defaultValues['fileSize'];
    this._nested.results.defaultValues = defaultValues['results'];
    this._nested.geolocation.defaultValues = defaultValues['geolocation'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.sendMedium.initialize(values['sendMedium']);
    this._nested.sessionExpired.initialize(values['sessionExpired']);
    this._nested.datePart.initialize(values['datePart']);
    this._nested.month.initialize(values['month']);
    this._nested.timeField.initialize(values['timeField']);
    this._nested.weekday.initialize(values['weekday']);
    this._nested.range.initialize(values['range']);
    this._nested.fileSize.initialize(values['fileSize']);
    this._nested.results.initialize(values['results']);
    this._nested.geolocation.initialize(values['geolocation']);
  }

  /**
   * Returns the value of translation message for key `businessName`.
   * Default value: `Ragione sociale`
   */
  get businessName(): string {
    return this._translations.get('businessName');
  }

  /**
   * Returns the value of translation message for key `userTo`.
   * Default value: `Controparte`
   */
  get userTo(): string {
    return this._translations.get('userTo');
  }

  /**
   * Returns the value of translation message for key `by`.
   * Default value: `Da`
   */
  get by(): string {
    return this._translations.get('by');
  }

  /**
   * Returns the value of translation message for key `acceptedCurrency`.
   * Default value: `% min accettata`
   */
  get acceptedCurrency(): string {
    return this._translations.get('acceptedCurrency');
  }

  /**
   * Returns the value of translation message for key `minFranchise`.
   * Default value: `Franchigia minima`
   */
  get minFranchise(): string {
    return this._translations.get('minFranchise');
  }

  /**
   * Returns the value of translation message for key `onlyCurrency`.
   * Default value: `-> 100%`
   */
  get onlyCurrency(): string {
    return this._translations.get('onlyCurrency');
  }

  /**
   * Returns the value of translation message for key `driver`.
   * Default value: `Driver`
   */
  get driver(): string {
    return this._translations.get('driver');
  }

  /**
   * Returns the value of translation message for key `onlyDriver`.
   * Default value: `Solo Driver`
   */
  get onlyDriver(): string {
    return this._translations.get('onlyDriver');
  }

  /**
   * Returns the value of translation message for key `noDriver`.
   * Default value: `No Driver`
   */
  get noDriver(): string {
    return this._translations.get('noDriver');
  }

  /**
   * Returns the value of translation message for key `type`.
   * Default value: `Tipo`
   */
  get type(): string {
    return this._translations.get('type');
  }

  /**
   * Returns the value of translation message for key `code`.
   * Default value: `Codice`
   */
  get code(): string {
    return this._translations.get('code');
  }

  /**
   * Returns the value of translation message for key `period`.
   * Default value: `Periodo`
   */
  get period(): string {
    return this._translations.get('period');
  }

  /**
   * Returns the value of translation message for key `period.to`.
   * Default value: `a`
   */
  get periodTo(): string {
    return this._translations.get('period.to');
  }

  /**
   * Returns the value of translation message for key `now`.
   * Default value: `Ora`
   */
  get now(): string {
    return this._translations.get('now');
  }

  /**
   * Returns the value of translation message for key `action`.
   * Default value: `Azione`
   */
  get action(): string {
    return this._translations.get('action');
  }

  /**
   * Returns the value of translation message for key `actions`.
   * Default value: `Azioni`
   */
  get actions(): string {
    return this._translations.get('actions');
  }

  /**
   * Returns the value of translation message for key `add`.
   * Default value: `Aggiungi`
   */
  get add(): string {
    return this._translations.get('add');
  }

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Data`
   */
  get date(): string {
    return this._translations.get('date');
  }

  /**
   * Returns the value of translation message for key `performedBy`.
   * Default value: `Eseguito da`
   */
  get performedBy(): string {
    return this._translations.get('performedBy');
  }

  /**
   * Returns the value of translation message for key `status`.
   * Default value: `Stato`
   */
  get status(): string {
    return this._translations.get('status');
  }

  /**
   * Returns the value of translation message for key `comments`.
   * Default value: `Commenti`
   */
  get comments(): string {
    return this._translations.get('comments');
  }

  /**
   * Returns the value of translation message for key `futureDate`.
   * Default value: `Data futura`
   */
  get futureDate(): string {
    return this._translations.get('futureDate');
  }

  /**
   * Returns the value of translation message for key `beginDate`.
   * Default value: `Data inizio`
   */
  get beginDate(): string {
    return this._translations.get('beginDate');
  }

  /**
   * Returns the value of translation message for key `endDate`.
   * Default value: `Data fine`
   */
  get endDate(): string {
    return this._translations.get('endDate');
  }

  /**
   * Returns the value of translation message for key `creationDate`.
   * Default value: `Data di creazione`
   */
  get creationDate(): string {
    return this._translations.get('creationDate');
  }

  /**
   * Returns the value of translation message for key `expirationDate`.
   * Default value: `Data di scadenza`
   */
  get expirationDate(): string {
    return this._translations.get('expirationDate');
  }

  /**
   * Returns the value of translation message for key `newExpirationDate`.
   * Default value: `Nuova data di scadenza`
   */
  get newExpirationDate(): string {
    return this._translations.get('newExpirationDate');
  }

  /**
   * Returns the value of translation message for key `notApplied`.
   * Default value: `Non applicato`
   */
  get notApplied(): string {
    return this._translations.get('notApplied');
  }

  /**
   * Returns the value of translation message for key `notAppliedSelect`.
   * Default value: `Non applicato (selezionare per applicare)`
   */
  get notAppliedSelect(): string {
    return this._translations.get('notAppliedSelect');
  }

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `Utente`
   */
  get user(): string {
    return this._translations.get('user');
  }

  /**
   * Returns the value of translation message for key `operator`.
   * Default value: `Operatore`
   */
  get operator(): string {
    return this._translations.get('operator');
  }

  /**
   * Returns the value of translation message for key `brokers`.
   * Default value: `Brokers`
   */
  get brokers(): string {
    return this._translations.get('brokers');
  }

  /**
   * Returns the value of translation message for key `groups`.
   * Default value: `Gruppi`
   */
  get groups(): string {
    return this._translations.get('groups');
  }

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Nome`
   */
  get name(): string {
    return this._translations.get('name');
  }

  /**
   * Returns the value of translation message for key `to`.
   * Default value: `a`
   */
  get to(): string {
    return this._translations.get('to');
  }

  /**
   * Returns the value of translation message for key `description`.
   * Default value: `Descrizione`
   */
  get description(): string {
    return this._translations.get('description');
  }

  /**
   * Returns the value of translation message for key `reason`.
   * Default value: `Causale`
   */
  get reason(): string {
    return this._translations.get('reason');
  }

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `Details`
   */
  get details(): string {
    return this._translations.get('details');
  }

  /**
   * Returns the value of translation message for key `image`.
   * Default value: `Immagine`
   */
  get image(): string {
    return this._translations.get('image');
  }

  /**
   * Returns the value of translation message for key `keywords`.
   * Default value: `Parole Chiave`
   */
  get keywords(): string {
    return this._translations.get('keywords');
  }

  /**
   * Returns the value of translation message for key `distanceFilter`.
   * Default value: `Distanza`
   */
  get distanceFilter(): string {
    return this._translations.get('distanceFilter');
  }

  /**
   * Returns the value of translation message for key `orderBy`.
   * Default value: `Ordina per`
   */
  get orderBy(): string {
    return this._translations.get('orderBy');
  }

  /**
   * Returns the value of translation message for key `orderBy.relevance`.
   * Default value: `Rilevanza`
   */
  get orderByRelevance(): string {
    return this._translations.get('orderBy.relevance');
  }

  /**
   * Returns the value of translation message for key `print`.
   * Default value: `Stampa`
   */
  get print(): string {
    return this._translations.get('print');
  }

  /**
   * Returns the value of translation message for key `printed`.
   * Default value: `Stampato`
   */
  get printed(): string {
    return this._translations.get('printed');
  }

  /**
   * Returns the value of translation message for key `all`.
   * Default value: `Tutti`
   */
  get all(): string {
    return this._translations.get('all');
  }

  /**
   * Returns the value of translation message for key `showFilters`.
   * Default value: `Mostra filtri`
   */
  get showFilters(): string {
    return this._translations.get('showFilters');
  }

  /**
   * Returns the value of translation message for key `hideFilters`.
   * Default value: `Nascondi filtri`
   */
  get hideFilters(): string {
    return this._translations.get('hideFilters');
  }

  /**
   * Returns the value of translation message for key `moreFilters`.
   * Default value: `Più filtri`
   */
  get moreFilters(): string {
    return this._translations.get('moreFilters');
  }

  /**
   * Returns the value of translation message for key `showMoreFilters`.
   * Default value: `Mostra più filtri`
   */
  get showMoreFilters(): string {
    return this._translations.get('showMoreFilters');
  }

  /**
   * Returns the value of translation message for key `lessFilters`.
   * Default value: `Meno filtri`
   */
  get lessFilters(): string {
    return this._translations.get('lessFilters');
  }

  /**
   * Returns the value of translation message for key `showLessFilters`.
   * Default value: `Mostra meno filtri`
   */
  get showLessFilters(): string {
    return this._translations.get('showLessFilters');
  }

  /**
   * Returns the value of translation message for key `enabled`.
   * Default value: `Abilitato`
   */
  get enabled(): string {
    return this._translations.get('enabled');
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabilitato`
   */
  get disabled(): string {
    return this._translations.get('disabled');
  }

  /**
   * Returns the value of translation message for key `back`.
   * Default value: `Indietro`
   */
  get back(): string {
    return this._translations.get('back');
  }

  /**
   * Returns the value of translation message for key `previous`.
   * Default value: `Precedente`
   */
  get previous(): string {
    return this._translations.get('previous');
  }

  /**
   * Returns the value of translation message for key `next`.
   * Default value: `Prossimo`
   */
  get next(): string {
    return this._translations.get('next');
  }

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Mostra`
   */
  get view(): string {
    return this._translations.get('view');
  }

  /**
   * Returns the value of translation message for key `viewHistory`.
   * Default value: `Visualizza cronologia`
   */
  get viewHistory(): string {
    return this._translations.get('viewHistory');
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Modifica`
   */
  get edit(): string {
    return this._translations.get('edit');
  }

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `Conferma`
   */
  get confirm(): string {
    return this._translations.get('confirm');
  }

  /**
   * Returns the value of translation message for key `cancel`.
   * Default value: `Annulla`
   */
  get cancel(): string {
    return this._translations.get('cancel');
  }

  /**
   * Returns the value of translation message for key `close`.
   * Default value: `Chiudi`
   */
  get close(): string {
    return this._translations.get('close');
  }

  /**
   * Returns the value of translation message for key `submit`.
   * Default value: `Invia`
   */
  get submit(): string {
    return this._translations.get('submit');
  }

  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `Reset filtri`
   */
  get reset(): string {
    return this._translations.get('reset');
  }

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Rimuovi`
   */
  get remove(): string {
    return this._translations.get('remove');
  }

  /**
   * Returns the value of translation message for key `remove.confirm`.
   * Default value: `Sei sicuro di voler rimuovere l'elemento {item}?`
   */
  removeConfirm(item: string | number): string {
    return this._translations.get('remove.confirm', {
      item: item
    });
  }

  /**
   * Returns the value of translation message for key `remove.done`.
   * Default value: `L'elemento {item} è stato rimosso`
   */
  removeDone(item: string | number): string {
    return this._translations.get('remove.done', {
      item: item
    });
  }

  /**
   * Returns the value of translation message for key `remove.tooltip`.
   * Default value: `Rimuovi {item}`
   */
  removeTooltip(item: string | number): string {
    return this._translations.get('remove.tooltip', {
      item: item
    });
  }

  /**
   * Returns the value of translation message for key `save`.
   * Default value: `Salva`
   */
  get save(): string {
    return this._translations.get('save');
  }

  /**
   * Returns the value of translation message for key `addNew`.
   * Default value: `Aggiungi nuovo`
   */
  get addNew(): string {
    return this._translations.get('addNew');
  }

  /**
   * Returns the value of translation message for key `yes`.
   * Default value: `Sì`
   */
  get yes(): string {
    return this._translations.get('yes');
  }

  /**
   * Returns the value of translation message for key `no`.
   * Default value: `No`
   */
  get no(): string {
    return this._translations.get('no');
  }

  /**
   * Returns the value of translation message for key `notSet`.
   * Default value: `Non impostato`
   */
  get notSet(): string {
    return this._translations.get('notSet');
  }

  /**
   * Returns the value of translation message for key `map`.
   * Default value: `Mappa`
   */
  get map(): string {
    return this._translations.get('map');
  }

  /**
   * Returns the value of translation message for key `map.view`.
   * Default value: `Individua Posizione`
   */
  get mapView(): string {
    return this._translations.get('map.view');
  }

  /**
   * Returns the value of translation message for key `noOptionsSelected`.
   * Default value: `Nessuna opzione selezionata`
   */
  get noOptionsSelected(): string {
    return this._translations.get('noOptionsSelected');
  }

  /**
   * Returns the value of translation message for key `noOptionSelected`.
   * Default value: `Nessuna opzione selezionata`
   */
  get noOptionSelected(): string {
    return this._translations.get('noOptionSelected');
  }

  /**
   * Returns the value of translation message for key `removeItemConfirm`.
   * Default value: `Sei sicuro di voler rimuovere?`
   */
  get removeItemConfirm(): string {
    return this._translations.get('removeItemConfirm');
  }

  /**
   * Returns the value of translation message for key `removeItemDone`.
   * Default value: `Elemento rimosso`
   */
  get removeItemDone(): string {
    return this._translations.get('removeItemDone');
  }

  /**
   * Returns the value of translation message for key `currency`.
   * Default value: `Valuta`
   */
  get currency(): string {
    return this._translations.get('currency');
  }

  /**
   * Returns the value of translation message for key `images`.
   * Default value: `Immagini`
   */
  get images(): string {
    return this._translations.get('images');
  }

  /**
   * Returns the value of translation message for key `reloadPage`.
   * Default value: `Aggiorna`
   */
  get reloadPage(): string {
    return this._translations.get('reloadPage');
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Cerca...`
   */
  get search(): string {
    return this._translations.get('search');
  }

  /**
   * Returns the value of translation message for key `circuit`.
   * Default value: `Circuito`
   */
  get circuit(): string {
    return this._translations.get('circuit');
  }

  /**
   * Returns the value of translation message for key `vatFiscalCode`.
   * Default value: `Partita IVA / Codice Fiscale`
   */
  get vatFiscalCode(): string {
    return this._translations.get('vatFiscalCode');
  }

  /**
   * Returns the nested accessor for translation messages in `sendMedium`.
   */
  get sendMedium(): I18n$General$SendMedium {
    return this._nested.sendMedium;
  }

  /**
   * Returns the nested accessor for translation messages in `sessionExpired`.
   */
  get sessionExpired(): I18n$General$SessionExpired {
    return this._nested.sessionExpired;
  }

  /**
   * Returns the nested accessor for translation messages in `datePart`.
   */
  get datePart(): I18n$General$DatePart {
    return this._nested.datePart;
  }

  /**
   * Returns the nested accessor for translation messages in `month`.
   */
  get month(): I18n$General$Month {
    return this._nested.month;
  }

  /**
   * Returns the nested accessor for translation messages in `timeField`.
   */
  get timeField(): I18n$General$TimeField {
    return this._nested.timeField;
  }

  /**
   * Returns the nested accessor for translation messages in `weekday`.
   */
  get weekday(): I18n$General$Weekday {
    return this._nested.weekday;
  }

  /**
   * Returns the nested accessor for translation messages in `range`.
   */
  get range(): I18n$General$Range {
    return this._nested.range;
  }

  /**
   * Returns the nested accessor for translation messages in `fileSize`.
   */
  get fileSize(): I18n$General$FileSize {
    return this._nested.fileSize;
  }

  /**
   * Returns the nested accessor for translation messages in `results`.
   */
  get results(): I18n$General$Results {
    return this._nested.results;
  }

  /**
   * Returns the nested accessor for translation messages in `geolocation`.
   */
  get geolocation(): I18n$General$Geolocation {
    return this._nested.geolocation;
  }
}

export class I18n$General$SendMedium {

  private _translations: Translations = new Translations('general.sendMedium');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `email`.
   * Default value: `E-mail`
   */
  get email(): string {
    return this._translations.get('email');
  }

  /**
   * Returns the value of translation message for key `sms`.
   * Default value: `SMS`
   */
  get sms(): string {
    return this._translations.get('sms');
  }
}

export class I18n$General$SessionExpired {

  private _translations: Translations = new Translations('general.sessionExpired');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Sessione scaduta`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Sei stato disconnesso.<br>Puoi continuare a visualizzare la stessa pagina o accedere nuovamente.`
   */
  get message(): string {
    return this._translations.get('message');
  }

  /**
   * Returns the value of translation message for key `loginAgain`.
   * Default value: `Accedi di nuovo`
   */
  get loginAgain(): string {
    return this._translations.get('loginAgain');
  }
}

export class I18n$General$DatePart {

  private _translations: Translations = new Translations('general.datePart');

  private _nested = {
    long: new I18n$General$DatePart$Long()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.long.defaultValues = defaultValues['long'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.long.initialize(values['long']);
  }

  /**
   * Returns the nested accessor for translation messages in `long`.
   */
  get long(): I18n$General$DatePart$Long {
    return this._nested.long;
  }
}

export class I18n$General$DatePart$Long {

  private _translations: Translations = new Translations('general.datePart.long');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `year`.
   * Default value: `Anno`
   */
  get year(): string {
    return this._translations.get('year');
  }

  /**
   * Returns the value of translation message for key `month`.
   * Default value: `Mese`
   */
  get month(): string {
    return this._translations.get('month');
  }

  /**
   * Returns the value of translation message for key `day`.
   * Default value: `Giorno`
   */
  get day(): string {
    return this._translations.get('day');
  }
}

export class I18n$General$Month {

  private _translations: Translations = new Translations('general.month');

  private _nested = {
    long: new I18n$General$Month$Long(),
    short: new I18n$General$Month$Short()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.long.defaultValues = defaultValues['long'];
    this._nested.short.defaultValues = defaultValues['short'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.long.initialize(values['long']);
    this._nested.short.initialize(values['short']);
  }

  /**
   * Returns the nested accessor for translation messages in `long`.
   */
  get long(): I18n$General$Month$Long {
    return this._nested.long;
  }

  /**
   * Returns the nested accessor for translation messages in `short`.
   */
  get short(): I18n$General$Month$Short {
    return this._nested.short;
  }
}

export class I18n$General$Month$Long {

  private _translations: Translations = new Translations('general.month.long');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `jan`.
   * Default value: `Gennaio`
   */
  get jan(): string {
    return this._translations.get('jan');
  }

  /**
   * Returns the value of translation message for key `feb`.
   * Default value: `Febbraio`
   */
  get feb(): string {
    return this._translations.get('feb');
  }

  /**
   * Returns the value of translation message for key `mar`.
   * Default value: `Marzo`
   */
  get mar(): string {
    return this._translations.get('mar');
  }

  /**
   * Returns the value of translation message for key `apr`.
   * Default value: `Aprile`
   */
  get apr(): string {
    return this._translations.get('apr');
  }

  /**
   * Returns the value of translation message for key `may`.
   * Default value: `Maggio`
   */
  get may(): string {
    return this._translations.get('may');
  }

  /**
   * Returns the value of translation message for key `jun`.
   * Default value: `Giugno`
   */
  get jun(): string {
    return this._translations.get('jun');
  }

  /**
   * Returns the value of translation message for key `jul`.
   * Default value: `Luglio`
   */
  get jul(): string {
    return this._translations.get('jul');
  }

  /**
   * Returns the value of translation message for key `aug`.
   * Default value: `Agosto`
   */
  get aug(): string {
    return this._translations.get('aug');
  }

  /**
   * Returns the value of translation message for key `sep`.
   * Default value: `Settembre`
   */
  get sep(): string {
    return this._translations.get('sep');
  }

  /**
   * Returns the value of translation message for key `oct`.
   * Default value: `Ottobre`
   */
  get oct(): string {
    return this._translations.get('oct');
  }

  /**
   * Returns the value of translation message for key `nov`.
   * Default value: `Novembre`
   */
  get nov(): string {
    return this._translations.get('nov');
  }

  /**
   * Returns the value of translation message for key `dec`.
   * Default value: `Dicembre`
   */
  get dec(): string {
    return this._translations.get('dec');
  }
}

export class I18n$General$Month$Short {

  private _translations: Translations = new Translations('general.month.short');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `jan`.
   * Default value: `Gen`
   */
  get jan(): string {
    return this._translations.get('jan');
  }

  /**
   * Returns the value of translation message for key `feb`.
   * Default value: `Feb`
   */
  get feb(): string {
    return this._translations.get('feb');
  }

  /**
   * Returns the value of translation message for key `mar`.
   * Default value: `Mar`
   */
  get mar(): string {
    return this._translations.get('mar');
  }

  /**
   * Returns the value of translation message for key `apr`.
   * Default value: `Apr`
   */
  get apr(): string {
    return this._translations.get('apr');
  }

  /**
   * Returns the value of translation message for key `may`.
   * Default value: `Mag`
   */
  get may(): string {
    return this._translations.get('may');
  }

  /**
   * Returns the value of translation message for key `jun`.
   * Default value: `Giu`
   */
  get jun(): string {
    return this._translations.get('jun');
  }

  /**
   * Returns the value of translation message for key `jul`.
   * Default value: `Lug`
   */
  get jul(): string {
    return this._translations.get('jul');
  }

  /**
   * Returns the value of translation message for key `aug`.
   * Default value: `Ago`
   */
  get aug(): string {
    return this._translations.get('aug');
  }

  /**
   * Returns the value of translation message for key `sep`.
   * Default value: `Set`
   */
  get sep(): string {
    return this._translations.get('sep');
  }

  /**
   * Returns the value of translation message for key `oct`.
   * Default value: `Ott`
   */
  get oct(): string {
    return this._translations.get('oct');
  }

  /**
   * Returns the value of translation message for key `nov`.
   * Default value: `Nov`
   */
  get nov(): string {
    return this._translations.get('nov');
  }

  /**
   * Returns the value of translation message for key `dec`.
   * Default value: `Dic`
   */
  get dec(): string {
    return this._translations.get('dec');
  }
}

export class I18n$General$TimeField {

  private _translations: Translations = new Translations('general.timeField');

  private _nested = {
    plural: new I18n$General$TimeField$Plural(),
    singular: new I18n$General$TimeField$Singular()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.plural.defaultValues = defaultValues['plural'];
    this._nested.singular.defaultValues = defaultValues['singular'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.plural.initialize(values['plural']);
    this._nested.singular.initialize(values['singular']);
  }

  /**
   * Returns the value of translation message for key `pattern`.
   * Default value: `{amount} {field}`
   */
  pattern($: {amount: string | number, field: string | number}): string {
    return this._translations.get('pattern', {
      amount: $.amount,
      field: $.field
    });
  }

  /**
   * Returns the nested accessor for translation messages in `plural`.
   */
  get plural(): I18n$General$TimeField$Plural {
    return this._nested.plural;
  }

  /**
   * Returns the nested accessor for translation messages in `singular`.
   */
  get singular(): I18n$General$TimeField$Singular {
    return this._nested.singular;
  }
}

export class I18n$General$TimeField$Plural {

  private _translations: Translations = new Translations('general.timeField.plural');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `days`.
   * Default value: `Giorni`
   */
  get days(): string {
    return this._translations.get('days');
  }

  /**
   * Returns the value of translation message for key `hours`.
   * Default value: `Ore`
   */
  get hours(): string {
    return this._translations.get('hours');
  }

  /**
   * Returns the value of translation message for key `millis`.
   * Default value: `Millisecondi`
   */
  get millis(): string {
    return this._translations.get('millis');
  }

  /**
   * Returns the value of translation message for key `minutes`.
   * Default value: `Minuti`
   */
  get minutes(): string {
    return this._translations.get('minutes');
  }

  /**
   * Returns the value of translation message for key `months`.
   * Default value: `Mesi`
   */
  get months(): string {
    return this._translations.get('months');
  }

  /**
   * Returns the value of translation message for key `seconds`.
   * Default value: `Secondi`
   */
  get seconds(): string {
    return this._translations.get('seconds');
  }

  /**
   * Returns the value of translation message for key `weeks`.
   * Default value: `Settimane`
   */
  get weeks(): string {
    return this._translations.get('weeks');
  }

  /**
   * Returns the value of translation message for key `years`.
   * Default value: `Anni`
   */
  get years(): string {
    return this._translations.get('years');
  }
}

export class I18n$General$TimeField$Singular {

  private _translations: Translations = new Translations('general.timeField.singular');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `day`.
   * Default value: `Giorno`
   */
  get day(): string {
    return this._translations.get('day');
  }

  /**
   * Returns the value of translation message for key `hour`.
   * Default value: `Ora`
   */
  get hour(): string {
    return this._translations.get('hour');
  }

  /**
   * Returns the value of translation message for key `milli`.
   * Default value: `Millisecondo`
   */
  get milli(): string {
    return this._translations.get('milli');
  }

  /**
   * Returns the value of translation message for key `minute`.
   * Default value: `Minuto`
   */
  get minute(): string {
    return this._translations.get('minute');
  }

  /**
   * Returns the value of translation message for key `month`.
   * Default value: `Mese`
   */
  get month(): string {
    return this._translations.get('month');
  }

  /**
   * Returns the value of translation message for key `second`.
   * Default value: `Secondo`
   */
  get second(): string {
    return this._translations.get('second');
  }

  /**
   * Returns the value of translation message for key `week`.
   * Default value: `Settimana`
   */
  get week(): string {
    return this._translations.get('week');
  }

  /**
   * Returns the value of translation message for key `year`.
   * Default value: `Anno`
   */
  get year(): string {
    return this._translations.get('year');
  }
}

export class I18n$General$Weekday {

  private _translations: Translations = new Translations('general.weekday');

  private _nested = {
    min: new I18n$General$Weekday$Min(),
    long: new I18n$General$Weekday$Long()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.min.defaultValues = defaultValues['min'];
    this._nested.long.defaultValues = defaultValues['long'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.min.initialize(values['min']);
    this._nested.long.initialize(values['long']);
  }

  /**
   * Returns the nested accessor for translation messages in `min`.
   */
  get min(): I18n$General$Weekday$Min {
    return this._nested.min;
  }

  /**
   * Returns the nested accessor for translation messages in `long`.
   */
  get long(): I18n$General$Weekday$Long {
    return this._nested.long;
  }
}

export class I18n$General$Weekday$Min {

  private _translations: Translations = new Translations('general.weekday.min');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `sun`.
   * Default value: `D`
   */
  get sun(): string {
    return this._translations.get('sun');
  }

  /**
   * Returns the value of translation message for key `mon`.
   * Default value: `L`
   */
  get mon(): string {
    return this._translations.get('mon');
  }

  /**
   * Returns the value of translation message for key `tue`.
   * Default value: `M`
   */
  get tue(): string {
    return this._translations.get('tue');
  }

  /**
   * Returns the value of translation message for key `wed`.
   * Default value: `Me`
   */
  get wed(): string {
    return this._translations.get('wed');
  }

  /**
   * Returns the value of translation message for key `thu`.
   * Default value: `G`
   */
  get thu(): string {
    return this._translations.get('thu');
  }

  /**
   * Returns the value of translation message for key `fri`.
   * Default value: `V`
   */
  get fri(): string {
    return this._translations.get('fri');
  }

  /**
   * Returns the value of translation message for key `sat`.
   * Default value: `S`
   */
  get sat(): string {
    return this._translations.get('sat');
  }
}

export class I18n$General$Weekday$Long {

  private _translations: Translations = new Translations('general.weekday.long');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `sun`.
   * Default value: `Domenica`
   */
  get sun(): string {
    return this._translations.get('sun');
  }

  /**
   * Returns the value of translation message for key `mon`.
   * Default value: `Lunedi`
   */
  get mon(): string {
    return this._translations.get('mon');
  }

  /**
   * Returns the value of translation message for key `tue`.
   * Default value: `Martedì`
   */
  get tue(): string {
    return this._translations.get('tue');
  }

  /**
   * Returns the value of translation message for key `wed`.
   * Default value: `Mercoledì`
   */
  get wed(): string {
    return this._translations.get('wed');
  }

  /**
   * Returns the value of translation message for key `thu`.
   * Default value: `Giovedi`
   */
  get thu(): string {
    return this._translations.get('thu');
  }

  /**
   * Returns the value of translation message for key `fri`.
   * Default value: `Venerdì`
   */
  get fri(): string {
    return this._translations.get('fri');
  }

  /**
   * Returns the value of translation message for key `sat`.
   * Default value: `Sabato`
   */
  get sat(): string {
    return this._translations.get('sat');
  }
}

export class I18n$General$Range {

  private _translations: Translations = new Translations('general.range');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `fromTo`.
   * Default value: `Da {min} a {max}`
   */
  fromTo($: {min: string | number, max: string | number}): string {
    return this._translations.get('fromTo', {
      min: $.min,
      max: $.max
    });
  }

  /**
   * Returns the value of translation message for key `from`.
   * Default value: `Da {min}`
   */
  from(min: string | number): string {
    return this._translations.get('from', {
      min: min
    });
  }

  /**
   * Returns the value of translation message for key `to`.
   * Default value: `Fino a {max}`
   */
  to(max: string | number): string {
    return this._translations.get('to', {
      max: max
    });
  }
}

export class I18n$General$FileSize {

  private _translations: Translations = new Translations('general.fileSize');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `b`.
   * Default value: `{n} byte`
   */
  b(n: string | number): string {
    return this._translations.get('b', {
      n: n
    });
  }

  /**
   * Returns the value of translation message for key `k`.
   * Default value: `{n}KB`
   */
  k(n: string | number): string {
    return this._translations.get('k', {
      n: n
    });
  }

  /**
   * Returns the value of translation message for key `m`.
   * Default value: `{n}MB`
   */
  m(n: string | number): string {
    return this._translations.get('m', {
      n: n
    });
  }
}

export class I18n$General$Results {

  private _translations: Translations = new Translations('general.results');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `withTotal`.
   * Default value: `Mostra da {first} - a {last} di {total} risultati`
   */
  withTotal($: {first: string | number, last: string | number, total: string | number}): string {
    return this._translations.get('withTotal', {
      first: $.first,
      last: $.last,
      total: $.total
    });
  }

  /**
   * Returns the value of translation message for key `noTotal`.
   * Default value: `Mostra da {first} - a {last} risultato`
   */
  noTotal($: {first: string | number, last: string | number}): string {
    return this._translations.get('noTotal', {
      first: $.first,
      last: $.last
    });
  }

  /**
   * Returns the value of translation message for key `none`.
   * Default value: `Nessun risultato corrisponde ai criteri di ricerca`
   */
  get none(): string {
    return this._translations.get('none');
  }

  /**
   * Returns the value of translation message for key `nextXxs`.
   * Default value: `Avanti`
   */
  get nextXxs(): string {
    return this._translations.get('nextXxs');
  }

  /**
   * Returns the value of translation message for key `previousXxs`.
   * Default value: `Indietro`
   */
  get previousXxs(): string {
    return this._translations.get('previousXxs');
  }

  /**
   * Returns the value of translation message for key `noneDropdown`.
   * Default value: `Nessun risultato`
   */
  get noneDropdown(): string {
    return this._translations.get('noneDropdown');
  }
}

export class I18n$General$Geolocation {

  private _translations: Translations = new Translations('general.geolocation');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `kilometersFrom`.
   * Default value: `km da`
   */
  get kilometersFrom(): string {
    return this._translations.get('kilometersFrom');
  }

  /**
   * Returns the value of translation message for key `milesFrom`.
   * Default value: `miglia da`
   */
  get milesFrom(): string {
    return this._translations.get('milesFrom');
  }

  /**
   * Returns the value of translation message for key `current`.
   * Default value: `Posizione attuale`
   */
  get current(): string {
    return this._translations.get('current');
  }

  /**
   * Returns the value of translation message for key `myAddress`.
   * Default value: `Indirizzo`
   */
  get myAddress(): string {
    return this._translations.get('myAddress');
  }
}

export class I18n$Error {

  private _translations: Translations = new Translations('error');

  private _nested = {
    notFound: new I18n$Error$NotFound(),
    unauthorized: new I18n$Error$Unauthorized(),
    securityAnswer: new I18n$Error$SecurityAnswer(),
    field: new I18n$Error$Field(),
    geolocation: new I18n$Error$Geolocation(),
    badRequest: new I18n$Error$BadRequest()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.notFound.defaultValues = defaultValues['notFound'];
    this._nested.unauthorized.defaultValues = defaultValues['unauthorized'];
    this._nested.securityAnswer.defaultValues = defaultValues['securityAnswer'];
    this._nested.field.defaultValues = defaultValues['field'];
    this._nested.geolocation.defaultValues = defaultValues['geolocation'];
    this._nested.badRequest.defaultValues = defaultValues['badRequest'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.notFound.initialize(values['notFound']);
    this._nested.unauthorized.initialize(values['unauthorized']);
    this._nested.securityAnswer.initialize(values['securityAnswer']);
    this._nested.field.initialize(values['field']);
    this._nested.geolocation.initialize(values['geolocation']);
    this._nested.badRequest.initialize(values['badRequest']);
  }

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `Si è verificato un errore imprevisto durante l'elaborazione della richiesta`
   */
  get general(): string {
    return this._translations.get('general');
  }

  /**
   * Returns the value of translation message for key `serverOffline`.
   * Default value: `Il server non può essere contattato.<br>Per favore, riprova più tardi.`
   */
  get serverOffline(): string {
    return this._translations.get('serverOffline');
  }

  /**
   * Returns the value of translation message for key `invalidRequest`.
   * Default value: `Non è possibile connettersi al server.<br>Assicurati di essere connesso a Internet e riprova tra pochi secondi.`
   */
  get invalidRequest(): string {
    return this._translations.get('invalidRequest');
  }

  /**
   * Returns the value of translation message for key `queryParse`.
   * Default value: `Parole chiave non valide`
   */
  get queryParse(): string {
    return this._translations.get('queryParse');
  }

  /**
   * Returns the value of translation message for key `uploadSizeExceeded`.
   * Default value: `Il file caricato supera la dimensione massima consentita di {size}`
   */
  uploadSizeExceeded(size: string | number): string {
    return this._translations.get('uploadSizeExceeded', {
      size: size
    });
  }

  /**
   * Returns the value of translation message for key `maxItems`.
   * Default value: `Impossibile aggiungere più di {max} elementi`
   */
  maxItems(max: string | number): string {
    return this._translations.get('maxItems', {
      max: max
    });
  }

  /**
   * Returns the value of translation message for key `validation`.
   * Default value: `L'azione non può essere elaborata, poiché ci sono stati errori di convalida`
   */
  get validation(): string {
    return this._translations.get('validation');
  }

  /**
   * Returns the value of translation message for key `staleEntity`.
   * Default value: `Questo dato non può essere salvato perché è stato modificato da qualcun altro.<br>Si prega di caricare nuovamente la pagina e riavviare l'operazione.`
   */
  get staleEntity(): string {
    return this._translations.get('staleEntity');
  }

  /**
   * Returns the value of translation message for key `removeDataInUse`.
   * Default value: `Questo elemento non può essere rimosso perché è attualmente in uso.`
   */
  get removeDataInUse(): string {
    return this._translations.get('removeDataInUse');
  }

  /**
   * Returns the value of translation message for key `login`.
   * Default value: `Il nome / password specificato non sono corretti. Riprova.`
   */
  get login(): string {
    return this._translations.get('login');
  }

  /**
   * Returns the value of translation message for key `remoteAddressBlocked`.
   * Default value: `Il tuo indirizzo IP è bloccato per aver superato i tentativi di accesso`
   */
  get remoteAddressBlocked(): string {
    return this._translations.get('remoteAddressBlocked');
  }

  /**
   * Returns the value of translation message for key `permission`.
   * Default value: `Non hai i permessi sufficienti per eseguire l'azione richiesta`
   */
  get permission(): string {
    return this._translations.get('permission');
  }

  /**
   * Returns the value of translation message for key `loggedOut`.
   * Default value: `Sei stato disconnesso. Effettua nuovamente il login e ripeti l'operazione.`
   */
  get loggedOut(): string {
    return this._translations.get('loggedOut');
  }

  /**
   * Returns the value of translation message for key `otp`.
   * Default value: `Si è verificato un errore durante l'invio della password. Riprova più tardi.`
   */
  get otp(): string {
    return this._translations.get('otp');
  }

  /**
   * Returns the value of translation message for key `illegalAction`.
   * Default value: `L'azione che hai tentato di eseguire non è valida`
   */
  get illegalAction(): string {
    return this._translations.get('illegalAction');
  }

  /**
   * Returns the nested accessor for translation messages in `notFound`.
   */
  get notFound(): I18n$Error$NotFound {
    return this._nested.notFound;
  }

  /**
   * Returns the nested accessor for translation messages in `unauthorized`.
   */
  get unauthorized(): I18n$Error$Unauthorized {
    return this._nested.unauthorized;
  }

  /**
   * Returns the nested accessor for translation messages in `securityAnswer`.
   */
  get securityAnswer(): I18n$Error$SecurityAnswer {
    return this._nested.securityAnswer;
  }

  /**
   * Returns the nested accessor for translation messages in `field`.
   */
  get field(): I18n$Error$Field {
    return this._nested.field;
  }

  /**
   * Returns the nested accessor for translation messages in `geolocation`.
   */
  get geolocation(): I18n$Error$Geolocation {
    return this._nested.geolocation;
  }

  /**
   * Returns the nested accessor for translation messages in `badRequest`.
   */
  get badRequest(): I18n$Error$BadRequest {
    return this._nested.badRequest;
  }
}

export class I18n$Error$NotFound {

  private _translations: Translations = new Translations('error.notFound');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `location`.
   * Default value: `La posizione che hai digitato o provato ad accedere non è stata trovata`
   */
  get location(): string {
    return this._translations.get('location');
  }

  /**
   * Returns the value of translation message for key `type`.
   * Default value: `I dati richiesti non sono stati trovati: {type}`
   */
  type(type: string | number): string {
    return this._translations.get('type', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `typeKey`.
   * Default value: `I dati richiesti non sono stati trovati: {type} con chiave {key}`
   */
  typeKey($: {type: string | number, key: string | number}): string {
    return this._translations.get('typeKey', {
      type: $.type,
      key: $.key
    });
  }

  /**
   * Returns the value of translation message for key `userNotFound`.
   * Default value: `Questo utente non esiste`
   */
  get userNotFound(): string {
    return this._translations.get('userNotFound');
  }
}

export class I18n$Error$Unauthorized {

  private _translations: Translations = new Translations('error.unauthorized');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `address`.
   * Default value: `Il tuo indirizzo IP non è autorizzato a accedere`
   */
  get address(): string {
    return this._translations.get('address');
  }

  /**
   * Returns the value of translation message for key `url`.
   * Default value: `L'accesso non è consentito da questo URL`
   */
  get url(): string {
    return this._translations.get('url');
  }
}

export class I18n$Error$SecurityAnswer {

  private _translations: Translations = new Translations('error.securityAnswer');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `La risposta di sicurezza fornita non è valida`
   */
  get invalid(): string {
    return this._translations.get('invalid');
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `A causa del numero di tentativi sulla domande di sicurezza, questa richiesta è stata interrotta. Si prega di contattare l'amministrazione`
   */
  get disabled(): string {
    return this._translations.get('disabled');
  }
}

export class I18n$Error$Field {

  private _translations: Translations = new Translations('error.field');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `Questo campo non è valido`
   */
  get invalid(): string {
    return this._translations.get('invalid');
  }

  /**
   * Returns the value of translation message for key `required`.
   * Default value: `Questo campo è obbligatorio`
   */
  get required(): string {
    return this._translations.get('required');
  }

  /**
   * Returns the value of translation message for key `passwordsMatch`.
   * Default value: `Le password non coincidono`
   */
  get passwordsMatch(): string {
    return this._translations.get('passwordsMatch');
  }

  /**
   * Returns the value of translation message for key `minLength`.
   * Default value: `Dovrebbe avere almeno {min} caratteri`
   */
  minLength(min: string | number): string {
    return this._translations.get('minLength', {
      min: min
    });
  }

  /**
   * Returns the value of translation message for key `maxLength`.
   * Default value: `Dovrebbe avere al massimo {max} caratteri`
   */
  maxLength(max: string | number): string {
    return this._translations.get('maxLength', {
      max: max
    });
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Valore numerico non valido`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Data non valida`
   */
  get date(): string {
    return this._translations.get('date');
  }

  /**
   * Returns the value of translation message for key `minDate`.
   * Default value: `Dovrebbe essere {min} o dopo`
   */
  minDate(min: string | number): string {
    return this._translations.get('minDate', {
      min: min
    });
  }

  /**
   * Returns the value of translation message for key `maxDate`.
   * Default value: `Dovrebbe essere {max} o prima`
   */
  maxDate(max: string | number): string {
    return this._translations.get('maxDate', {
      max: max
    });
  }
}

export class I18n$Error$Geolocation {

  private _translations: Translations = new Translations('error.geolocation');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `denied`.
   * Default value: `Non hai i permessi per ottenere la posizione corrente. Si prega di autorizzare questo permesso nel browser.`
   */
  get denied(): string {
    return this._translations.get('denied');
  }

  /**
   * Returns the value of translation message for key `unavailable`.
   * Default value: `La posizione attuale non è disponibile`
   */
  get unavailable(): string {
    return this._translations.get('unavailable');
  }

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `Errore nell'ottenere la posizione corrente`
   */
  get general(): string {
    return this._translations.get('general');
  }
}

export class I18n$Error$BadRequest {

  private _translations: Translations = new Translations('error.badRequest');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `Errore generico`
   */
  get general(): string {
    return this._translations.get('general');
  }

  /**
   * Returns the value of translation message for key `invalidUsername`.
   * Default value: `Nome utente non valido`
   */
  get invalidUsername(): string {
    return this._translations.get('invalidUsername');
  }

  /**
   * Returns the value of translation message for key `invalidPassword`.
   * Default value: `Password non valida`
   */
  get invalidPassword(): string {
    return this._translations.get('invalidPassword');
  }

  /**
   * Returns the value of translation message for key `blockedCredentials`.
   * Default value: `Credenziali bloccate per i prossimi 15 minuti`
   */
  get blockedCredentials(): string {
    return this._translations.get('blockedCredentials');
  }

  /**
   * Returns the value of translation message for key `maxAmountPerDay`.
   * Default value: `Hai superato l'importo massimo giornaliero`
   */
  get maxAmountPerDay(): string {
    return this._translations.get('maxAmountPerDay');
  }

  /**
   * Returns the value of translation message for key `notEnoughCreditsPayment`.
   * Default value: `Non hai abbastanza fondi`
   */
  get notEnoughCreditsPayment(): string {
    return this._translations.get('notEnoughCreditsPayment');
  }

  /**
   * Returns the value of translation message for key `notEnoughCreditsRequest`.
   * Default value: `L'utente non ha abbastanza fondi`
   */
  get notEnoughCreditsRequest(): string {
    return this._translations.get('notEnoughCreditsRequest');
  }

  /**
   * Returns the value of translation message for key `transferMinimumPayment`.
   * Default value: `Il pagamento non può essere eseguito perchè stai trasferendo una cifra inferiore a quella minima`
   */
  get transferMinimumPayment(): string {
    return this._translations.get('transferMinimumPayment');
  }

  /**
   * Returns the value of translation message for key `upperCreditLimitReached`.
   * Default value: `Il pagamento non può essere eseguito perchè l'importo è superiore all'importo massimo`
   */
  get upperCreditLimitReached(): string {
    return this._translations.get('upperCreditLimitReached');
  }

  /**
   * Returns the value of translation message for key `invalidCreditLimit`.
   * Default value: `L'utente ha un saldo negativo ed il valore inserito è maggiore del suo saldo disponibile`
   */
  get invalidCreditLimit(): string {
    return this._translations.get('invalidCreditLimit');
  }

  /**
   * Returns the value of translation message for key `invalidUpperCreditLimit`.
   * Default value: `Il valore inserito è minore del saldo disponibile dell'utente.`
   */
  get invalidUpperCreditLimit(): string {
    return this._translations.get('invalidUpperCreditLimit');
  }

  /**
   * Returns the value of translation message for key `removedWithPositiveBalance`.
   * Default value: `L'utente non può essere rimosso poichè ha un saldo disponibile positivo.`
   */
  get removedWithPositiveBalance(): string {
    return this._translations.get('removedWithPositiveBalance');
  }

  /**
   * Returns the value of translation message for key `invalidCaptcha`.
   * Default value: `Il captcha scritto non è corretto`
   */
  get invalidCaptcha(): string {
    return this._translations.get('invalidCaptcha');
  }

  /**
   * Returns the value of translation message for key `channelDisabled`.
   * Default value: `Quest'utente non può accedere per mezzo di questo canale`
   */
  get channelDisabled(): string {
    return this._translations.get('channelDisabled');
  }

  /**
   * Returns the value of translation message for key `validationError`.
   * Default value: `Hai inserito un valore non valido {field}`
   */
  validationError(field: string | number): string {
    return this._translations.get('validationError', {
      field: field
    });
  }
}

export class I18n$Menu {

  private _translations: Translations = new Translations('menu');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `login`.
   * Default value: `Accedi`
   */
  get login(): string {
    return this._translations.get('login');
  }

  /**
   * Returns the value of translation message for key `register`.
   * Default value: `Iscriviti`
   */
  get register(): string {
    return this._translations.get('register');
  }

  /**
   * Returns the value of translation message for key `logout`.
   * Default value: `Esci`
   */
  get logout(): string {
    return this._translations.get('logout');
  }

  /**
   * Returns the value of translation message for key `shoppingCart`.
   * Default value: `Carrello`
   */
  get shoppingCart(): string {
    return this._translations.get('shoppingCart');
  }

  /**
   * Returns the value of translation message for key `home`.
   * Default value: `Home`
   */
  get home(): string {
    return this._translations.get('home');
  }

  /**
   * Returns the value of translation message for key `dashboard`.
   * Default value: `Dashboard`
   */
  get dashboard(): string {
    return this._translations.get('dashboard');
  }

  /**
   * Returns the value of translation message for key `banking`.
   * Default value: `Conti`
   */
  get banking(): string {
    return this._translations.get('banking');
  }

  /**
   * Returns the value of translation message for key `banking.account`.
   * Default value: `Conti`
   */
  get bankingAccount(): string {
    return this._translations.get('banking.account');
  }

  /**
   * Returns the value of translation message for key `banking.payUser`.
   * Default value: `Paga`
   */
  get bankingPayUser(): string {
    return this._translations.get('banking.payUser');
  }

  /**
   * Returns the value of translation message for key `banking.paySystem`.
   * Default value: `Pagamento sistema`
   */
  get bankingPaySystem(): string {
    return this._translations.get('banking.paySystem');
  }

  /**
   * Returns the value of translation message for key `banking.paySelf`.
   * Default value: `Auto-pagamento`
   */
  get bankingPaySelf(): string {
    return this._translations.get('banking.paySelf');
  }

  /**
   * Returns the value of translation message for key `banking.pos`.
   * Default value: `Incassa`
   */
  get bankingPos(): string {
    return this._translations.get('banking.pos');
  }

  /**
   * Returns the value of translation message for key `banking.scheduledPayments`.
   * Default value: `Pagamenti pianificati`
   */
  get bankingScheduledPayments(): string {
    return this._translations.get('banking.scheduledPayments');
  }

  /**
   * Returns the value of translation message for key `banking.authorizations`.
   * Default value: `Autorizzazioni di pagamento`
   */
  get bankingAuthorizations(): string {
    return this._translations.get('banking.authorizations');
  }

  /**
   * Returns the value of translation message for key `banking.searchVouchers`.
   * Default value: `Cerca voucher`
   */
  get bankingSearchVouchers(): string {
    return this._translations.get('banking.searchVouchers');
  }

  /**
   * Returns the value of translation message for key `banking.buyVouchers`.
   * Default value: `Acquista voucher`
   */
  get bankingBuyVouchers(): string {
    return this._translations.get('banking.buyVouchers');
  }

  /**
   * Returns the value of translation message for key `banking.boughtVouchers`.
   * Default value: `Visualizza acquisti`
   */
  get bankingBoughtVouchers(): string {
    return this._translations.get('banking.boughtVouchers');
  }

  /**
   * Returns the value of translation message for key `banking.redeemVoucher`.
   * Default value: `Riscatta voucher`
   */
  get bankingRedeemVoucher(): string {
    return this._translations.get('banking.redeemVoucher');
  }

  /**
   * Returns the value of translation message for key `banking.redeemedVouchers`.
   * Default value: `Mostra voucher riscattati`
   */
  get bankingRedeemedVouchers(): string {
    return this._translations.get('banking.redeemedVouchers');
  }

  /**
   * Returns the value of translation message for key `banking.transfersOverview`.
   * Default value: `Panoramica operazioni`
   */
  get bankingTransfersOverview(): string {
    return this._translations.get('banking.transfersOverview');
  }

  /**
   * Returns the value of translation message for key `content.contacts`.
   * Default value: `Contatti`
   */
  get contentContacts(): string {
    return this._translations.get('content.contacts');
  }

  /**
   * Returns the value of translation message for key `operators`.
   * Default value: `Operatori`
   */
  get operators(): string {
    return this._translations.get('operators');
  }

  /**
   * Returns the value of translation message for key `operators.operators`.
   * Default value: `Operatori`
   */
  get operatorsOperators(): string {
    return this._translations.get('operators.operators');
  }

  /**
   * Returns the value of translation message for key `operators.register`.
   * Default value: `Iscrivi operatore`
   */
  get operatorsRegister(): string {
    return this._translations.get('operators.register');
  }

  /**
   * Returns the value of translation message for key `operators.groups`.
   * Default value: `Gruppi operatore`
   */
  get operatorsGroups(): string {
    return this._translations.get('operators.groups');
  }

  /**
   * Returns the value of translation message for key `brokering`.
   * Default value: `Mie aziende`
   */
  get brokering(): string {
    return this._translations.get('brokering');
  }

  /**
   * Returns the value of translation message for key `brokering.users`.
   * Default value: `Utenti`
   */
  get brokeringUsers(): string {
    return this._translations.get('brokering.users');
  }

  /**
   * Returns the value of translation message for key `brokering.register`.
   * Default value: `Iscrivi utente`
   */
  get brokeringRegister(): string {
    return this._translations.get('brokering.register');
  }

  /**
   * Returns the value of translation message for key `marketplace`.
   * Default value: `Marketplace`
   */
  get marketplace(): string {
    return this._translations.get('marketplace');
  }

  /**
   * Returns the value of translation message for key `marketplace.users`.
   * Default value: `Utenti`
   */
  get marketplaceUsers(): string {
    return this._translations.get('marketplace.users');
  }

  /**
   * Returns the value of translation message for key `marketplace.register`.
   * Default value: `Iscrivi utente`
   */
  get marketplaceRegister(): string {
    return this._translations.get('marketplace.register');
  }

  /**
   * Returns the value of translation message for key `marketplace.directory`.
   * Default value: `Aziende`
   */
  get marketplaceDirectory(): string {
    return this._translations.get('marketplace.directory');
  }

  /**
   * Returns the value of translation message for key `marketplace.userSearch`.
   * Default value: `Utenti`
   */
  get marketplaceUserSearch(): string {
    return this._translations.get('marketplace.userSearch');
  }

  /**
   * Returns the value of translation message for key `marketplace.businessDirectory`.
   * Default value: `Aziende`
   */
  get marketplaceBusinessDirectory(): string {
    return this._translations.get('marketplace.businessDirectory');
  }

  /**
   * Returns the value of translation message for key `marketplace.advertisements`.
   * Default value: `Annunci`
   */
  get marketplaceAdvertisements(): string {
    return this._translations.get('marketplace.advertisements');
  }

  /**
   * Returns the value of translation message for key `marketplace.adInterests`.
   * Default value: `Advertisement interests`
   */
  get marketplaceAdInterests(): string {
    return this._translations.get('marketplace.adInterests');
  }

  /**
   * Returns the value of translation message for key `marketplace.deliveryMethods`.
   * Default value: `Delivery methods`
   */
  get marketplaceDeliveryMethods(): string {
    return this._translations.get('marketplace.deliveryMethods');
  }

  /**
   * Returns the value of translation message for key `marketplace.webshopSettings`.
   * Default value: `Webshop settings`
   */
  get marketplaceWebshopSettings(): string {
    return this._translations.get('marketplace.webshopSettings');
  }

  /**
   * Returns the value of translation message for key `marketplace.myAdvertisements`.
   * Default value: `I miei annunci`
   */
  get marketplaceMyAdvertisements(): string {
    return this._translations.get('marketplace.myAdvertisements');
  }

  /**
   * Returns the value of translation message for key `marketplace.myPurchases`.
   * Default value: `My purchases`
   */
  get marketplaceMyPurchases(): string {
    return this._translations.get('marketplace.myPurchases');
  }

  /**
   * Returns the value of translation message for key `marketplace.mySales`.
   * Default value: `My sales`
   */
  get marketplaceMySales(): string {
    return this._translations.get('marketplace.mySales');
  }

  /**
   * Returns the value of translation message for key `marketplace.myWebshop`.
   * Default value: `My webshop`
   */
  get marketplaceMyWebshop(): string {
    return this._translations.get('marketplace.myWebshop');
  }

  /**
   * Returns the value of translation message for key `marketplace.unansweredQuestions`.
   * Default value: `Unanswered questions`
   */
  get marketplaceUnansweredQuestions(): string {
    return this._translations.get('marketplace.unansweredQuestions');
  }

  /**
   * Returns the value of translation message for key `marketplace.connectedUsers`.
   * Default value: `Utenti connessi`
   */
  get marketplaceConnectedUsers(): string {
    return this._translations.get('marketplace.connectedUsers');
  }

  /**
   * Returns the value of translation message for key `marketplace.userAlerts`.
   * Default value: `Avvisi utente`
   */
  get marketplaceUserAlerts(): string {
    return this._translations.get('marketplace.userAlerts');
  }

  /**
   * Returns the value of translation message for key `personal`.
   * Default value: `Personale`
   */
  get personal(): string {
    return this._translations.get('personal');
  }

  /**
   * Returns the value of translation message for key `personal.viewProfile`.
   * Default value: `Profilo`
   */
  get personalViewProfile(): string {
    return this._translations.get('personal.viewProfile');
  }

  /**
   * Returns the value of translation message for key `personal.editProfile`.
   * Default value: `Modifica profilo`
   */
  get personalEditProfile(): string {
    return this._translations.get('personal.editProfile');
  }

  /**
   * Returns the value of translation message for key `personal.addressBook`.
   * Default value: `Rubrica`
   */
  get personalAddressBook(): string {
    return this._translations.get('personal.addressBook');
  }

  /**
   * Returns the value of translation message for key `personal.password`.
   * Default value: `Password`
   */
  get personalPassword(): string {
    return this._translations.get('personal.password');
  }

  /**
   * Returns the value of translation message for key `personal.passwords`.
   * Default value: `Password`
   */
  get personalPasswords(): string {
    return this._translations.get('personal.passwords');
  }

  /**
   * Returns the value of translation message for key `personal.identityProviders`.
   * Default value: `Identity providers`
   */
  get personalIdentityProviders(): string {
    return this._translations.get('personal.identityProviders');
  }

  /**
   * Returns the value of translation message for key `personal.notifications`.
   * Default value: `Notifiche`
   */
  get personalNotifications(): string {
    return this._translations.get('personal.notifications');
  }

  /**
   * Returns the value of translation message for key `personal.notificationSettings`.
   * Default value: `Impostazioni di notifica`
   */
  get personalNotificationSettings(): string {
    return this._translations.get('personal.notificationSettings');
  }

  /**
   * Returns the value of translation message for key `personal.settings`.
   * Default value: `Impostazioni`
   */
  get personalSettings(): string {
    return this._translations.get('personal.settings');
  }

  /**
   * Returns the value of translation message for key `content`.
   * Default value: `Informazioni`
   */
  get content(): string {
    return this._translations.get('content');
  }

  /**
   * Returns the value of translation message for key `report`.
   * Default value: `Report`
   */
  get report(): string {
    return this._translations.get('report');
  }
}

export class I18n$Login {

  private _translations: Translations = new Translations('login');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Login`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Login`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Puoi accedere con il tuo nome utente e password`
   */
  get message(): string {
    return this._translations.get('message');
  }

  /**
   * Returns the value of translation message for key `disconnected`.
   * Default value: `Sei stato disconnesso.<br>Per favore, accedi di nuovo per visualizzare la pagina richiesta.`
   */
  get disconnected(): string {
    return this._translations.get('disconnected');
  }

  /**
   * Returns the value of translation message for key `principal`.
   * Default value: `Utente`
   */
  get principal(): string {
    return this._translations.get('principal');
  }

  /**
   * Returns the value of translation message for key `forgotPassword`.
   * Default value: `Password dimenticata?`
   */
  get forgotPassword(): string {
    return this._translations.get('forgotPassword');
  }

  /**
   * Returns the value of translation message for key `register`.
   * Default value: `Non sei ancora un utente? Registrati qui.`
   */
  get register(): string {
    return this._translations.get('register');
  }
}

export class I18n$Password {

  private _translations: Translations = new Translations('password');

  private _nested = {
    error: new I18n$Password$Error(),
    title: new I18n$Password$Title(),
    mobileTitle: new I18n$Password$MobileTitle(),
    forgotten: new I18n$Password$Forgotten(),
    expired: new I18n$Password$Expired(),
    status: new I18n$Password$Status(),
    action: new I18n$Password$Action()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.error.defaultValues = defaultValues['error'];
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.forgotten.defaultValues = defaultValues['forgotten'];
    this._nested.expired.defaultValues = defaultValues['expired'];
    this._nested.status.defaultValues = defaultValues['status'];
    this._nested.action.defaultValues = defaultValues['action'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.error.initialize(values['error']);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.forgotten.initialize(values['forgotten']);
    this._nested.expired.initialize(values['expired']);
    this._nested.status.initialize(values['status']);
    this._nested.action.initialize(values['action']);
  }

  /**
   * Returns the value of translation message for key `confirmationMessage`.
   * Default value: `Inserisci la tua {passwordType} per confermare questa operazione`
   */
  confirmationMessage(passwordType: string | number): string {
    return this._translations.get('confirmationMessage', {
      passwordType: passwordType
    });
  }

  /**
   * Returns the value of translation message for key `confirm.noPassword`.
   * Default value: `Per confermare hai bisogno di un {passwordType}, ma non ne hai`
   */
  confirmNoPassword(passwordType: string | number): string {
    return this._translations.get('confirm.noPassword', {
      passwordType: passwordType
    });
  }

  /**
   * Returns the value of translation message for key `confirm.mode`.
   * Default value: `Conferma con`
   */
  get confirmMode(): string {
    return this._translations.get('confirm.mode');
  }

  /**
   * Returns the value of translation message for key `confirm.mode.device`.
   * Default value: `Cellulare`
   */
  get confirmModeDevice(): string {
    return this._translations.get('confirm.mode.device');
  }

  /**
   * Returns the value of translation message for key `confirm.mode.password`.
   * Default value: `Password`
   */
  get confirmModePassword(): string {
    return this._translations.get('confirm.mode.password');
  }

  /**
   * Returns the value of translation message for key `confirm.device.active`.
   * Default value: `Per confermare, scansiona questo codice QR con il tuo telefono cellulare`
   */
  get confirmDeviceActive(): string {
    return this._translations.get('confirm.device.active');
  }

  /**
   * Returns the value of translation message for key `confirm.device.none`.
   * Default value: `Per confermare che devi avere un cellulare verificato. Il tuo non è ancora verificato`
   */
  get confirmDeviceNone(): string {
    return this._translations.get('confirm.device.none');
  }

  /**
   * Returns the value of translation message for key `confirm.deviceOrPassword.active`.
   * Default value: `Per confermare, il cliente dovrà scansionare questo codice QR o inserire la sua {password} d'accesso.`
   */
  confirmDeviceOrPasswordActive(password: string | number): string {
    return this._translations.get('confirm.deviceOrPassword.active', {
      password: password
    });
  }

  /**
   * Returns the value of translation message for key `confirm.deviceOrPassword.none`.
   * Default value: `Per confermare è necessario disporre di una cellulare verificato o inserire la {password}`
   */
  confirmDeviceOrPasswordNone(password: string | number): string {
    return this._translations.get('confirm.deviceOrPassword.none', {
      password: password
    });
  }

  /**
   * Returns the value of translation message for key `confirm.deviceOrOtp.noMediums`.
   * Default value: `Per confermare che è necessario un cellulare verificato (e non ne hai) o un Otp ma non ci sono possibili mezzi per riceverlo.<br>Contatta l'amministrazione.`
   */
  get confirmDeviceOrOtpNoMediums(): string {
    return this._translations.get('confirm.deviceOrOtp.noMediums');
  }

  /**
   * Returns the value of translation message for key `confirm.deviceOrOtp.active`.
   * Default value: `Per confermare, scansionare questo codice QR con il tuo cellulare, utilizzare la password di conferma precedentemente inviata o richiedine una nuova`
   */
  get confirmDeviceOrOtpActive(): string {
    return this._translations.get('confirm.deviceOrOtp.active');
  }

  /**
   * Returns the value of translation message for key `confirm.deviceOrOtp.request`.
   * Default value: `Per confermare, scansionare questo codice QR con il tuo cellulare o richiedere una password di conferma qui sotto`
   */
  get confirmDeviceOrOtpRequest(): string {
    return this._translations.get('confirm.deviceOrOtp.request');
  }

  /**
   * Returns the value of translation message for key `confirm.otp.noMediums`.
   * Default value: `Per confermare hai bisogno di una password di conferma, ma non ci sono i mezzi per riceverla.<br>Per favore, contatta l'amministrazione.`
   */
  get confirmOtpNoMediums(): string {
    return this._translations.get('confirm.otp.noMediums');
  }

  /**
   * Returns the value of translation message for key `confirm.otp.active`.
   * Default value: `Puoi utilizzare la password di conferma inviata in precedenza o richiederne una nuova`
   */
  get confirmOtpActive(): string {
    return this._translations.get('confirm.otp.active');
  }

  /**
   * Returns the value of translation message for key `confirm.otp.request`.
   * Default value: `Per favore, richiedi una password di conferma qui sotto per confermare`
   */
  get confirmOtpRequest(): string {
    return this._translations.get('confirm.otp.request');
  }

  /**
   * Returns the value of translation message for key `otp.sent`.
   * Default value: `La password è stata inviata a {dest}`
   */
  otpSent(dest: string | number): string {
    return this._translations.get('otp.sent', {
      dest: dest
    });
  }

  /**
   * Returns the value of translation message for key `otp.receiveBy`.
   * Default value: `Ricevi via {medium}`
   */
  otpReceiveBy(medium: string | number): string {
    return this._translations.get('otp.receiveBy', {
      medium: medium
    });
  }

  /**
   * Returns the value of translation message for key `oldPassword`.
   * Default value: `Vecchia password`
   */
  get oldPassword(): string {
    return this._translations.get('oldPassword');
  }

  /**
   * Returns the value of translation message for key `newPassword`.
   * Default value: `Nuova password`
   */
  get newPassword(): string {
    return this._translations.get('newPassword');
  }

  /**
   * Returns the value of translation message for key `passwordConfirmation`.
   * Default value: `Conferma password`
   */
  get passwordConfirmation(): string {
    return this._translations.get('passwordConfirmation');
  }

  /**
   * Returns the value of translation message for key `statusSince`.
   * Default value: `Dal {date}`
   */
  statusSince(date: string | number): string {
    return this._translations.get('statusSince', {
      date: date
    });
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Password$Error {
    return this._nested.error;
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Password$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Password$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `forgotten`.
   */
  get forgotten(): I18n$Password$Forgotten {
    return this._nested.forgotten;
  }

  /**
   * Returns the nested accessor for translation messages in `expired`.
   */
  get expired(): I18n$Password$Expired {
    return this._nested.expired;
  }

  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  get status(): I18n$Password$Status {
    return this._nested.status;
  }

  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  get action(): I18n$Password$Action {
    return this._nested.action;
  }
}

export class I18n$Password$Error {

  private _translations: Translations = new Translations('password.error');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `La password è stata disattivata. Contatta l'amministrazione.`
   */
  get disabled(): string {
    return this._translations.get('disabled');
  }

  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `La password è stata reimpostata.`
   */
  get reset(): string {
    return this._translations.get('reset');
  }

  /**
   * Returns the value of translation message for key `indefinitelyBlocked`.
   * Default value: `La password di accesso è stato disabilitato dal superamento dei tentativi massimi. Si prega di contattare l'amministrazione.`
   */
  get indefinitelyBlocked(): string {
    return this._translations.get('indefinitelyBlocked');
  }

  /**
   * Returns the value of translation message for key `temporarilyBlocked`.
   * Default value: `La password è temporaneamente bloccata.`
   */
  get temporarilyBlocked(): string {
    return this._translations.get('temporarilyBlocked');
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `La password è scaduta. Contatta l'amministrazione.`
   */
  get expired(): string {
    return this._translations.get('expired');
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `La password è in attesa di attivazione. Contatta l'amministrazione.`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `Il dato {type} non è valido`
   */
  invalid(type: string | number): string {
    return this._translations.get('invalid', {
      type: type
    });
  }
}

export class I18n$Password$Title {

  private _translations: Translations = new Translations('password.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `change.self`.
   * Default value: `Cambia la tua password`
   */
  get changeSelf(): string {
    return this._translations.get('change.self');
  }

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Modifica la password`
   */
  get changeUser(): string {
    return this._translations.get('change.user');
  }

  /**
   * Returns the value of translation message for key `forgotten`.
   * Default value: `User identification / password recovery`
   */
  get forgotten(): string {
    return this._translations.get('forgotten');
  }

  /**
   * Returns the value of translation message for key `manage.single.self`.
   * Default value: `Gestisci la tua password`
   */
  get manageSingleSelf(): string {
    return this._translations.get('manage.single.self');
  }

  /**
   * Returns the value of translation message for key `manage.multiple.self`.
   * Default value: `Gestisci le password`
   */
  get manageMultipleSelf(): string {
    return this._translations.get('manage.multiple.self');
  }

  /**
   * Returns the value of translation message for key `manage.single.user`.
   * Default value: `Gestisci password utente`
   */
  get manageSingleUser(): string {
    return this._translations.get('manage.single.user');
  }

  /**
   * Returns the value of translation message for key `manage.multiple.user`.
   * Default value: `Gestire le password utente`
   */
  get manageMultipleUser(): string {
    return this._translations.get('manage.multiple.user');
  }

  /**
   * Returns the value of translation message for key `securityAnswer`.
   * Default value: `Imposta la tua risposta di sicurezza`
   */
  get securityAnswer(): string {
    return this._translations.get('securityAnswer');
  }
}

export class I18n$Password$MobileTitle {

  private _translations: Translations = new Translations('password.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `change.self`.
   * Default value: `Modifica password`
   */
  get changeSelf(): string {
    return this._translations.get('change.self');
  }

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Modifica password`
   */
  get changeUser(): string {
    return this._translations.get('change.user');
  }

  /**
   * Returns the value of translation message for key `forgotten`.
   * Default value: `User / password recovery`
   */
  get forgotten(): string {
    return this._translations.get('forgotten');
  }

  /**
   * Returns the value of translation message for key `manage.single.self`.
   * Default value: `Password`
   */
  get manageSingleSelf(): string {
    return this._translations.get('manage.single.self');
  }

  /**
   * Returns the value of translation message for key `manage.multiple.self`.
   * Default value: `Password`
   */
  get manageMultipleSelf(): string {
    return this._translations.get('manage.multiple.self');
  }

  /**
   * Returns the value of translation message for key `manage.single.user`.
   * Default value: `Password utente`
   */
  get manageSingleUser(): string {
    return this._translations.get('manage.single.user');
  }

  /**
   * Returns the value of translation message for key `manage.multiple.user`.
   * Default value: `Password utente`
   */
  get manageMultipleUser(): string {
    return this._translations.get('manage.multiple.user');
  }

  /**
   * Returns the value of translation message for key `securityAnswer`.
   * Default value: `Risposta di sicurezza`
   */
  get securityAnswer(): string {
    return this._translations.get('securityAnswer');
  }
}

export class I18n$Password$Forgotten {

  private _translations: Translations = new Translations('password.forgotten');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `introduction`.
   * Default value: `Hai dimenticato la password?`
   */
  get introduction(): string {
    return this._translations.get('introduction');
  }

  /**
   * Returns the value of translation message for key `preface`.
   * Default value: `Per motivi di sicurezza è necessario reimpostare una nuova password. Inserisci l’indirizzo e-mail o il nome utente che hai scelto in fase di registrazione e ti verrà inviata, alla mail di contatto, una password temporanea che potrai modificare successivamente con una a tuo piacere.`
   */
  get preface(): string {
    return this._translations.get('preface');
  }

  /**
   * Returns the value of translation message for key `principal`.
   * Default value: `Utente`
   */
  get principal(): string {
    return this._translations.get('principal');
  }

  /**
   * Returns the value of translation message for key `captcha`.
   * Default value: `Captcha validation`
   */
  get captcha(): string {
    return this._translations.get('captcha');
  }

  /**
   * Returns the value of translation message for key `code`.
   * Default value: `Verification code`
   */
  get code(): string {
    return this._translations.get('code');
  }

  /**
   * Returns the value of translation message for key `code.sent`.
   * Default value: `The verification code was sent to {to}`
   */
  codeSent(to: string | number): string {
    return this._translations.get('code.sent', {
      to: to
    });
  }

  /**
   * Returns the value of translation message for key `sendMedium`.
   * Default value: `Receive code by`
   */
  get sendMedium(): string {
    return this._translations.get('sendMedium');
  }

  /**
   * Returns the value of translation message for key `generate`.
   * Default value: `Generate password`
   */
  get generate(): string {
    return this._translations.get('generate');
  }

  /**
   * Returns the value of translation message for key `generated.message`.
   * Default value: `Dopo la conferma verrà generata una nuova password e inviata al tuo indirizzo e-mail`
   */
  get generatedMessage(): string {
    return this._translations.get('generated.message');
  }

  /**
   * Returns the value of translation message for key `generated.done.email`.
   * Default value: `Riceverai a breve una e-mail contenente la tua nuova password.`
   */
  get generatedDoneEmail(): string {
    return this._translations.get('generated.done.email');
  }

  /**
   * Returns the value of translation message for key `generated.done.sms`.
   * Default value: `You should receive shortly an SMS message with your new password.`
   */
  get generatedDoneSms(): string {
    return this._translations.get('generated.done.sms');
  }

  /**
   * Returns the value of translation message for key `manual.done`.
   * Default value: `La tua password è stata modificata con successo. Ora puoi usarla per accedere.`
   */
  get manualDone(): string {
    return this._translations.get('manual.done');
  }

  /**
   * Returns the value of translation message for key `title.changePassword`.
   * Default value: `Cambia la tua password`
   */
  get titleChangePassword(): string {
    return this._translations.get('title.changePassword');
  }
}

export class I18n$Password$Expired {

  private _translations: Translations = new Translations('password.expired');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `preface`.
   * Default value: `Il tuo {type} è scaduto.`
   */
  preface(type: string | number): string {
    return this._translations.get('preface', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `message.manual`.
   * Default value: `Per procedere, è necessario modificarla.`
   */
  get messageManual(): string {
    return this._translations.get('message.manual');
  }

  /**
   * Returns the value of translation message for key `message.generated`.
   * Default value: `Per procedere, deve essere generato un nuovo valore.<br>Questo valore verrà visualizzato solo una volta, quindi, assicurati di memorizzarlo o di annotarlo.`
   */
  get messageGenerated(): string {
    return this._translations.get('message.generated');
  }

  /**
   * Returns the value of translation message for key `generatedValue`.
   * Default value: `Il valore per {type} è <data>{value}</data>.<br>Questo valore non verrà visualizzato di nuovo, quindi, assicurati di memorizzarlo o di annotarlo.`
   */
  generatedValue($: {type: string | number, value: string | number}): string {
    return this._translations.get('generatedValue', {
      type: $.type,
      value: $.value
    });
  }

  /**
   * Returns the value of translation message for key `generateNew`.
   * Default value: `Genera nuovo`
   */
  get generateNew(): string {
    return this._translations.get('generateNew');
  }

  /**
   * Returns the value of translation message for key `changed`.
   * Default value: `Il tuo {type} è stato modificato`
   */
  changed(type: string | number): string {
    return this._translations.get('changed', {
      type: type
    });
  }
}

export class I18n$Password$Status {

  private _translations: Translations = new Translations('password.status');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Attivo`
   */
  get active(): string {
    return this._translations.get('active');
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabilitato`
   */
  get disabled(): string {
    return this._translations.get('disabled');
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Scaduto`
   */
  get expired(): string {
    return this._translations.get('expired');
  }

  /**
   * Returns the value of translation message for key `indefinitelyBlocked`.
   * Default value: `Bloccato`
   */
  get indefinitelyBlocked(): string {
    return this._translations.get('indefinitelyBlocked');
  }

  /**
   * Returns the value of translation message for key `neverCreated`.
   * Default value: `Mai creato`
   */
  get neverCreated(): string {
    return this._translations.get('neverCreated');
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `In attesa`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `Resetta`
   */
  get reset(): string {
    return this._translations.get('reset');
  }

  /**
   * Returns the value of translation message for key `temporarilyBlocked`.
   * Default value: `Temporaneamente bloccato`
   */
  get temporarilyBlocked(): string {
    return this._translations.get('temporarilyBlocked');
  }
}

export class I18n$Password$Action {

  private _translations: Translations = new Translations('password.action');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `change`.
   * Default value: `Modifica`
   */
  get change(): string {
    return this._translations.get('change');
  }

  /**
   * Returns the value of translation message for key `change.done`.
   * Default value: `La tua {type} è stata modificata`
   */
  changeDone(type: string | number): string {
    return this._translations.get('change.done', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `change.generated.confirm`.
   * Default value: `Questo genererà un nuovo {type}, e il valore verrà visualizzato solo una volta. Sei sicuro?`
   */
  changeGeneratedConfirm(type: string | number): string {
    return this._translations.get('change.generated.confirm', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `change.generated.done`.
   * Default value: `Il valore per {type} è <data>{value}</data>.<br>Memorizzalo, perché non verrà visualizzato di nuovo.`
   */
  changeGeneratedDone($: {type: string | number, value: string | number}): string {
    return this._translations.get('change.generated.done', {
      type: $.type,
      value: $.value
    });
  }

  /**
   * Returns the value of translation message for key `unblock`.
   * Default value: `Sblocca`
   */
  get unblock(): string {
    return this._translations.get('unblock');
  }

  /**
   * Returns the value of translation message for key `unblock.confirm`.
   * Default value: `Sei sicuro di sbloccare il tuo {type}?`
   */
  unblockConfirm(type: string | number): string {
    return this._translations.get('unblock.confirm', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `unblock.done`.
   * Default value: `Il tuo {type} è stato sbloccato`
   */
  unblockDone(type: string | number): string {
    return this._translations.get('unblock.done', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `activate`.
   * Default value: `Attiva`
   */
  get activate(): string {
    return this._translations.get('activate');
  }

  /**
   * Returns the value of translation message for key `activate.confirm`.
   * Default value: `Questo attiverà il tuo {type}, e il valore generato verrà visualizzato solo una volta. Sei sicuro?`
   */
  activateConfirm(type: string | number): string {
    return this._translations.get('activate.confirm', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `enable`.
   * Default value: `Abilita`
   */
  get enable(): string {
    return this._translations.get('enable');
  }

  /**
   * Returns the value of translation message for key `enable.confirm`.
   * Default value: `Sei sicuro di abilitare il tuo {type}?`
   */
  enableConfirm(type: string | number): string {
    return this._translations.get('enable.confirm', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `enable.done`.
   * Default value: `Il tuo {type} è stato abilitato`
   */
  enableDone(type: string | number): string {
    return this._translations.get('enable.done', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `disable`.
   * Default value: `Disattiva`
   */
  get disable(): string {
    return this._translations.get('disable');
  }

  /**
   * Returns the value of translation message for key `disable.confirm`.
   * Default value: `Sei sicuro di voler disattivare il tuo {type}?`
   */
  disableConfirm(type: string | number): string {
    return this._translations.get('disable.confirm', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `disable.done`.
   * Default value: `Il {type} è stato disattivato`
   */
  disableDone(type: string | number): string {
    return this._translations.get('disable.done', {
      type: type
    });
  }
}

export class I18n$PendingAgreements {

  private _translations: Translations = new Translations('pendingAgreements');

  private _nested = {
    title: new I18n$PendingAgreements$Title(),
    mobileTitle: new I18n$PendingAgreements$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `message.previouslyAccepted`.
   * Default value: `I termini e condizioni sono stati aggiornati.<br><br>Per continuare, devi accettare i nuovi termini e condizioni.`
   */
  get messagePreviouslyAccepted(): string {
    return this._translations.get('message.previouslyAccepted');
  }

  /**
   * Returns the value of translation message for key `message.firstTime`.
   * Default value: `In order to complete your registration, you must agree with these terms.`
   */
  get messageFirstTime(): string {
    return this._translations.get('message.firstTime');
  }

  /**
   * Returns the value of translation message for key `agree`.
   * Default value: `Accetto i seguenti termini e condizioni: {agreements}`
   */
  agree(agreements: string | number): string {
    return this._translations.get('agree', {
      agreements: agreements
    });
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$PendingAgreements$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$PendingAgreements$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$PendingAgreements$Title {

  private _translations: Translations = new Translations('pendingAgreements.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `previouslyAccepted`.
   * Default value: `Termini e condizioni aggiornati`
   */
  get previouslyAccepted(): string {
    return this._translations.get('previouslyAccepted');
  }

  /**
   * Returns the value of translation message for key `firstTime`.
   * Default value: `Termini e condizioni`
   */
  get firstTime(): string {
    return this._translations.get('firstTime');
  }
}

export class I18n$PendingAgreements$MobileTitle {

  private _translations: Translations = new Translations('pendingAgreements.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `previouslyAccepted`.
   * Default value: `Termini e condizioni aggiornati`
   */
  get previouslyAccepted(): string {
    return this._translations.get('previouslyAccepted');
  }

  /**
   * Returns the value of translation message for key `firstTime`.
   * Default value: `Termini e condizioni`
   */
  get firstTime(): string {
    return this._translations.get('firstTime');
  }
}

export class I18n$SecurityQuestion {

  private _translations: Translations = new Translations('securityQuestion');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `La domanda di sicurezza è richiesta nel caso in cui sia necessario reimpostare la password. Una volta impostata, se si vuole modificare, contattare l'amministrazione.`
   */
  get message(): string {
    return this._translations.get('message');
  }

  /**
   * Returns the value of translation message for key `question`.
   * Default value: `Domanda di sicurezza`
   */
  get question(): string {
    return this._translations.get('question');
  }

  /**
   * Returns the value of translation message for key `answer`.
   * Default value: `La tua risposta`
   */
  get answer(): string {
    return this._translations.get('answer');
  }

  /**
   * Returns the value of translation message for key `set`.
   * Default value: `La tua risposta di sicurezza è stata impostata`
   */
  get set(): string {
    return this._translations.get('set');
  }
}

export class I18n$Dashboard {

  private _translations: Translations = new Translations('dashboard');

  private _nested = {
    action: new I18n$Dashboard$Action(),
    passwords: new I18n$Dashboard$Passwords()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.action.defaultValues = defaultValues['action'];
    this._nested.passwords.defaultValues = defaultValues['passwords'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.action.initialize(values['action']);
    this._nested.passwords.initialize(values['passwords']);
  }

  /**
   * Returns the value of translation message for key `quickAccess`.
   * Default value: `Accesso rapido`
   */
  get quickAccess(): string {
    return this._translations.get('quickAccess');
  }

  /**
   * Returns the value of translation message for key `quickAccess.shortcutTemplate`.
   * Default value: `{shortcut}: {label}`
   */
  quickAccessShortcutTemplate($: {shortcut: string | number, label: string | number}): string {
    return this._translations.get('quickAccess.shortcutTemplate', {
      shortcut: $.shortcut,
      label: $.label
    });
  }

  /**
   * Returns the value of translation message for key `accountStatus`.
   * Default value: `Stato conto`
   */
  get accountStatus(): string {
    return this._translations.get('accountStatus');
  }

  /**
   * Returns the value of translation message for key `lastIncomingPayments`.
   * Default value: `Ultimi pagamenti in arrivo`
   */
  get lastIncomingPayments(): string {
    return this._translations.get('lastIncomingPayments');
  }

  /**
   * Returns the value of translation message for key `noIncomingPayments`.
   * Default value: `Nessun pagamento in arrivo`
   */
  get noIncomingPayments(): string {
    return this._translations.get('noIncomingPayments');
  }

  /**
   * Returns the value of translation message for key `latestAds`.
   * Default value: `Ultimi annunci`
   */
  get latestAds(): string {
    return this._translations.get('latestAds');
  }

  /**
   * Returns the value of translation message for key `latestUsers`.
   * Default value: `Ultimi utenti`
   */
  get latestUsers(): string {
    return this._translations.get('latestUsers');
  }

  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  get action(): I18n$Dashboard$Action {
    return this._nested.action;
  }

  /**
   * Returns the nested accessor for translation messages in `passwords`.
   */
  get passwords(): I18n$Dashboard$Passwords {
    return this._nested.passwords;
  }
}

export class I18n$Dashboard$Action {

  private _translations: Translations = new Translations('dashboard.action');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Conto`
   */
  get account(): string {
    return this._translations.get('account');
  }

  /**
   * Returns the value of translation message for key `payUser`.
   * Default value: `Paga`
   */
  get payUser(): string {
    return this._translations.get('payUser');
  }

  /**
   * Returns the value of translation message for key `paySystem`.
   * Default value: `Paga sistema`
   */
  get paySystem(): string {
    return this._translations.get('paySystem');
  }

  /**
   * Returns the value of translation message for key `pos`.
   * Default value: `Incassa`
   */
  get pos(): string {
    return this._translations.get('pos');
  }

  /**
   * Returns the value of translation message for key `contacts`.
   * Default value: `Rubrica`
   */
  get contacts(): string {
    return this._translations.get('contacts');
  }

  /**
   * Returns the value of translation message for key `directory`.
   * Default value: `Aziende`
   */
  get directory(): string {
    return this._translations.get('directory');
  }

  /**
   * Returns the value of translation message for key `advertisements`.
   * Default value: `Marketplace`
   */
  get advertisements(): string {
    return this._translations.get('advertisements');
  }

  /**
   * Returns the value of translation message for key `editProfile`.
   * Default value: `Modifica profilo`
   */
  get editProfile(): string {
    return this._translations.get('editProfile');
  }

  /**
   * Returns the value of translation message for key `password`.
   * Default value: `Password`
   */
  get password(): string {
    return this._translations.get('password');
  }

  /**
   * Returns the value of translation message for key `passwords`.
   * Default value: `Password`
   */
  get passwords(): string {
    return this._translations.get('passwords');
  }

  /**
   * Returns the value of translation message for key `switchTheme`.
   * Default value: `Cambia tema`
   */
  get switchTheme(): string {
    return this._translations.get('switchTheme');
  }

  /**
   * Returns the value of translation message for key `connectedUsers`.
   * Default value: `Utenti connessi`
   */
  get connectedUsers(): string {
    return this._translations.get('connectedUsers');
  }
}

export class I18n$Dashboard$Passwords {

  private _translations: Translations = new Translations('dashboard.passwords');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Il tuo {type} è scaduto.`
   */
  expired(type: string | number): string {
    return this._translations.get('expired', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `Il tuo {type} è stato resettato.`
   */
  reset(type: string | number): string {
    return this._translations.get('reset', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Il tuo {type} è in attesa di generazione.`
   */
  pending(type: string | number): string {
    return this._translations.get('pending', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `neverCreated`.
   * Default value: `Il tuo {type} deve essere impostato.`
   */
  neverCreated(type: string | number): string {
    return this._translations.get('neverCreated', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `securityAnswer`.
   * Default value: `Dovresti impostare la domanda di sicurezza, che sarà necessaria nel caso in cui dovrai reimpostare la password.`
   */
  get securityAnswer(): string {
    return this._translations.get('securityAnswer');
  }

  /**
   * Returns the value of translation message for key `proceed`.
   * Default value: `Clicca qui per procedere`
   */
  get proceed(): string {
    return this._translations.get('proceed');
  }
}

export class I18n$Account {

  private _translations: Translations = new Translations('account');

  private _nested = {
    orderBy: new I18n$Account$OrderBy()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.orderBy.defaultValues = defaultValues['orderBy'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.orderBy.initialize(values['orderBy']);
  }

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Conto`
   */
  get account(): string {
    return this._translations.get('account');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Numero conto`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `transferFilter`.
   * Default value: `Filtro`
   */
  get transferFilter(): string {
    return this._translations.get('transferFilter');
  }

  /**
   * Returns the value of translation message for key `minAmount`.
   * Default value: `Importo min`
   */
  get minAmount(): string {
    return this._translations.get('minAmount');
  }

  /**
   * Returns the value of translation message for key `maxAmount`.
   * Default value: `Importo max`
   */
  get maxAmount(): string {
    return this._translations.get('maxAmount');
  }

  /**
   * Returns the value of translation message for key `direction`.
   * Default value: `Transazione`
   */
  get direction(): string {
    return this._translations.get('direction');
  }

  /**
   * Returns the value of translation message for key `reason`.
   * Default value: `Causale`
   */
  get reason(): string {
    return this._translations.get('reason');
  }

  /**
   * Returns the value of translation message for key `incoming`.
   * Default value: `Entrate`
   */
  get incoming(): string {
    return this._translations.get('incoming');
  }

  /**
   * Returns the value of translation message for key `outgoing`.
   * Default value: `Uscite`
   */
  get outgoing(): string {
    return this._translations.get('outgoing');
  }

  /**
   * Returns the value of translation message for key `balance`.
   * Default value: `Saldo contabile`
   */
  get balance(): string {
    return this._translations.get('balance');
  }

  /**
   * Returns the value of translation message for key `reservedAmount`.
   * Default value: `Importo riservato`
   */
  get reservedAmount(): string {
    return this._translations.get('reservedAmount');
  }

  /**
   * Returns the value of translation message for key `availableBalance`.
   * Default value: `Saldo disponibile`
   */
  get availableBalance(): string {
    return this._translations.get('availableBalance');
  }

  /**
   * Returns the value of translation message for key `negativeLimit`.
   * Default value: `Massimale scoperto`
   */
  get negativeLimit(): string {
    return this._translations.get('negativeLimit');
  }

  /**
   * Returns the value of translation message for key `positiveLimit`.
   * Default value: `Limite positivo`
   */
  get positiveLimit(): string {
    return this._translations.get('positiveLimit');
  }

  /**
   * Returns the value of translation message for key `overdraftCeiling`.
   * Default value: `Massimale di scoperto`
   */
  get overdraftCeiling(): string {
    return this._translations.get('overdraftCeiling');
  }

  /**
   * Returns the value of translation message for key `commercialAvailability`.
   * Default value: `Disponibilità commerciale`
   */
  get commercialAvailability(): string {
    return this._translations.get('commercialAvailability');
  }

  /**
   * Returns the value of translation message for key `balanceOn`.
   * Default value: `Saldo - {date}`
   */
  balanceOn(date: string | number): string {
    return this._translations.get('balanceOn', {
      date: date
    });
  }

  /**
   * Returns the value of translation message for key `totalIncome`.
   * Default value: `Totale entrate`
   */
  get totalIncome(): string {
    return this._translations.get('totalIncome');
  }

  /**
   * Returns the value of translation message for key `totalOutflow`.
   * Default value: `Totale uscite`
   */
  get totalOutflow(): string {
    return this._translations.get('totalOutflow');
  }

  /**
   * Returns the value of translation message for key `netInflow`.
   * Default value: `Saldo entrate/uscite`
   */
  get netInflow(): string {
    return this._translations.get('netInflow');
  }

  /**
   * Returns the value of translation message for key `noAccounts`.
   * Default value: `Non hai alcun conto`
   */
  get noAccounts(): string {
    return this._translations.get('noAccounts');
  }

  /**
   * Returns the value of translation message for key `printTransactions`.
   * Default value: `Stampa transazioni`
   */
  get printTransactions(): string {
    return this._translations.get('printTransactions');
  }

  /**
   * Returns the value of translation message for key `system`.
   * Default value: `Conto di sistema`
   */
  get system(): string {
    return this._translations.get('system');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Conto`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `complFranchise`.
   * Default value: `Franchigia`
   */
  get complFranchise(): string {
    return this._translations.get('complFranchise');
  }

  /**
   * Returns the value of translation message for key `complPercMin`.
   * Default value: `% minima complementare`
   */
  get complPercMin(): string {
    return this._translations.get('complPercMin');
  }

  /**
   * Returns the value of translation message for key `complPercDef`.
   * Default value: `% default complementare`
   */
  get complPercDef(): string {
    return this._translations.get('complPercDef');
  }

  /**
   * Returns the value of translation message for key `complPercMax`.
   * Default value: `% massima complementare`
   */
  get complPercMax(): string {
    return this._translations.get('complPercMax');
  }

  /**
   * Returns the value of translation message for key `franchiseOutRange`.
   * Default value: `Valore fuori intervallo. Inserisci un numero tra 0 e 9999.`
   */
  get franchiseOutRange(): string {
    return this._translations.get('franchiseOutRange');
  }

  /**
   * Returns the value of translation message for key `outRange`.
   * Default value: `Valore fuori intervallo`
   */
  get outRange(): string {
    return this._translations.get('outRange');
  }

  /**
   * Returns the value of translation message for key `upperCreditLimitToHigh`.
   * Default value: `Valore minore rispetto all'attuale saldo contabile.`
   */
  get upperCreditLimitToHigh(): string {
    return this._translations.get('upperCreditLimitToHigh');
  }

  /**
   * Returns the value of translation message for key `creditLimitToLow`.
   * Default value: `Valore maggiore rispetto all'attuale saldo contabile.`
   */
  get creditLimitToLow(): string {
    return this._translations.get('creditLimitToLow');
  }

  /**
   * Returns the value of translation message for key `minMaxDefOrder`.
   * Default value: `Attenzione! Verifica che % minima complementare <= % default complementare <= % massima complementare.`
   */
  get minMaxDefOrder(): string {
    return this._translations.get('minMaxDefOrder');
  }

  /**
   * Returns the value of translation message for key `update.success`.
   * Default value: `Conto aggiornato con successo`
   */
  get updateSuccess(): string {
    return this._translations.get('update.success');
  }

  /**
   * Returns the value of translation message for key `vatNumber`.
   * Default value: `Partita IVA`
   */
  get vatNumber(): string {
    return this._translations.get('vatNumber');
  }

  /**
   * Returns the value of translation message for key `description`.
   * Default value: `Decrizione`
   */
  get description(): string {
    return this._translations.get('description');
  }

  /**
   * Returns the value of translation message for key `wholesaleDiscount`.
   * Default value: `Sconto per vendita all’ingrosso`
   */
  get wholesaleDiscount(): string {
    return this._translations.get('wholesaleDiscount');
  }

  /**
   * Returns the value of translation message for key `facebook`.
   * Default value: `Facebook`
   */
  get facebook(): string {
    return this._translations.get('facebook');
  }

  /**
   * Returns the value of translation message for key `instagram`.
   * Default value: `Instagram`
   */
  get instagram(): string {
    return this._translations.get('instagram');
  }

  /**
   * Returns the value of translation message for key `linkedin`.
   * Default value: `Linkedin`
   */
  get linkedin(): string {
    return this._translations.get('linkedin');
  }

  /**
   * Returns the value of translation message for key `twitter`.
   * Default value: `Twitter`
   */
  get twitter(): string {
    return this._translations.get('twitter');
  }

  /**
   * Returns the value of translation message for key `publicLiaison`.
   * Default value: `Persona di contatto per le relazioni pubbliche`
   */
  get publicLiaison(): string {
    return this._translations.get('publicLiaison');
  }

  /**
   * Returns the value of translation message for key `publicLiaisonContact`.
   * Default value: `Contatto della persona per le relazioni pubbliche`
   */
  get publicLiaisonContact(): string {
    return this._translations.get('publicLiaisonContact');
  }

  /**
   * Returns the value of translation message for key `percentageApplied`.
   * Default value: `Percentuale applicata`
   */
  get percentageApplied(): string {
    return this._translations.get('percentageApplied');
  }

  /**
   * Returns the value of translation message for key `circuit`.
   * Default value: `Circuito`
   */
  get circuit(): string {
    return this._translations.get('circuit');
  }

  /**
   * Returns the value of translation message for key `keywords`.
   * Default value: `Parole chiave`
   */
  get keywords(): string {
    return this._translations.get('keywords');
  }

  /**
   * Returns the value of translation message for key `fiscalCode`.
   * Default value: `Codice Fiscale`
   */
  get fiscalCode(): string {
    return this._translations.get('fiscalCode');
  }

  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  get orderBy(): I18n$Account$OrderBy {
    return this._nested.orderBy;
  }
}

export class I18n$Account$OrderBy {

  private _translations: Translations = new Translations('account.orderBy');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `dateDesc`.
   * Default value: `Data (dalla più recente)`
   */
  get dateDesc(): string {
    return this._translations.get('dateDesc');
  }

  /**
   * Returns the value of translation message for key `dateAsc`.
   * Default value: `Data (dalla meno recente)`
   */
  get dateAsc(): string {
    return this._translations.get('dateAsc');
  }

  /**
   * Returns the value of translation message for key `amountAsc`.
   * Default value: `Importo (crescente)`
   */
  get amountAsc(): string {
    return this._translations.get('amountAsc');
  }

  /**
   * Returns the value of translation message for key `amountDesc`.
   * Default value: `Importo (decrescente)`
   */
  get amountDesc(): string {
    return this._translations.get('amountDesc');
  }
}

export class I18n$Transaction {

  private _translations: Translations = new Translations('transaction');

  private _nested = {
    schedulingStatus: new I18n$Transaction$SchedulingStatus(),
    status: new I18n$Transaction$Status(),
    title: new I18n$Transaction$Title(),
    mobileTitle: new I18n$Transaction$MobileTitle(),
    error: new I18n$Transaction$Error()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.schedulingStatus.defaultValues = defaultValues['schedulingStatus'];
    this._nested.status.defaultValues = defaultValues['status'];
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.error.defaultValues = defaultValues['error'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.schedulingStatus.initialize(values['schedulingStatus']);
    this._nested.status.initialize(values['status']);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.error.initialize(values['error']);
  }

  /**
   * Returns the value of translation message for key `authorize`.
   * Default value: `Autorizza`
   */
  get authorize(): string {
    return this._translations.get('authorize');
  }

  /**
   * Returns the value of translation message for key `deny`.
   * Default value: `Rifiuta`
   */
  get deny(): string {
    return this._translations.get('deny');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Numero Transazione`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `amount`.
   * Default value: `Importo crediti`
   */
  get amount(): string {
    return this._translations.get('amount');
  }

  /**
   * Returns the value of translation message for key `fiatAmount`.
   * Default value: `Totale scontrino / fattura`
   */
  get fiatAmount(): string {
    return this._translations.get('fiatAmount');
  }

  /**
   * Returns the value of translation message for key `type`.
   * Default value: `Tipo di pagamento`
   */
  get type(): string {
    return this._translations.get('type');
  }

  /**
   * Returns the value of translation message for key `fromTo`.
   * Default value: `Da / a`
   */
  get fromTo(): string {
    return this._translations.get('fromTo');
  }

  /**
   * Returns the value of translation message for key `fromTo.text`.
   * Default value: `{from} a {to}`
   */
  fromToText($: {from: string | number, to: string | number}): string {
    return this._translations.get('fromTo.text', {
      from: $.from,
      to: $.to
    });
  }

  /**
   * Returns the value of translation message for key `from`.
   * Default value: `Da`
   */
  get from(): string {
    return this._translations.get('from');
  }

  /**
   * Returns the value of translation message for key `to`.
   * Default value: `A`
   */
  get to(): string {
    return this._translations.get('to');
  }

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Conto`
   */
  get account(): string {
    return this._translations.get('account');
  }

  /**
   * Returns the value of translation message for key `fromAccount`.
   * Default value: `Dal conto`
   */
  get fromAccount(): string {
    return this._translations.get('fromAccount');
  }

  /**
   * Returns the value of translation message for key `fromUser`.
   * Default value: `Da cliente`
   */
  get fromUser(): string {
    return this._translations.get('fromUser');
  }

  /**
   * Returns the value of translation message for key `toAccount`.
   * Default value: `Al conto`
   */
  get toAccount(): string {
    return this._translations.get('toAccount');
  }

  /**
   * Returns the value of translation message for key `toUser`.
   * Default value: `Destinatario`
   */
  get toUser(): string {
    return this._translations.get('toUser');
  }

  /**
   * Returns the value of translation message for key `toPayInEuros`.
   * Default value: `Importo euro`
   */
  get toPayInEuros(): string {
    return this._translations.get('toPayInEuros');
  }

  /**
   * Returns the value of translation message for key `requiresAuthorization`.
   * Default value: `Il pagamento richiederà l'autorizzazione`
   */
  get requiresAuthorization(): string {
    return this._translations.get('requiresAuthorization');
  }

  /**
   * Returns the value of translation message for key `appliedFees`.
   * Default value: `Costi applicati`
   */
  get appliedFees(): string {
    return this._translations.get('appliedFees');
  }

  /**
   * Returns the value of translation message for key `totalAmount`.
   * Default value: `Importo totale`
   */
  get totalAmount(): string {
    return this._translations.get('totalAmount');
  }

  /**
   * Returns the value of translation message for key `dueAmount`.
   * Default value: `Importo dovuto`
   */
  get dueAmount(): string {
    return this._translations.get('dueAmount');
  }

  /**
   * Returns the value of translation message for key `dueDate`.
   * Default value: `Data scadenza`
   */
  get dueDate(): string {
    return this._translations.get('dueDate');
  }

  /**
   * Returns the value of translation message for key `nextOccurrence`.
   * Default value: `Prossima ricorrenza`
   */
  get nextOccurrence(): string {
    return this._translations.get('nextOccurrence');
  }

  /**
   * Returns the value of translation message for key `channel`.
   * Default value: `Canale`
   */
  get channel(): string {
    return this._translations.get('channel');
  }

  /**
   * Returns the value of translation message for key `receivedBy`.
   * Default value: `Ricevuto da`
   */
  get receivedBy(): string {
    return this._translations.get('receivedBy');
  }

  /**
   * Returns the value of translation message for key `chargebackOf`.
   * Default value: `Storno di`
   */
  get chargebackOf(): string {
    return this._translations.get('chargebackOf');
  }

  /**
   * Returns the value of translation message for key `chargedBackBy`.
   * Default value: `Stornato da`
   */
  get chargedBackBy(): string {
    return this._translations.get('chargedBackBy');
  }

  /**
   * Returns the value of translation message for key `authorizationComments`.
   * Default value: `Commenti di autorizzazione`
   */
  get authorizationComments(): string {
    return this._translations.get('authorizationComments');
  }

  /**
   * Returns the value of translation message for key `accountBalance`.
   * Default value: `Saldo disponibile`
   */
  get accountBalance(): string {
    return this._translations.get('accountBalance');
  }

  /**
   * Returns the value of translation message for key `installments`.
   * Default value: `Rate`
   */
  get installments(): string {
    return this._translations.get('installments');
  }

  /**
   * Returns the value of translation message for key `installmentNumber`.
   * Default value: `Numero`
   */
  get installmentNumber(): string {
    return this._translations.get('installmentNumber');
  }

  /**
   * Returns the value of translation message for key `occurrences`.
   * Default value: `Occorrenze`
   */
  get occurrences(): string {
    return this._translations.get('occurrences');
  }

  /**
   * Returns the value of translation message for key `noAccounts`.
   * Default value: `Non hai nessun conto per eseguire il pagamento`
   */
  get noAccounts(): string {
    return this._translations.get('noAccounts');
  }

  /**
   * Returns the value of translation message for key `noTypes`.
   * Default value: `Non ci sono tipi di pagamento possibili`
   */
  get noTypes(): string {
    return this._translations.get('noTypes');
  }

  /**
   * Returns the value of translation message for key `noTypesSelection`.
   * Default value: `Non ci sono tipi di pagamento possibili da questo conto al cliente selezionato`
   */
  get noTypesSelection(): string {
    return this._translations.get('noTypesSelection');
  }

  /**
   * Returns the value of translation message for key `scheduling`.
   * Default value: `Pianificazione`
   */
  get scheduling(): string {
    return this._translations.get('scheduling');
  }

  /**
   * Returns the value of translation message for key `scheduling.direct`.
   * Default value: `Paga ora`
   */
  get schedulingDirect(): string {
    return this._translations.get('scheduling.direct');
  }

  /**
   * Returns the value of translation message for key `scheduling.single`.
   * Default value: `Pianificato`
   */
  get schedulingSingle(): string {
    return this._translations.get('scheduling.single');
  }

  /**
   * Returns the value of translation message for key `scheduling.installments`.
   * Default value: `Rate mensili`
   */
  get schedulingInstallments(): string {
    return this._translations.get('scheduling.installments');
  }

  /**
   * Returns the value of translation message for key `scheduling.recurring`.
   * Default value: `Ripeti mensilmente`
   */
  get schedulingRecurring(): string {
    return this._translations.get('scheduling.recurring');
  }

  /**
   * Returns the value of translation message for key `recurringPayment`.
   * Default value: `Pagamento ricorrente`
   */
  get recurringPayment(): string {
    return this._translations.get('recurringPayment');
  }

  /**
   * Returns the value of translation message for key `recurringPayment.nowManual`.
   * Default value: `Ripeti fino a quando non è annullato manualmente, a partire da ora`
   */
  get recurringPaymentNowManual(): string {
    return this._translations.get('recurringPayment.nowManual');
  }

  /**
   * Returns the value of translation message for key `recurringPayment.dateManual`.
   * Default value: `Ripeti fino a quando non è annullato manualmente, a partire da {date}`
   */
  recurringPaymentDateManual(date: string | number): string {
    return this._translations.get('recurringPayment.dateManual', {
      date: date
    });
  }

  /**
   * Returns the value of translation message for key `recurringPayment.nowFixed`.
   * Default value: `Si ripete {count} volte, a partire da ora`
   */
  recurringPaymentNowFixed(count: string | number): string {
    return this._translations.get('recurringPayment.nowFixed', {
      count: count
    });
  }

  /**
   * Returns the value of translation message for key `recurringPayment.dateFixed`.
   * Default value: `Si ripete {count} volte, a partire da {date}`
   */
  recurringPaymentDateFixed($: {count: string | number, date: string | number}): string {
    return this._translations.get('recurringPayment.dateFixed', {
      count: $.count,
      date: $.date
    });
  }

  /**
   * Returns the value of translation message for key `installmentsCount`.
   * Default value: `Numero di rate`
   */
  get installmentsCount(): string {
    return this._translations.get('installmentsCount');
  }

  /**
   * Returns the value of translation message for key `firstInstallment`.
   * Default value: `Prima rata`
   */
  get firstInstallment(): string {
    return this._translations.get('firstInstallment');
  }

  /**
   * Returns the value of translation message for key `firstInstallment.date`.
   * Default value: `Data prima rata`
   */
  get firstInstallmentDate(): string {
    return this._translations.get('firstInstallment.date');
  }

  /**
   * Returns the value of translation message for key `repeatUntil`.
   * Default value: `Ripeti fino a`
   */
  get repeatUntil(): string {
    return this._translations.get('repeatUntil');
  }

  /**
   * Returns the value of translation message for key `repeatUntil.manual`.
   * Default value: `Annullato manualmente`
   */
  get repeatUntilManual(): string {
    return this._translations.get('repeatUntil.manual');
  }

  /**
   * Returns the value of translation message for key `repeatUntil.fixed`.
   * Default value: `Numero fisso di occorrenze`
   */
  get repeatUntilFixed(): string {
    return this._translations.get('repeatUntil.fixed');
  }

  /**
   * Returns the value of translation message for key `occurrencesCount`.
   * Default value: `Numero di occorrenze`
   */
  get occurrencesCount(): string {
    return this._translations.get('occurrencesCount');
  }

  /**
   * Returns the value of translation message for key `firstOccurrence`.
   * Default value: `Prima occorrenza`
   */
  get firstOccurrence(): string {
    return this._translations.get('firstOccurrence');
  }

  /**
   * Returns the value of translation message for key `firstOccurrence.date`.
   * Default value: `Data prima occorrenza`
   */
  get firstOccurrenceDate(): string {
    return this._translations.get('firstOccurrence.date');
  }

  /**
   * Returns the value of translation message for key `processed`.
   * Default value: `Il tuo pagamento è stato processato correttamente`
   */
  get processed(): string {
    return this._translations.get('processed');
  }

  /**
   * Returns the value of translation message for key `processed.withNumber`.
   * Default value: `Il pagamento con il numero di transazione {number} è stato elaborato con successo`
   */
  processedWithNumber(number: string | number): string {
    return this._translations.get('processed.withNumber', {
      number: number
    });
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Il pagamento è stato inviato per ulteriore autorizzazione`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `pending.withNumber`.
   * Default value: `Il pagamento con il numero di transazione {number} è stato inviato per ulteriore autorizzazione`
   */
  pendingWithNumber(number: string | number): string {
    return this._translations.get('pending.withNumber', {
      number: number
    });
  }

  /**
   * Returns the value of translation message for key `done.view`.
   * Default value: `Vedi pagamento`
   */
  get doneView(): string {
    return this._translations.get('done.view');
  }

  /**
   * Returns the value of translation message for key `done.new`.
   * Default value: `Nuovo pagamento`
   */
  get doneNew(): string {
    return this._translations.get('done.new');
  }

  /**
   * Returns the value of translation message for key `viewAuthorizations`.
   * Default value: `Mostra autorizzazioni`
   */
  get viewAuthorizations(): string {
    return this._translations.get('viewAuthorizations');
  }

  /**
   * Returns the value of translation message for key `viewThisTransfer`.
   * Default value: `Mostra operazione`
   */
  get viewThisTransfer(): string {
    return this._translations.get('viewThisTransfer');
  }

  /**
   * Returns the value of translation message for key `authorizePending`.
   * Default value: `Autorizza il pagamento in sospeso`
   */
  get authorizePending(): string {
    return this._translations.get('authorizePending');
  }

  /**
   * Returns the value of translation message for key `authorizePending.done.stillPending`.
   * Default value: `Il pagamento ha ancora bisogno di un'altra autorizzazione per essere processato`
   */
  get authorizePendingDoneStillPending(): string {
    return this._translations.get('authorizePending.done.stillPending');
  }

  /**
   * Returns the value of translation message for key `authorizePending.done`.
   * Default value: `Il pagamento è stato autorizzato`
   */
  get authorizePendingDone(): string {
    return this._translations.get('authorizePending.done');
  }

  /**
   * Returns the value of translation message for key `denyPending`.
   * Default value: `Nega pagamento in sospeso`
   */
  get denyPending(): string {
    return this._translations.get('denyPending');
  }

  /**
   * Returns the value of translation message for key `denyPending.done`.
   * Default value: `Il pagamento è stato negato`
   */
  get denyPendingDone(): string {
    return this._translations.get('denyPending.done');
  }

  /**
   * Returns the value of translation message for key `cancelAuthorization`.
   * Default value: `Annulla il processo di autorizzazione`
   */
  get cancelAuthorization(): string {
    return this._translations.get('cancelAuthorization');
  }

  /**
   * Returns the value of translation message for key `cancelAuthorization.done`.
   * Default value: `L'autorizzazione di pagamento è stata annullata`
   */
  get cancelAuthorizationDone(): string {
    return this._translations.get('cancelAuthorization.done');
  }

  /**
   * Returns the value of translation message for key `blockScheduling`.
   * Default value: `Blocca pianificazione`
   */
  get blockScheduling(): string {
    return this._translations.get('blockScheduling');
  }

  /**
   * Returns the value of translation message for key `blockScheduling.message`.
   * Default value: `Questo impedirà l'elaborazione automatica delle rate pianificate`
   */
  get blockSchedulingMessage(): string {
    return this._translations.get('blockScheduling.message');
  }

  /**
   * Returns the value of translation message for key `blockScheduling.done`.
   * Default value: `Questo pagamento pianificato non sarà più elaborato automaticamente`
   */
  get blockSchedulingDone(): string {
    return this._translations.get('blockScheduling.done');
  }

  /**
   * Returns the value of translation message for key `unblockScheduling`.
   * Default value: `Sblocca pianificazione`
   */
  get unblockScheduling(): string {
    return this._translations.get('unblockScheduling');
  }

  /**
   * Returns the value of translation message for key `unblockScheduling.message`.
   * Default value: `Questo riprenderà l'elaborazione automatica per le rate pianificate`
   */
  get unblockSchedulingMessage(): string {
    return this._translations.get('unblockScheduling.message');
  }

  /**
   * Returns the value of translation message for key `unblockScheduling.done`.
   * Default value: `Questo pagamento pianificato verrà elaborato automaticamente`
   */
  get unblockSchedulingDone(): string {
    return this._translations.get('unblockScheduling.done');
  }

  /**
   * Returns the value of translation message for key `cancelScheduled`.
   * Default value: `Annulla questo pagamento pianificato`
   */
  get cancelScheduled(): string {
    return this._translations.get('cancelScheduled');
  }

  /**
   * Returns the value of translation message for key `cancelScheduled.message`.
   * Default value: `Questo cancellerà definitivamente questo pagamento pianificato`
   */
  get cancelScheduledMessage(): string {
    return this._translations.get('cancelScheduled.message');
  }

  /**
   * Returns the value of translation message for key `cancelScheduled.done`.
   * Default value: `Questo pagamento pianificato è stato annullato`
   */
  get cancelScheduledDone(): string {
    return this._translations.get('cancelScheduled.done');
  }

  /**
   * Returns the value of translation message for key `settleScheduled`.
   * Default value: `Salda il pagamento previsto`
   */
  get settleScheduled(): string {
    return this._translations.get('settleScheduled');
  }

  /**
   * Returns the value of translation message for key `settleScheduled.message`.
   * Default value: `Questo contrassegna permanentemente tutte le rate rimanenti come risolte`
   */
  get settleScheduledMessage(): string {
    return this._translations.get('settleScheduled.message');
  }

  /**
   * Returns the value of translation message for key `settleScheduled.done`.
   * Default value: `Questo pagamento pianificato è stato completato`
   */
  get settleScheduledDone(): string {
    return this._translations.get('settleScheduled.done');
  }

  /**
   * Returns the value of translation message for key `cancelRecurring`.
   * Default value: `Annulla questo pagamento ricorrente`
   */
  get cancelRecurring(): string {
    return this._translations.get('cancelRecurring');
  }

  /**
   * Returns the value of translation message for key `cancelRecurring.message`.
   * Default value: `Questo cancellerà definitivamente il pagamento ricorrente e impedirà qualsiasi evento futuro`
   */
  get cancelRecurringMessage(): string {
    return this._translations.get('cancelRecurring.message');
  }

  /**
   * Returns the value of translation message for key `cancelRecurring.done`.
   * Default value: `Questo pagamento ricorrente è stato annullato`
   */
  get cancelRecurringDone(): string {
    return this._translations.get('cancelRecurring.done');
  }

  /**
   * Returns the value of translation message for key `chargebackTransfer`.
   * Default value: `Storna questo trasferimento`
   */
  get chargebackTransfer(): string {
    return this._translations.get('chargebackTransfer');
  }

  /**
   * Returns the value of translation message for key `chargebackTransfer.message`.
   * Default value: `Questo restituirà l'importo di questo trasferimento al pagatore`
   */
  get chargebackTransferMessage(): string {
    return this._translations.get('chargebackTransfer.message');
  }

  /**
   * Returns the value of translation message for key `chargebackTransfer.done`.
   * Default value: `Questo trasferimento è stato stornato`
   */
  get chargebackTransferDone(): string {
    return this._translations.get('chargebackTransfer.done');
  }

  /**
   * Returns the value of translation message for key `processInstallment`.
   * Default value: `Elabora questa rata`
   */
  get processInstallment(): string {
    return this._translations.get('processInstallment');
  }

  /**
   * Returns the value of translation message for key `processInstallment.message`.
   * Default value: `Sei sicuro di voler elaborare ora la rata numero {number}?`
   */
  processInstallmentMessage(number: string | number): string {
    return this._translations.get('processInstallment.message', {
      number: number
    });
  }

  /**
   * Returns the value of translation message for key `processInstallment.done`.
   * Default value: `La rata è stata elaborata`
   */
  get processInstallmentDone(): string {
    return this._translations.get('processInstallment.done');
  }

  /**
   * Returns the value of translation message for key `settleInstallment`.
   * Default value: `Salda questa rata`
   */
  get settleInstallment(): string {
    return this._translations.get('settleInstallment');
  }

  /**
   * Returns the value of translation message for key `settleInstallment.message`.
   * Default value: `Sei sicuro di voler saldare la rata numero {number}?`
   */
  settleInstallmentMessage(number: string | number): string {
    return this._translations.get('settleInstallment.message', {
      number: number
    });
  }

  /**
   * Returns the value of translation message for key `settleInstallment.done`.
   * Default value: `La rata è stata salda`
   */
  get settleInstallmentDone(): string {
    return this._translations.get('settleInstallment.done');
  }

  /**
   * Returns the value of translation message for key `processFailedOccurrence`.
   * Default value: `Elabora questa occorrenza fallita`
   */
  get processFailedOccurrence(): string {
    return this._translations.get('processFailedOccurrence');
  }

  /**
   * Returns the value of translation message for key `processFailedOccurrence.message`.
   * Default value: `Sei sicuro di voler processare ora l'occorrenza numero {number}?`
   */
  processFailedOccurrenceMessage(number: string | number): string {
    return this._translations.get('processFailedOccurrence.message', {
      number: number
    });
  }

  /**
   * Returns the value of translation message for key `processFailedOccurrence.done`.
   * Default value: `L'occorrenza è stata elaborata`
   */
  get processFailedOccurrenceDone(): string {
    return this._translations.get('processFailedOccurrence.done');
  }

  /**
   * Returns the nested accessor for translation messages in `schedulingStatus`.
   */
  get schedulingStatus(): I18n$Transaction$SchedulingStatus {
    return this._nested.schedulingStatus;
  }

  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  get status(): I18n$Transaction$Status {
    return this._nested.status;
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Transaction$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Transaction$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Transaction$Error {
    return this._nested.error;
  }
}

export class I18n$Transaction$SchedulingStatus {

  private _translations: Translations = new Translations('transaction.schedulingStatus');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `direct`.
   * Default value: `Pagamento diretto`
   */
  get direct(): string {
    return this._translations.get('direct');
  }

  /**
   * Returns the value of translation message for key `scheduledToDate`.
   * Default value: `Pianificato il {date}`
   */
  scheduledToDate(date: string | number): string {
    return this._translations.get('scheduledToDate', {
      date: date
    });
  }

  /**
   * Returns the value of translation message for key `openInstallments`.
   * Default value: `{count} rate, prossima il {dueDate}`
   */
  openInstallments($: {count: string | number, dueDate: string | number}): string {
    return this._translations.get('openInstallments', {
      count: $.count,
      dueDate: $.dueDate
    });
  }

  /**
   * Returns the value of translation message for key `closedInstallments`.
   * Default value: `{count} rate`
   */
  closedInstallments(count: string | number): string {
    return this._translations.get('closedInstallments', {
      count: count
    });
  }

  /**
   * Returns the value of translation message for key `closedRecurring`.
   * Default value: `Pagamento ricorrente chiuso`
   */
  get closedRecurring(): string {
    return this._translations.get('closedRecurring');
  }

  /**
   * Returns the value of translation message for key `canceledRecurring`.
   * Default value: `Annulla pagamento ricorrente`
   */
  get canceledRecurring(): string {
    return this._translations.get('canceledRecurring');
  }

  /**
   * Returns the value of translation message for key `openRecurring`.
   * Default value: `Il prossimo pagamento ricorrente è il {date}`
   */
  openRecurring(date: string | number): string {
    return this._translations.get('openRecurring', {
      date: date
    });
  }
}

export class I18n$Transaction$Status {

  private _translations: Translations = new Translations('transaction.status');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Stato`
   */
  get name(): string {
    return this._translations.get('name');
  }

  /**
   * Returns the value of translation message for key `open`.
   * Default value: `Aperto`
   */
  get open(): string {
    return this._translations.get('open');
  }

  /**
   * Returns the value of translation message for key `closed`.
   * Default value: `Chiuso`
   */
  get closed(): string {
    return this._translations.get('closed');
  }

  /**
   * Returns the value of translation message for key `canceled`.
   * Default value: `Annullato`
   */
  get canceled(): string {
    return this._translations.get('canceled');
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Scaduto`
   */
  get expired(): string {
    return this._translations.get('expired');
  }

  /**
   * Returns the value of translation message for key `approved`.
   * Default value: `Approvato`
   */
  get approved(): string {
    return this._translations.get('approved');
  }

  /**
   * Returns the value of translation message for key `processed`.
   * Default value: `Elaborato`
   */
  get processed(): string {
    return this._translations.get('processed');
  }

  /**
   * Returns the value of translation message for key `authorized`.
   * Default value: `Autorizzato`
   */
  get authorized(): string {
    return this._translations.get('authorized');
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `In attesa di autorizzazione`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `denied`.
   * Default value: `Negato`
   */
  get denied(): string {
    return this._translations.get('denied');
  }

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Bloccato`
   */
  get blocked(): string {
    return this._translations.get('blocked');
  }

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Pianificato`
   */
  get scheduled(): string {
    return this._translations.get('scheduled');
  }

  /**
   * Returns the value of translation message for key `failed`.
   * Default value: `Operazione non riuscita`
   */
  get failed(): string {
    return this._translations.get('failed');
  }

  /**
   * Returns the value of translation message for key `settled`.
   * Default value: `Saldato`
   */
  get settled(): string {
    return this._translations.get('settled');
  }
}

export class I18n$Transaction$Title {

  private _translations: Translations = new Translations('transaction.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `pos`.
   * Default value: `Richiedi incasso`
   */
  get pos(): string {
    return this._translations.get('pos');
  }

  /**
   * Returns the value of translation message for key `paymentSystemToSystem`.
   * Default value: `Pagamento tra conti di sistema`
   */
  get paymentSystemToSystem(): string {
    return this._translations.get('paymentSystemToSystem');
  }

  /**
   * Returns the value of translation message for key `paymentSystemToUser`.
   * Default value: `Pagamento da sistema a cliente`
   */
  get paymentSystemToUser(): string {
    return this._translations.get('paymentSystemToUser');
  }

  /**
   * Returns the value of translation message for key `paymentToSystem`.
   * Default value: `Pagamento al sistema`
   */
  get paymentToSystem(): string {
    return this._translations.get('paymentToSystem');
  }

  /**
   * Returns the value of translation message for key `paymentToSelf`.
   * Default value: `Pagamento tra i propri account`
   */
  get paymentToSelf(): string {
    return this._translations.get('paymentToSelf');
  }

  /**
   * Returns the value of translation message for key `paymentToUser`.
   * Default value: `Pagamento a cliente`
   */
  get paymentToUser(): string {
    return this._translations.get('paymentToUser');
  }

  /**
   * Returns the value of translation message for key `paymentConfirmation`.
   * Default value: `Conferma pagamento`
   */
  get paymentConfirmation(): string {
    return this._translations.get('paymentConfirmation');
  }

  /**
   * Returns the value of translation message for key `pendingPayment`.
   * Default value: `Pagamento inviato per autorizzazione`
   */
  get pendingPayment(): string {
    return this._translations.get('pendingPayment');
  }

  /**
   * Returns the value of translation message for key `processedPayment`.
   * Default value: `Pagamento eseguito`
   */
  get processedPayment(): string {
    return this._translations.get('processedPayment');
  }

  /**
   * Returns the value of translation message for key `authorizations`.
   * Default value: `Autorizzazioni di pagamento`
   */
  get authorizations(): string {
    return this._translations.get('authorizations');
  }

  /**
   * Returns the value of translation message for key `authorizationHistory`.
   * Default value: `Storico autorizzazioni`
   */
  get authorizationHistory(): string {
    return this._translations.get('authorizationHistory');
  }

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Pagamenti pianificati`
   */
  get scheduled(): string {
    return this._translations.get('scheduled');
  }

  /**
   * Returns the value of translation message for key `details.payment`.
   * Default value: `Dettagli pagamento`
   */
  get detailsPayment(): string {
    return this._translations.get('details.payment');
  }

  /**
   * Returns the value of translation message for key `details.scheduled`.
   * Default value: `Dettagli di pagamento pianificati`
   */
  get detailsScheduled(): string {
    return this._translations.get('details.scheduled');
  }

  /**
   * Returns the value of translation message for key `details.recurring`.
   * Default value: `Dettagli pagamenti ricorrenti`
   */
  get detailsRecurring(): string {
    return this._translations.get('details.recurring');
  }

  /**
   * Returns the value of translation message for key `details.request`.
   * Default value: `Dettagli richiesta di pagamento`
   */
  get detailsRequest(): string {
    return this._translations.get('details.request');
  }

  /**
   * Returns the value of translation message for key `details.chargeback`.
   * Default value: `Dettagli storno`
   */
  get detailsChargeback(): string {
    return this._translations.get('details.chargeback');
  }

  /**
   * Returns the value of translation message for key `details.ticket`.
   * Default value: `Dettagli scontrino Qr`
   */
  get detailsTicket(): string {
    return this._translations.get('details.ticket');
  }

  /**
   * Returns the value of translation message for key `details.external`.
   * Default value: `Dettagli pagamento esterno`
   */
  get detailsExternal(): string {
    return this._translations.get('details.external');
  }

  /**
   * Returns the value of translation message for key `details.transfer`.
   * Default value: `Dettagli trasferimento`
   */
  get detailsTransfer(): string {
    return this._translations.get('details.transfer');
  }

  /**
   * Returns the value of translation message for key `parentTransfer`.
   * Default value: `Trasferimento che ha generato questo`
   */
  get parentTransfer(): string {
    return this._translations.get('parentTransfer');
  }

  /**
   * Returns the value of translation message for key `childTransfers`.
   * Default value: `Trasferimenti generati da questo`
   */
  get childTransfers(): string {
    return this._translations.get('childTransfers');
  }

  /**
   * Returns the value of translation message for key `transfersOverview`.
   * Default value: `Panoramica operazioni`
   */
  get transfersOverview(): string {
    return this._translations.get('transfersOverview');
  }
}

export class I18n$Transaction$MobileTitle {

  private _translations: Translations = new Translations('transaction.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `pos`.
   * Default value: `Richiedi incasso`
   */
  get pos(): string {
    return this._translations.get('pos');
  }

  /**
   * Returns the value of translation message for key `paymentSystemToSystem`.
   * Default value: `Paga sistema`
   */
  get paymentSystemToSystem(): string {
    return this._translations.get('paymentSystemToSystem');
  }

  /**
   * Returns the value of translation message for key `paymentSystemToUser`.
   * Default value: `Paga cliente`
   */
  get paymentSystemToUser(): string {
    return this._translations.get('paymentSystemToUser');
  }

  /**
   * Returns the value of translation message for key `paymentToSystem`.
   * Default value: `Paga sistema`
   */
  get paymentToSystem(): string {
    return this._translations.get('paymentToSystem');
  }

  /**
   * Returns the value of translation message for key `paymentToSelf`.
   * Default value: `Autopagamento`
   */
  get paymentToSelf(): string {
    return this._translations.get('paymentToSelf');
  }

  /**
   * Returns the value of translation message for key `paymentToUser`.
   * Default value: `Paga cliente`
   */
  get paymentToUser(): string {
    return this._translations.get('paymentToUser');
  }

  /**
   * Returns the value of translation message for key `paymentConfirmation`.
   * Default value: `Conferma`
   */
  get paymentConfirmation(): string {
    return this._translations.get('paymentConfirmation');
  }

  /**
   * Returns the value of translation message for key `pendingPayment`.
   * Default value: `Autorizzazione in sospeso`
   */
  get pendingPayment(): string {
    return this._translations.get('pendingPayment');
  }

  /**
   * Returns the value of translation message for key `processedPayment`.
   * Default value: `Pagamento effettuato`
   */
  get processedPayment(): string {
    return this._translations.get('processedPayment');
  }

  /**
   * Returns the value of translation message for key `authorizations`.
   * Default value: `Autorizzazioni`
   */
  get authorizations(): string {
    return this._translations.get('authorizations');
  }

  /**
   * Returns the value of translation message for key `authorizationHistory`.
   * Default value: `Storico`
   */
  get authorizationHistory(): string {
    return this._translations.get('authorizationHistory');
  }

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Pagamenti pianificati`
   */
  get scheduled(): string {
    return this._translations.get('scheduled');
  }

  /**
   * Returns the value of translation message for key `details.payment`.
   * Default value: `Pagamento`
   */
  get detailsPayment(): string {
    return this._translations.get('details.payment');
  }

  /**
   * Returns the value of translation message for key `details.scheduled`.
   * Default value: `Pagamento pianificato`
   */
  get detailsScheduled(): string {
    return this._translations.get('details.scheduled');
  }

  /**
   * Returns the value of translation message for key `details.recurring`.
   * Default value: `Pagamento ricorrente`
   */
  get detailsRecurring(): string {
    return this._translations.get('details.recurring');
  }

  /**
   * Returns the value of translation message for key `details.request`.
   * Default value: `Richiesta di pagamento`
   */
  get detailsRequest(): string {
    return this._translations.get('details.request');
  }

  /**
   * Returns the value of translation message for key `details.chargeback`.
   * Default value: `Storno`
   */
  get detailsChargeback(): string {
    return this._translations.get('details.chargeback');
  }

  /**
   * Returns the value of translation message for key `details.ticket`.
   * Default value: `Scontrino`
   */
  get detailsTicket(): string {
    return this._translations.get('details.ticket');
  }

  /**
   * Returns the value of translation message for key `details.external`.
   * Default value: `Pagamento esterno`
   */
  get detailsExternal(): string {
    return this._translations.get('details.external');
  }

  /**
   * Returns the value of translation message for key `details.transfer`.
   * Default value: `Trasferimento`
   */
  get detailsTransfer(): string {
    return this._translations.get('details.transfer');
  }

  /**
   * Returns the value of translation message for key `parentTransfer`.
   * Default value: `Trasferimento originale`
   */
  get parentTransfer(): string {
    return this._translations.get('parentTransfer');
  }

  /**
   * Returns the value of translation message for key `childTransfers`.
   * Default value: `Trasferimenti originali`
   */
  get childTransfers(): string {
    return this._translations.get('childTransfers');
  }

  /**
   * Returns the value of translation message for key `transfersOverview`.
   * Default value: `Panoramica operazioni`
   */
  get transfersOverview(): string {
    return this._translations.get('transfersOverview');
  }
}

export class I18n$Transaction$Error {

  private _translations: Translations = new Translations('transaction.error');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `minTime`.
   * Default value: `Si dovrebbe attendere un periodo minimo di tempo per effettuare un pagamento di questo tipo.`
   */
  get minTime(): string {
    return this._translations.get('minTime');
  }

  /**
   * Returns the value of translation message for key `balance`.
   * Default value: `Saldo insufficiente per eseguire questa operazione`
   */
  get balance(): string {
    return this._translations.get('balance');
  }

  /**
   * Returns the value of translation message for key `upperLimit`.
   * Default value: `Non puoi eseguire questo pagamento perché il limite massimo del saldo disponibile di destinazione è stato superato`
   */
  get upperLimit(): string {
    return this._translations.get('upperLimit');
  }

  /**
   * Returns the value of translation message for key `daily.amount`.
   * Default value: `Il massimo giornaliero di {amount} è stato superato`
   */
  dailyAmount(amount: string | number): string {
    return this._translations.get('daily.amount', {
      amount: amount
    });
  }

  /**
   * Returns the value of translation message for key `daily.count`.
   * Default value: `Il numero massimo di pagamenti consentiti al giorno è {count}`
   */
  dailyCount(count: string | number): string {
    return this._translations.get('daily.count', {
      count: count
    });
  }

  /**
   * Returns the value of translation message for key `weekly.amount`.
   * Default value: `La massima quantità settimanale di {amount} è stata superata`
   */
  weeklyAmount(amount: string | number): string {
    return this._translations.get('weekly.amount', {
      amount: amount
    });
  }

  /**
   * Returns the value of translation message for key `weekly.count`.
   * Default value: `Il numero massimo di pagamenti consentito per settimana è {count}`
   */
  weeklyCount(count: string | number): string {
    return this._translations.get('weekly.count', {
      count: count
    });
  }

  /**
   * Returns the value of translation message for key `monthly.amount`.
   * Default value: `La quantità massima mensile di {amount} è stata superata`
   */
  monthlyAmount(amount: string | number): string {
    return this._translations.get('monthly.amount', {
      amount: amount
    });
  }

  /**
   * Returns the value of translation message for key `monthly.count`.
   * Default value: `Il numero massimo di pagamenti consentiti al mese è {count}`
   */
  monthlyCount(count: string | number): string {
    return this._translations.get('monthly.count', {
      count: count
    });
  }
}

export class I18n$Voucher {

  private _translations: Translations = new Translations('voucher');

  private _nested = {
    error: new I18n$Voucher$Error(),
    redeem: new I18n$Voucher$Redeem(),
    status: new I18n$Voucher$Status(),
    mobileTitle: new I18n$Voucher$MobileTitle(),
    title: new I18n$Voucher$Title(),
    buy: new I18n$Voucher$Buy(),
    cancel: new I18n$Voucher$Cancel(),
    sort: new I18n$Voucher$Sort()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.error.defaultValues = defaultValues['error'];
    this._nested.redeem.defaultValues = defaultValues['redeem'];
    this._nested.status.defaultValues = defaultValues['status'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.buy.defaultValues = defaultValues['buy'];
    this._nested.cancel.defaultValues = defaultValues['cancel'];
    this._nested.sort.defaultValues = defaultValues['sort'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.error.initialize(values['error']);
    this._nested.redeem.initialize(values['redeem']);
    this._nested.status.initialize(values['status']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.title.initialize(values['title']);
    this._nested.buy.initialize(values['buy']);
    this._nested.cancel.initialize(values['cancel']);
    this._nested.sort.initialize(values['sort']);
  }

  /**
   * Returns the value of translation message for key `boughtType`.
   * Default value: `Acquistato`
   */
  get boughtType(): string {
    return this._translations.get('boughtType');
  }

  /**
   * Returns the value of translation message for key `generatedType`.
   * Default value: `Generato`
   */
  get generatedType(): string {
    return this._translations.get('generatedType');
  }

  /**
   * Returns the value of translation message for key `buyerGroups`.
   * Default value: `Gruppi acquirenti`
   */
  get buyerGroups(): string {
    return this._translations.get('buyerGroups');
  }

  /**
   * Returns the value of translation message for key `redeemerGroups`.
   * Default value: `Gruppi accettatori`
   */
  get redeemerGroups(): string {
    return this._translations.get('redeemerGroups');
  }

  /**
   * Returns the value of translation message for key `creationType`.
   * Default value: `Tipo di creazione`
   */
  get creationType(): string {
    return this._translations.get('creationType');
  }

  /**
   * Returns the value of translation message for key `cancelAndRefund`.
   * Default value: `Annulla e rimborsa`
   */
  get cancelAndRefund(): string {
    return this._translations.get('cancelAndRefund');
  }

  /**
   * Returns the value of translation message for key `cancelDone`.
   * Default value: `Questo voucher è stato annullato.`
   */
  get cancelDone(): string {
    return this._translations.get('cancelDone');
  }

  /**
   * Returns the value of translation message for key `cancelConfirmation`.
   * Default value: `Sei sicuro di voler annullare questo voucher?`
   */
  get cancelConfirmation(): string {
    return this._translations.get('cancelConfirmation');
  }

  /**
   * Returns the value of translation message for key `cancelPackConfirmation`.
   * Default value: `Ci sono altri buoni appartenenti allo stesso pacchetto che saranno cancellati. Sei sicuro di continuare?`
   */
  get cancelPackConfirmation(): string {
    return this._translations.get('cancelPackConfirmation');
  }

  /**
   * Returns the value of translation message for key `token`.
   * Default value: `Codice voucher`
   */
  get token(): string {
    return this._translations.get('token');
  }

  /**
   * Returns the value of translation message for key `owner`.
   * Default value: `Owner`
   */
  get owner(): string {
    return this._translations.get('owner');
  }

  /**
   * Returns the value of translation message for key `changeExpirationDate`.
   * Default value: `Cambia data di scadenza`
   */
  get changeExpirationDate(): string {
    return this._translations.get('changeExpirationDate');
  }

  /**
   * Returns the value of translation message for key `expirationDateChanged`.
   * Default value: `Data di scadenza modificata`
   */
  get expirationDateChanged(): string {
    return this._translations.get('expirationDateChanged');
  }

  /**
   * Returns the value of translation message for key `refundPayment`.
   * Default value: `Pagamento rimborso`
   */
  get refundPayment(): string {
    return this._translations.get('refundPayment');
  }

  /**
   * Returns the value of translation message for key `refundDate`.
   * Default value: `Data di rimborso`
   */
  get refundDate(): string {
    return this._translations.get('refundDate');
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Voucher$Error {
    return this._nested.error;
  }

  /**
   * Returns the nested accessor for translation messages in `redeem`.
   */
  get redeem(): I18n$Voucher$Redeem {
    return this._nested.redeem;
  }

  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  get status(): I18n$Voucher$Status {
    return this._nested.status;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Voucher$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Voucher$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `buy`.
   */
  get buy(): I18n$Voucher$Buy {
    return this._nested.buy;
  }

  /**
   * Returns the nested accessor for translation messages in `cancel`.
   */
  get cancel(): I18n$Voucher$Cancel {
    return this._nested.cancel;
  }

  /**
   * Returns the nested accessor for translation messages in `sort`.
   */
  get sort(): I18n$Voucher$Sort {
    return this._nested.sort;
  }
}

export class I18n$Voucher$Error {

  private _translations: Translations = new Translations('voucher.error');

  private _nested = {
    buy: new I18n$Voucher$Error$Buy(),
    redeem: new I18n$Voucher$Error$Redeem()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.buy.defaultValues = defaultValues['buy'];
    this._nested.redeem.defaultValues = defaultValues['redeem'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.buy.initialize(values['buy']);
    this._nested.redeem.initialize(values['redeem']);
  }

  /**
   * Returns the value of translation message for key `totalOpenAmount`.
   * Default value: `L'importo totale di tutti i voucher aperti supererebbe il {maxAmount}, come definito in questo tipo di voucher. L'importo totale aperto attuale è {currentAmount}.`
   */
  totalOpenAmount($: {maxAmount: string | number, currentAmount: string | number}): string {
    return this._translations.get('totalOpenAmount', {
      maxAmount: $.maxAmount,
      currentAmount: $.currentAmount
    });
  }

  /**
   * Returns the nested accessor for translation messages in `buy`.
   */
  get buy(): I18n$Voucher$Error$Buy {
    return this._nested.buy;
  }

  /**
   * Returns the nested accessor for translation messages in `redeem`.
   */
  get redeem(): I18n$Voucher$Error$Redeem {
    return this._nested.redeem;
  }
}

export class I18n$Voucher$Error$Buy {

  private _translations: Translations = new Translations('voucher.error.buy');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `amountForPeriod`.
   * Default value: `L'acquisto di questo tipo di voucher è limitato per periodo. Fino a {date} solo {amount} è disponibile per l'acquisto.`
   */
  amountForPeriod($: {date: string | number, amount: string | number}): string {
    return this._translations.get('amountForPeriod', {
      date: $.date,
      amount: $.amount
    });
  }

  /**
   * Returns the value of translation message for key `openAmount`.
   * Default value: `L'utente attuale supera il limite consentito per Voucher non riscossi di {maxAmount}. L'importo aperto attuale è {currentAmount}.`
   */
  openAmount($: {maxAmount: string | number, currentAmount: string | number}): string {
    return this._translations.get('openAmount', {
      maxAmount: $.maxAmount,
      currentAmount: $.currentAmount
    });
  }

  /**
   * Returns the value of translation message for key `notAllowedForUser`.
   * Default value: `L'utente attuale non è autorizzato ad acquistare voucher da questo tipo.`
   */
  get notAllowedForUser(): string {
    return this._translations.get('notAllowedForUser');
  }
}

export class I18n$Voucher$Error$Redeem {

  private _translations: Translations = new Translations('voucher.error.redeem');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `status`.
   * Default value: `Questo voucher non può essere riscattato. Lo stato attuale è: {status}.`
   */
  status(status: string | number): string {
    return this._translations.get('status', {
      status: status
    });
  }

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `L'utente attuale non è autorizzato ad acquistare voucher di questo tipo.`
   */
  get user(): string {
    return this._translations.get('user');
  }

  /**
   * Returns the value of translation message for key `notAllowedYet`.
   * Default value: `Questo voucher non può essere riscattato prima di {beginDate}.`
   */
  notAllowedYet(beginDate: string | number): string {
    return this._translations.get('notAllowedYet', {
      beginDate: beginDate
    });
  }

  /**
   * Returns the value of translation message for key `notAllowedToday`.
   * Default value: `Questo buono non può essere riscattato oggi. I giorni consentiti per riscattare sono: {allowedDays}.`
   */
  notAllowedToday(allowedDays: string | number): string {
    return this._translations.get('notAllowedToday', {
      allowedDays: allowedDays
    });
  }

  /**
   * Returns the value of translation message for key `userBlocked`.
   * Default value: `Sei stato bloccato per il superamento del numero di tentativi consentiti.`
   */
  get userBlocked(): string {
    return this._translations.get('userBlocked');
  }
}

export class I18n$Voucher$Redeem {

  private _translations: Translations = new Translations('voucher.redeem');

  private _nested = {
    error: new I18n$Voucher$Redeem$Error()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.error.defaultValues = defaultValues['error'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.error.initialize(values['error']);
  }

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `I dettagli del voucher sono riportati sotto. Per favore, consultali qui sotto e conferma questa azione.`
   */
  get confirm(): string {
    return this._translations.get('confirm');
  }

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `Il voucher è stato riscattato con successo.`
   */
  get done(): string {
    return this._translations.get('done');
  }

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Data di riscatto`
   */
  get date(): string {
    return this._translations.get('date');
  }

  /**
   * Returns the value of translation message for key `redeemer`.
   * Default value: `Riscattato da`
   */
  get redeemer(): string {
    return this._translations.get('redeemer');
  }

  /**
   * Returns the value of translation message for key `payment`.
   * Default value: `Pagamento per riscatto voucher`
   */
  get payment(): string {
    return this._translations.get('payment');
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Voucher$Redeem$Error {
    return this._nested.error;
  }
}

export class I18n$Voucher$Redeem$Error {

  private _translations: Translations = new Translations('voucher.redeem.error');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `status`.
   * Default value: `Questo voucher non può essere riscattato. Lo stato attuale è: {status}.`
   */
  status(status: string | number): string {
    return this._translations.get('status', {
      status: status
    });
  }

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `L'utente attuale non è autorizzato a riscattare voucher di questo tipo.`
   */
  get user(): string {
    return this._translations.get('user');
  }

  /**
   * Returns the value of translation message for key `notAllowedYet`.
   * Default value: `Questo voucher non può essere riscattato prima di {beginDate}.`
   */
  notAllowedYet(beginDate: string | number): string {
    return this._translations.get('notAllowedYet', {
      beginDate: beginDate
    });
  }

  /**
   * Returns the value of translation message for key `notAllowedToday`.
   * Default value: `Questo buono non può essere riscattato oggi. I giorni consentiti per riscattare sono: {allowedDays}.`
   */
  notAllowedToday(allowedDays: string | number): string {
    return this._translations.get('notAllowedToday', {
      allowedDays: allowedDays
    });
  }

  /**
   * Returns the value of translation message for key `userBlocked`.
   * Default value: `Sei stato bloccato per il superamento del numero di tentativi consentiti.`
   */
  get userBlocked(): string {
    return this._translations.get('userBlocked');
  }
}

export class I18n$Voucher$Status {

  private _translations: Translations = new Translations('voucher.status');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `redeemed`.
   * Default value: `Riscossi`
   */
  get redeemed(): string {
    return this._translations.get('redeemed');
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `In attesa`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `open`.
   * Default value: `Aperto`
   */
  get open(): string {
    return this._translations.get('open');
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Scaduto`
   */
  get expired(): string {
    return this._translations.get('expired');
  }

  /**
   * Returns the value of translation message for key `canceled`.
   * Default value: `Annullato`
   */
  get canceled(): string {
    return this._translations.get('canceled');
  }
}

export class I18n$Voucher$MobileTitle {

  private _translations: Translations = new Translations('voucher.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `buy`.
   * Default value: `Acquista voucher`
   */
  get buy(): string {
    return this._translations.get('buy');
  }

  /**
   * Returns the value of translation message for key `buyConfirmation`.
   * Default value: `Conferma di acquisto`
   */
  get buyConfirmation(): string {
    return this._translations.get('buyConfirmation');
  }

  /**
   * Returns the value of translation message for key `bought`.
   * Default value: `Voucher acquistati`
   */
  get bought(): string {
    return this._translations.get('bought');
  }

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Riscatta voucher`
   */
  get redeem(): string {
    return this._translations.get('redeem');
  }

  /**
   * Returns the value of translation message for key `redeemed`.
   * Default value: `Voucher riscattati`
   */
  get redeemed(): string {
    return this._translations.get('redeemed');
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Cerca voucher`
   */
  get search(): string {
    return this._translations.get('search');
  }
}

export class I18n$Voucher$Title {

  private _translations: Translations = new Translations('voucher.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `buy`.
   * Default value: `Acquista voucher`
   */
  get buy(): string {
    return this._translations.get('buy');
  }

  /**
   * Returns the value of translation message for key `buyConfirmation`.
   * Default value: `Conferma di acquisto`
   */
  get buyConfirmation(): string {
    return this._translations.get('buyConfirmation');
  }

  /**
   * Returns the value of translation message for key `searchBought`.
   * Default value: `Cerca buoni acquistati`
   */
  get searchBought(): string {
    return this._translations.get('searchBought');
  }

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Riscatta voucher`
   */
  get redeem(): string {
    return this._translations.get('redeem');
  }

  /**
   * Returns the value of translation message for key `redeemed`.
   * Default value: `Voucher riscattati`
   */
  get redeemed(): string {
    return this._translations.get('redeemed');
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Cerca voucher`
   */
  get search(): string {
    return this._translations.get('search');
  }
}

export class I18n$Voucher$Buy {

  private _translations: Translations = new Translations('voucher.buy');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `amountRange`.
   * Default value: `Gamma importo`
   */
  get amountRange(): string {
    return this._translations.get('amountRange');
  }

  /**
   * Returns the value of translation message for key `buyer`.
   * Default value: `Acquirente`
   */
  get buyer(): string {
    return this._translations.get('buyer');
  }

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `Il voucher(i) è stato acquistato.`
   */
  get done(): string {
    return this._translations.get('done');
  }

  /**
   * Returns the value of translation message for key `minTimeToRedeem`.
   * Default value: `Può essere riscattato`
   */
  get minTimeToRedeem(): string {
    return this._translations.get('minTimeToRedeem');
  }

  /**
   * Returns the value of translation message for key `minTimeToRedeem.afterBuy`.
   * Default value: `{interval} dopo l'acquisto`
   */
  minTimeToRedeemAfterBuy(interval: string | number): string {
    return this._translations.get('minTimeToRedeem.afterBuy', {
      interval: interval
    });
  }

  /**
   * Returns the value of translation message for key `numberOfVouchers`.
   * Default value: `Numero voucher`
   */
  get numberOfVouchers(): string {
    return this._translations.get('numberOfVouchers');
  }

  /**
   * Returns the value of translation message for key `amountPerVoucher`.
   * Default value: `Importo per voucher`
   */
  get amountPerVoucher(): string {
    return this._translations.get('amountPerVoucher');
  }

  /**
   * Returns the value of translation message for key `originAccount`.
   * Default value: `Conto di origine`
   */
  get originAccount(): string {
    return this._translations.get('originAccount');
  }

  /**
   * Returns the value of translation message for key `payment`.
   * Default value: `Pagamento per acquisto`
   */
  get payment(): string {
    return this._translations.get('payment');
  }
}

export class I18n$Voucher$Cancel {

  private _translations: Translations = new Translations('voucher.cancel');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `Questo voucher è stato annullato.`
   */
  get done(): string {
    return this._translations.get('done');
  }

  /**
   * Returns the value of translation message for key `packConfirmation`.
   * Default value: `Ci sono altri buoni appartenenti allo stesso pacchetto che saranno cancellati. Sei sicuro di continuare?`
   */
  get packConfirmation(): string {
    return this._translations.get('packConfirmation');
  }

  /**
   * Returns the value of translation message for key `refundConfirmation`.
   * Default value: `Sei sicuro di voler annullare e rimborsare questo voucher?`
   */
  get refundConfirmation(): string {
    return this._translations.get('refundConfirmation');
  }

  /**
   * Returns the value of translation message for key `confirmation`.
   * Default value: `Sei sicuro di voler annullare questo voucher?`
   */
  get confirmation(): string {
    return this._translations.get('confirmation');
  }
}

export class I18n$Voucher$Sort {

  private _translations: Translations = new Translations('voucher.sort');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `creationDateDesc`.
   * Default value: `Ordina per data (decrescente)`
   */
  get creationDateDesc(): string {
    return this._translations.get('creationDateDesc');
  }

  /**
   * Returns the value of translation message for key `creationDateAsc`.
   * Default value: `Ordina per data (crescente)`
   */
  get creationDateAsc(): string {
    return this._translations.get('creationDateAsc');
  }

  /**
   * Returns the value of translation message for key `expirationDateDesc`.
   * Default value: `Ordina per data (decrescente)`
   */
  get expirationDateDesc(): string {
    return this._translations.get('expirationDateDesc');
  }

  /**
   * Returns the value of translation message for key `expirationDateAsc`.
   * Default value: `Ordina per data (crescente)`
   */
  get expirationDateAsc(): string {
    return this._translations.get('expirationDateAsc');
  }

  /**
   * Returns the value of translation message for key `redeemDateDesc`.
   * Default value: `Ordina per data (decrescente)`
   */
  get redeemDateDesc(): string {
    return this._translations.get('redeemDateDesc');
  }

  /**
   * Returns the value of translation message for key `redeemDateAsc`.
   * Default value: `Ordina per data (decrescente)`
   */
  get redeemDateAsc(): string {
    return this._translations.get('redeemDateAsc');
  }
}

export class I18n$Field {

  private _translations: Translations = new Translations('field');

  private _nested = {
    privacy: new I18n$Field$Privacy(),
    image: new I18n$Field$Image(),
    file: new I18n$Field$File(),
    user: new I18n$Field$User()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.privacy.defaultValues = defaultValues['privacy'];
    this._nested.image.defaultValues = defaultValues['image'];
    this._nested.file.defaultValues = defaultValues['file'];
    this._nested.user.defaultValues = defaultValues['user'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.privacy.initialize(values['privacy']);
    this._nested.image.initialize(values['image']);
    this._nested.file.initialize(values['file']);
    this._nested.user.initialize(values['user']);
  }

  /**
   * Returns the nested accessor for translation messages in `privacy`.
   */
  get privacy(): I18n$Field$Privacy {
    return this._nested.privacy;
  }

  /**
   * Returns the nested accessor for translation messages in `image`.
   */
  get image(): I18n$Field$Image {
    return this._nested.image;
  }

  /**
   * Returns the nested accessor for translation messages in `file`.
   */
  get file(): I18n$Field$File {
    return this._nested.file;
  }

  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  get user(): I18n$Field$User {
    return this._nested.user;
  }
}

export class I18n$Field$Privacy {

  private _translations: Translations = new Translations('field.privacy');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Privacy`
   */
  get name(): string {
    return this._translations.get('name');
  }

  /**
   * Returns the value of translation message for key `private.tooltip`.
   * Default value: `Questo campo è privato. Clicca per consentire ad altri di vederlo.`
   */
  get privateTooltip(): string {
    return this._translations.get('private.tooltip');
  }

  /**
   * Returns the value of translation message for key `public.tooltip`.
   * Default value: `Questo campo è visibile da altri. Clicca per renderlo privato.`
   */
  get publicTooltip(): string {
    return this._translations.get('public.tooltip');
  }
}

export class I18n$Field$Image {

  private _translations: Translations = new Translations('field.image');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `noImage`.
   * Default value: `Nessuna immagine`
   */
  get noImage(): string {
    return this._translations.get('noImage');
  }

  /**
   * Returns the value of translation message for key `noImages`.
   * Default value: `Nessuna immagine`
   */
  get noImages(): string {
    return this._translations.get('noImages');
  }

  /**
   * Returns the value of translation message for key `upload`.
   * Default value: `Carica nuova immagine`
   */
  get upload(): string {
    return this._translations.get('upload');
  }

  /**
   * Returns the value of translation message for key `manage`.
   * Default value: `Riordina o rimuovi immagini`
   */
  get manage(): string {
    return this._translations.get('manage');
  }

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Rimuovi questa immagine`
   */
  get remove(): string {
    return this._translations.get('remove');
  }

  /**
   * Returns the value of translation message for key `manageMessage`.
   * Default value: `Puoi trascinare / rilasciare immagini per riordinarle`
   */
  get manageMessage(): string {
    return this._translations.get('manageMessage');
  }

  /**
   * Returns the value of translation message for key `manageAfterConfirm`.
   * Default value: `Dopo la conferma, assicurati di fare clic sul pulsante Salva per applicare le modifiche.`
   */
  get manageAfterConfirm(): string {
    return this._translations.get('manageAfterConfirm');
  }
}

export class I18n$Field$File {

  private _translations: Translations = new Translations('field.file');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `noFile`.
   * Default value: `Nessun file`
   */
  get noFile(): string {
    return this._translations.get('noFile');
  }

  /**
   * Returns the value of translation message for key `noFiles`.
   * Default value: `Nessun file`
   */
  get noFiles(): string {
    return this._translations.get('noFiles');
  }

  /**
   * Returns the value of translation message for key `upload`.
   * Default value: `Carica un nuovo file`
   */
  get upload(): string {
    return this._translations.get('upload');
  }

  /**
   * Returns the value of translation message for key `manage`.
   * Default value: `Riordina o rimuovi file`
   */
  get manage(): string {
    return this._translations.get('manage');
  }

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Rimuovi questo file`
   */
  get remove(): string {
    return this._translations.get('remove');
  }

  /**
   * Returns the value of translation message for key `manage.message`.
   * Default value: `Puoi trascinare / rilasciare file per riordinarli`
   */
  get manageMessage(): string {
    return this._translations.get('manage.message');
  }
}

export class I18n$Field$User {

  private _translations: Translations = new Translations('field.user');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `placeholder.allowSearch`.
   * Default value: `Digita per cercare`
   */
  get placeholderAllowSearch(): string {
    return this._translations.get('placeholder.allowSearch');
  }

  /**
   * Returns the value of translation message for key `placeholder.principal`.
   * Default value: `Digita l'Id utente`
   */
  get placeholderPrincipal(): string {
    return this._translations.get('placeholder.principal');
  }

  /**
   * Returns the value of translation message for key `contact.tooltip`.
   * Default value: `Scegli dalla tua rubrica`
   */
  get contactTooltip(): string {
    return this._translations.get('contact.tooltip');
  }

  /**
   * Returns the value of translation message for key `contact.title`.
   * Default value: `Seleziona un contatto`
   */
  get contactTitle(): string {
    return this._translations.get('contact.title');
  }

  /**
   * Returns the value of translation message for key `contact.empty`.
   * Default value: `La tua rubrica è vuota`
   */
  get contactEmpty(): string {
    return this._translations.get('contact.empty');
  }
}

export class I18n$User {

  private _translations: Translations = new Translations('user');

  private _nested = {
    linkedMember: new I18n$User$LinkedMember(),
    title: new I18n$User$Title(),
    mobileTitle: new I18n$User$MobileTitle(),
    profile: new I18n$User$Profile(),
    registration: new I18n$User$Registration(),
    orderBy: new I18n$User$OrderBy()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.linkedMember.defaultValues = defaultValues['linkedMember'];
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.profile.defaultValues = defaultValues['profile'];
    this._nested.registration.defaultValues = defaultValues['registration'];
    this._nested.orderBy.defaultValues = defaultValues['orderBy'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.linkedMember.initialize(values['linkedMember']);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.profile.initialize(values['profile']);
    this._nested.registration.initialize(values['registration']);
    this._nested.orderBy.initialize(values['orderBy']);
  }

  /**
   * Returns the value of translation message for key `group`.
   * Default value: `Gruppo`
   */
  get group(): string {
    return this._translations.get('group');
  }

  /**
   * Returns the value of translation message for key `groupSet`.
   * Default value: `Supergruppo`
   */
  get groupSet(): string {
    return this._translations.get('groupSet');
  }

  /**
   * Returns the value of translation message for key `group.filter`.
   * Default value: `Gruppi`
   */
  get groupFilter(): string {
    return this._translations.get('group.filter');
  }

  /**
   * Returns the value of translation message for key `registrationDate`.
   * Default value: `Data di registrazione`
   */
  get registrationDate(): string {
    return this._translations.get('registrationDate');
  }

  /**
   * Returns the value of translation message for key `activationDate`.
   * Default value: `Data di attivazione`
   */
  get activationDate(): string {
    return this._translations.get('activationDate');
  }

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Nome Completo`
   */
  get name(): string {
    return this._translations.get('name');
  }

  /**
   * Returns the value of translation message for key `username`.
   * Default value: `Id/Username`
   */
  get username(): string {
    return this._translations.get('username');
  }

  /**
   * Returns the value of translation message for key `email`.
   * Default value: `E-mail`
   */
  get email(): string {
    return this._translations.get('email');
  }

  /**
   * Returns the value of translation message for key `email.pending`.
   * Default value: `E-mail in attesa di convalida`
   */
  get emailPending(): string {
    return this._translations.get('email.pending');
  }

  /**
   * Returns the value of translation message for key `circuit`.
   * Default value: `Circuito`
   */
  get circuit(): string {
    return this._translations.get('circuit');
  }

  /**
   * Returns the value of translation message for key `operator.owner`.
   * Default value: `Proprietario delegato`
   */
  get operatorOwner(): string {
    return this._translations.get('operator.owner');
  }

  /**
   * Returns the value of translation message for key `operator.noGroup`.
   * Default value: `Nessun gruppo`
   */
  get operatorNoGroup(): string {
    return this._translations.get('operator.noGroup');
  }

  /**
   * Returns the value of translation message for key `operator.registration.active`.
   * Default value: `L'operatore {operator} è stato attivato.`
   */
  operatorRegistrationActive(operator: string | number): string {
    return this._translations.get('operator.registration.active', {
      operator: operator
    });
  }

  /**
   * Returns the value of translation message for key `operator.registration.inactive`.
   * Default value: `L'operatore {operator} è stato registrato, tuttavia, ha ancora bisogno di essere attivato dall'amministrazione.`
   */
  operatorRegistrationInactive(operator: string | number): string {
    return this._translations.get('operator.registration.inactive', {
      operator: operator
    });
  }

  /**
   * Returns the value of translation message for key `operator.registration.pending`.
   * Default value: `L'operatore {operator} è stato registrato, tuttavia, deve ancora convalidare la sua registrazione confermando l'e-mail.`
   */
  operatorRegistrationPending(operator: string | number): string {
    return this._translations.get('operator.registration.pending', {
      operator: operator
    });
  }

  /**
   * Returns the value of translation message for key `operator.registration.addAnother`.
   * Default value: `Registra un altro operatore`
   */
  get operatorRegistrationAddAnother(): string {
    return this._translations.get('operator.registration.addAnother');
  }

  /**
   * Returns the value of translation message for key `operator.noGroup.needNewGroup`.
   * Default value: `Non ci sono gruppi. Devi crearne uno nuovo prima di registrare un nuovo operatore`
   */
  get operatorNoGroupNeedNewGroup(): string {
    return this._translations.get('operator.noGroup.needNewGroup');
  }

  /**
   * Returns the value of translation message for key `noImage`.
   * Default value: `Nessuna immagine profilo`
   */
  get noImage(): string {
    return this._translations.get('noImage');
  }

  /**
   * Returns the value of translation message for key `noImages`.
   * Default value: `Nessuna immagine profilo`
   */
  get noImages(): string {
    return this._translations.get('noImages');
  }

  /**
   * Returns the value of translation message for key `address.define`.
   * Default value: `Definisci indirizzo`
   */
  get addressDefine(): string {
    return this._translations.get('address.define');
  }

  /**
   * Returns the value of translation message for key `address.add`.
   * Default value: `Aggiungi indirizzo`
   */
  get addressAdd(): string {
    return this._translations.get('address.add');
  }

  /**
   * Returns the value of translation message for key `address.remove`.
   * Default value: `Rimuovi questo indirizzo`
   */
  get addressRemove(): string {
    return this._translations.get('address.remove');
  }

  /**
   * Returns the value of translation message for key `address.none`.
   * Default value: `Al momento non ci sono indirizzi`
   */
  get addressNone(): string {
    return this._translations.get('address.none');
  }

  /**
   * Returns the value of translation message for key `phone.add`.
   * Default value: `Aggiungi telefono`
   */
  get phoneAdd(): string {
    return this._translations.get('phone.add');
  }

  /**
   * Returns the value of translation message for key `phone.add.mobile`.
   * Default value: `Aggiungi cellulare`
   */
  get phoneAddMobile(): string {
    return this._translations.get('phone.add.mobile');
  }

  /**
   * Returns the value of translation message for key `phone.add.landLine`.
   * Default value: `Aggiungi telefono fisso`
   */
  get phoneAddLandLine(): string {
    return this._translations.get('phone.add.landLine');
  }

  /**
   * Returns the value of translation message for key `phone.remove`.
   * Default value: `Rimuovi questo telefono`
   */
  get phoneRemove(): string {
    return this._translations.get('phone.remove');
  }

  /**
   * Returns the value of translation message for key `phone.none`.
   * Default value: `Al momento non ci sono telefoni`
   */
  get phoneNone(): string {
    return this._translations.get('phone.none');
  }

  /**
   * Returns the value of translation message for key `contactInfo`.
   * Default value: `Contact information`
   */
  get contactInfo(): string {
    return this._translations.get('contactInfo');
  }

  /**
   * Returns the value of translation message for key `contactInfo.add`.
   * Default value: `Aggiungi contatto`
   */
  get contactInfoAdd(): string {
    return this._translations.get('contactInfo.add');
  }

  /**
   * Returns the value of translation message for key `contactInfo.remove`.
   * Default value: `Rimuovi Contatto`
   */
  get contactInfoRemove(): string {
    return this._translations.get('contactInfo.remove');
  }

  /**
   * Returns the value of translation message for key `contactInfo.none`.
   * Default value: `Al momento non ci sono contatti aggiuntivi`
   */
  get contactInfoNone(): string {
    return this._translations.get('contactInfo.none');
  }

  /**
   * Returns the value of translation message for key `contactInfo.atAddress`.
   * Default value: `At`
   */
  get contactInfoAtAddress(): string {
    return this._translations.get('contactInfo.atAddress');
  }

  /**
   * Returns the value of translation message for key `confirmation.message`.
   * Default value: `Per salvare il tuo profilo, fornisci il tuo {type}`
   */
  confirmationMessage(type: string | number): string {
    return this._translations.get('confirmation.message', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `profileSaved`.
   * Default value: `Il profilo è stato salvato`
   */
  get profileSaved(): string {
    return this._translations.get('profileSaved');
  }

  /**
   * Returns the value of translation message for key `imagesChanged`.
   * Default value: `Per salvare le modifiche sulle immagini, il profilo deve essere salvato`
   */
  get imagesChanged(): string {
    return this._translations.get('imagesChanged');
  }

  /**
   * Returns the value of translation message for key `newEmailConfirmed`.
   * Default value: `Il tuo nuovo indirizzo e-mail è stato confermato con successo`
   */
  get newEmailConfirmed(): string {
    return this._translations.get('newEmailConfirmed');
  }

  /**
   * Returns the value of translation message for key `passwordConfirmation`.
   * Default value: `{type} conferma`
   */
  passwordConfirmation(type: string | number): string {
    return this._translations.get('passwordConfirmation', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `securityQuestion.message`.
   * Default value: `Se si dimentica la password, si dovrà rispondere correttamente alla domanda di sicurezza per reimpostare la password.<br>Questa può essere impostata più tardi, ma ricorda che il recupero della password non funzionerà senza domanda di sicurezza.`
   */
  get securityQuestionMessage(): string {
    return this._translations.get('securityQuestion.message');
  }

  /**
   * Returns the value of translation message for key `securityQuestion.empty`.
   * Default value: `Lascia vuoto`
   */
  get securityQuestionEmpty(): string {
    return this._translations.get('securityQuestion.empty');
  }

  /**
   * Returns the value of translation message for key `captcha`.
   * Default value: `Convalida visiva`
   */
  get captcha(): string {
    return this._translations.get('captcha');
  }

  /**
   * Returns the value of translation message for key `contact`.
   * Default value: `Contatto`
   */
  get contact(): string {
    return this._translations.get('contact');
  }

  /**
   * Returns the value of translation message for key `creationBegin`.
   * Default value: `Registrato dopo`
   */
  get creationBegin(): string {
    return this._translations.get('creationBegin');
  }

  /**
   * Returns the value of translation message for key `creationEnd`.
   * Default value: `Registrato prima`
   */
  get creationEnd(): string {
    return this._translations.get('creationEnd');
  }

  /**
   * Returns the nested accessor for translation messages in `linkedMember`.
   */
  get linkedMember(): I18n$User$LinkedMember {
    return this._nested.linkedMember;
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$User$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$User$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `profile`.
   */
  get profile(): I18n$User$Profile {
    return this._nested.profile;
  }

  /**
   * Returns the nested accessor for translation messages in `registration`.
   */
  get registration(): I18n$User$Registration {
    return this._nested.registration;
  }

  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  get orderBy(): I18n$User$OrderBy {
    return this._nested.orderBy;
  }
}

export class I18n$User$LinkedMember {

  private _translations: Translations = new Translations('user.linkedMember');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `linkedMember`.
   * Default value: `Collegato`
   */
  get linkedMember(): string {
    return this._translations.get('linkedMember');
  }

  /**
   * Returns the value of translation message for key `linkedMembers`.
   * Default value: `Collegati`
   */
  get linkedMembers(): string {
    return this._translations.get('linkedMembers');
  }
}

export class I18n$User$Title {

  private _translations: Translations = new Translations('user.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `myProfile`.
   * Default value: `Il mio profilo`
   */
  get myProfile(): string {
    return this._translations.get('myProfile');
  }

  /**
   * Returns the value of translation message for key `editProfile`.
   * Default value: `Modifica profilo`
   */
  get editProfile(): string {
    return this._translations.get('editProfile');
  }

  /**
   * Returns the value of translation message for key `image`.
   * Default value: `Immagine profilo`
   */
  get image(): string {
    return this._translations.get('image');
  }

  /**
   * Returns the value of translation message for key `images`.
   * Default value: `Immagini del profilo`
   */
  get images(): string {
    return this._translations.get('images');
  }

  /**
   * Returns the value of translation message for key `fields`.
   * Default value: `Campi profilo`
   */
  get fields(): string {
    return this._translations.get('fields');
  }

  /**
   * Returns the value of translation message for key `phones`.
   * Default value: `Telefoni`
   */
  get phones(): string {
    return this._translations.get('phones');
  }

  /**
   * Returns the value of translation message for key `addresses`.
   * Default value: `Indirizzi`
   */
  get addresses(): string {
    return this._translations.get('addresses');
  }

  /**
   * Returns the value of translation message for key `contactInfos`.
   * Default value: `Contatti aggiuntivi`
   */
  get contactInfos(): string {
    return this._translations.get('contactInfos');
  }

  /**
   * Returns the value of translation message for key `confirmation`.
   * Default value: `Conferma`
   */
  get confirmation(): string {
    return this._translations.get('confirmation');
  }

  /**
   * Returns the value of translation message for key `registration`.
   * Default value: `Registrazione utente`
   */
  get registration(): string {
    return this._translations.get('registration');
  }

  /**
   * Returns the value of translation message for key `registration.confirmation`.
   * Default value: `Conferma di registrazione`
   */
  get registrationConfirmation(): string {
    return this._translations.get('registration.confirmation');
  }

  /**
   * Returns the value of translation message for key `registration.done`.
   * Default value: `Registrazione riuscita`
   */
  get registrationDone(): string {
    return this._translations.get('registration.done');
  }

  /**
   * Returns the value of translation message for key `contactList`.
   * Default value: `Rubrica`
   */
  get contactList(): string {
    return this._translations.get('contactList');
  }

  /**
   * Returns the value of translation message for key `addContact`.
   * Default value: `Aggiungi un nuovo contatto`
   */
  get addContact(): string {
    return this._translations.get('addContact');
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Cerca utenti`
   */
  get search(): string {
    return this._translations.get('search');
  }

  /**
   * Returns the value of translation message for key `directory`.
   * Default value: `Aziende`
   */
  get directory(): string {
    return this._translations.get('directory');
  }

  /**
   * Returns the value of translation message for key `myOperators`.
   * Default value: `Delegati`
   */
  get myOperators(): string {
    return this._translations.get('myOperators');
  }

  /**
   * Returns the value of translation message for key `userOperators`.
   * Default value: `Delegati utenti`
   */
  get userOperators(): string {
    return this._translations.get('userOperators');
  }

  /**
   * Returns the value of translation message for key `operatorRegistration`.
   * Default value: `Registrazione delegato`
   */
  get operatorRegistration(): string {
    return this._translations.get('operatorRegistration');
  }

  /**
   * Returns the value of translation message for key `myBrokerings`.
   * Default value: `Membri assegnati`
   */
  get myBrokerings(): string {
    return this._translations.get('myBrokerings');
  }

  /**
   * Returns the value of translation message for key `userBrokerings`.
   * Default value: `Visualizza membri assegnati`
   */
  get userBrokerings(): string {
    return this._translations.get('userBrokerings');
  }
}

export class I18n$User$MobileTitle {

  private _translations: Translations = new Translations('user.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `myProfile`.
   * Default value: `Il mio profilo`
   */
  get myProfile(): string {
    return this._translations.get('myProfile');
  }

  /**
   * Returns the value of translation message for key `userProfile`.
   * Default value: `Profilo utente`
   */
  get userProfile(): string {
    return this._translations.get('userProfile');
  }

  /**
   * Returns the value of translation message for key `editProfile`.
   * Default value: `Modifica profilo`
   */
  get editProfile(): string {
    return this._translations.get('editProfile');
  }

  /**
   * Returns the value of translation message for key `image`.
   * Default value: `Immagine profilo`
   */
  get image(): string {
    return this._translations.get('image');
  }

  /**
   * Returns the value of translation message for key `images`.
   * Default value: `Immagini del profilo`
   */
  get images(): string {
    return this._translations.get('images');
  }

  /**
   * Returns the value of translation message for key `fields`.
   * Default value: `Campi profilo`
   */
  get fields(): string {
    return this._translations.get('fields');
  }

  /**
   * Returns the value of translation message for key `phones`.
   * Default value: `Telefoni`
   */
  get phones(): string {
    return this._translations.get('phones');
  }

  /**
   * Returns the value of translation message for key `addresses`.
   * Default value: `Indirizzi`
   */
  get addresses(): string {
    return this._translations.get('addresses');
  }

  /**
   * Returns the value of translation message for key `contactInfos`.
   * Default value: `Contatti aggiuntivi`
   */
  get contactInfos(): string {
    return this._translations.get('contactInfos');
  }

  /**
   * Returns the value of translation message for key `confirmation`.
   * Default value: `Conferma`
   */
  get confirmation(): string {
    return this._translations.get('confirmation');
  }

  /**
   * Returns the value of translation message for key `registration`.
   * Default value: `Registrazione`
   */
  get registration(): string {
    return this._translations.get('registration');
  }

  /**
   * Returns the value of translation message for key `registration.confirmation`.
   * Default value: `Conferma`
   */
  get registrationConfirmation(): string {
    return this._translations.get('registration.confirmation');
  }

  /**
   * Returns the value of translation message for key `registration.done`.
   * Default value: `Fatto`
   */
  get registrationDone(): string {
    return this._translations.get('registration.done');
  }

  /**
   * Returns the value of translation message for key `contactList`.
   * Default value: `Rubrica`
   */
  get contactList(): string {
    return this._translations.get('contactList');
  }

  /**
   * Returns the value of translation message for key `addContact`.
   * Default value: `Nuovo contatto`
   */
  get addContact(): string {
    return this._translations.get('addContact');
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Utenti`
   */
  get search(): string {
    return this._translations.get('search');
  }

  /**
   * Returns the value of translation message for key `directory`.
   * Default value: `Aziende`
   */
  get directory(): string {
    return this._translations.get('directory');
  }

  /**
   * Returns the value of translation message for key `myOperators`.
   * Default value: `Delegati`
   */
  get myOperators(): string {
    return this._translations.get('myOperators');
  }

  /**
   * Returns the value of translation message for key `userOperators`.
   * Default value: `Delegati`
   */
  get userOperators(): string {
    return this._translations.get('userOperators');
  }

  /**
   * Returns the value of translation message for key `operatorRegistration`.
   * Default value: `Nuovo delegato`
   */
  get operatorRegistration(): string {
    return this._translations.get('operatorRegistration');
  }

  /**
   * Returns the value of translation message for key `myBrokerings`.
   * Default value: `Membri assegnati`
   */
  get myBrokerings(): string {
    return this._translations.get('myBrokerings');
  }

  /**
   * Returns the value of translation message for key `userBrokerings`.
   * Default value: `Membri del Broker`
   */
  get userBrokerings(): string {
    return this._translations.get('userBrokerings');
  }
}

export class I18n$User$Profile {

  private _translations: Translations = new Translations('user.profile');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `noPermission`.
   * Default value: `Non hai i permessi per visualizzare il profilo di questo utente`
   */
  get noPermission(): string {
    return this._translations.get('noPermission');
  }

  /**
   * Returns the value of translation message for key `showActions`.
   * Default value: `Mostra azioni`
   */
  get showActions(): string {
    return this._translations.get('showActions');
  }

  /**
   * Returns the value of translation message for key `hideActions`.
   * Default value: `Nascondi azioni`
   */
  get hideActions(): string {
    return this._translations.get('hideActions');
  }

  /**
   * Returns the value of translation message for key `banking`.
   * Default value: `Conti`
   */
  get banking(): string {
    return this._translations.get('banking');
  }

  /**
   * Returns the value of translation message for key `management`.
   * Default value: `Amministrazione`
   */
  get management(): string {
    return this._translations.get('management');
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Modifica profilo`
   */
  get edit(): string {
    return this._translations.get('edit');
  }

  /**
   * Returns the value of translation message for key `managePasswords`.
   * Default value: `Gestisci password`
   */
  get managePasswords(): string {
    return this._translations.get('managePasswords');
  }

  /**
   * Returns the value of translation message for key `viewIdentityProviders`.
   * Default value: `View identity providers`
   */
  get viewIdentityProviders(): string {
    return this._translations.get('viewIdentityProviders');
  }

  /**
   * Returns the value of translation message for key `addContact`.
   * Default value: `Add to contacts`
   */
  get addContact(): string {
    return this._translations.get('addContact');
  }

  /**
   * Returns the value of translation message for key `addContact.done`.
   * Default value: `{user} è stato aggiunto alla tua rubrica`
   */
  addContactDone(user: string | number): string {
    return this._translations.get('addContact.done', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `removeContact`.
   * Default value: `Remove from contacts`
   */
  get removeContact(): string {
    return this._translations.get('removeContact');
  }

  /**
   * Returns the value of translation message for key `removeContact.done`.
   * Default value: `{user} è stato rimosso dalla tua rubrica`
   */
  removeContactDone(user: string | number): string {
    return this._translations.get('removeContact.done', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `pay`.
   * Default value: `Paga`
   */
  get pay(): string {
    return this._translations.get('pay');
  }

  /**
   * Returns the value of translation message for key `paySystemToUser`.
   * Default value: `Pagamenti da sistema a utente`
   */
  get paySystemToUser(): string {
    return this._translations.get('paySystemToUser');
  }

  /**
   * Returns the value of translation message for key `payAsUserToSystem`.
   * Default value: `Pagamenti da utente a sistema`
   */
  get payAsUserToSystem(): string {
    return this._translations.get('payAsUserToSystem');
  }

  /**
   * Returns the value of translation message for key `payAsUserToUser`.
   * Default value: `Pagamenti P2P`
   */
  get payAsUserToUser(): string {
    return this._translations.get('payAsUserToUser');
  }

  /**
   * Returns the value of translation message for key `payAsUserToSelf`.
   * Default value: `Auto-pagamento`
   */
  get payAsUserToSelf(): string {
    return this._translations.get('payAsUserToSelf');
  }

  /**
   * Returns the value of translation message for key `purchases`.
   * Default value: `Purchases`
   */
  get purchases(): string {
    return this._translations.get('purchases');
  }

  /**
   * Returns the value of translation message for key `sales`.
   * Default value: `Sales`
   */
  get sales(): string {
    return this._translations.get('sales');
  }

  /**
   * Returns the value of translation message for key `viewAccount`.
   * Default value: `Mostra {account}`
   */
  viewAccount(account: string | number): string {
    return this._translations.get('viewAccount', {
      account: account
    });
  }

  /**
   * Returns the value of translation message for key `viewScheduledPayments`.
   * Default value: `Visualizza pagamenti pianificati`
   */
  get viewScheduledPayments(): string {
    return this._translations.get('viewScheduledPayments');
  }

  /**
   * Returns the value of translation message for key `viewAuthorizedPayments`.
   * Default value: `Visualizza le autorizzazioni di pagamento`
   */
  get viewAuthorizedPayments(): string {
    return this._translations.get('viewAuthorizedPayments');
  }

  /**
   * Returns the value of translation message for key `viewAds`.
   * Default value: `Visualizza annunci`
   */
  get viewAds(): string {
    return this._translations.get('viewAds');
  }

  /**
   * Returns the value of translation message for key `viewBrokerings`.
   * Default value: `Visualizza membri assegnati`
   */
  get viewBrokerings(): string {
    return this._translations.get('viewBrokerings');
  }

  /**
   * Returns the value of translation message for key `viewBrokers`.
   * Default value: `Vedi broker`
   */
  get viewBrokers(): string {
    return this._translations.get('viewBrokers');
  }

  /**
   * Returns the value of translation message for key `viewOperators`.
   * Default value: `Visualizza operatori`
   */
  get viewOperators(): string {
    return this._translations.get('viewOperators');
  }

  /**
   * Returns the value of translation message for key `viewWebshop`.
   * Default value: `Vedi negozio online`
   */
  get viewWebshop(): string {
    return this._translations.get('viewWebshop');
  }

  /**
   * Returns the value of translation message for key `adInterests`.
   * Default value: `Ad interests`
   */
  get adInterests(): string {
    return this._translations.get('adInterests');
  }

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  get deliveryMethods(): string {
    return this._translations.get('deliveryMethods');
  }

  /**
   * Returns the value of translation message for key `webshopSettings`.
   * Default value: `Webshop settings`
   */
  get webshopSettings(): string {
    return this._translations.get('webshopSettings');
  }

  /**
   * Returns the value of translation message for key `status.user`.
   * Default value: `Stato utente`
   */
  get statusUser(): string {
    return this._translations.get('status.user');
  }

  /**
   * Returns the value of translation message for key `status.operator`.
   * Default value: `Stato operatore`
   */
  get statusOperator(): string {
    return this._translations.get('status.operator');
  }

  /**
   * Returns the value of translation message for key `group.user`.
   * Default value: `Gruppo utente`
   */
  get groupUser(): string {
    return this._translations.get('group.user');
  }

  /**
   * Returns the value of translation message for key `group.operator`.
   * Default value: `Gruppo Operatore`
   */
  get groupOperator(): string {
    return this._translations.get('group.operator');
  }

  /**
   * Returns the value of translation message for key `viewRedeemedVouchers`.
   * Default value: `Mostra voucher riscattati`
   */
  get viewRedeemedVouchers(): string {
    return this._translations.get('viewRedeemedVouchers');
  }

  /**
   * Returns the value of translation message for key `redeemVoucher`.
   * Default value: `Riscatta voucher`
   */
  get redeemVoucher(): string {
    return this._translations.get('redeemVoucher');
  }

  /**
   * Returns the value of translation message for key `viewBoughtVouchers`.
   * Default value: `Vedi voucher acquistati`
   */
  get viewBoughtVouchers(): string {
    return this._translations.get('viewBoughtVouchers');
  }

  /**
   * Returns the value of translation message for key `buyVouchers`.
   * Default value: `Acquista voucher`
   */
  get buyVouchers(): string {
    return this._translations.get('buyVouchers');
  }

  /**
   * Returns the value of translation message for key `notificationSettings`.
   * Default value: `Impostazioni di notifica`
   */
  get notificationSettings(): string {
    return this._translations.get('notificationSettings');
  }

  /**
   * Returns the value of translation message for key `manageCircuits`.
   * Default value: `Gestisci circuiti`
   */
  get manageCircuits(): string {
    return this._translations.get('manageCircuits');
  }
}

export class I18n$User$Registration {

  private _translations: Translations = new Translations('user.registration');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `group.public`.
   * Default value: `Scegliere il gruppo a cui si desidera partecipare`
   */
  get groupPublic(): string {
    return this._translations.get('group.public');
  }

  /**
   * Returns the value of translation message for key `group.manager`.
   * Default value: `Scegli il gruppo per il nuovo utente`
   */
  get groupManager(): string {
    return this._translations.get('group.manager');
  }

  /**
   * Returns the value of translation message for key `skipActivationEmail`.
   * Default value: `Saltare e-mail di attivazione`
   */
  get skipActivationEmail(): string {
    return this._translations.get('skipActivationEmail');
  }

  /**
   * Returns the value of translation message for key `password.assign`.
   * Default value: `Assegna password`
   */
  get passwordAssign(): string {
    return this._translations.get('password.assign');
  }

  /**
   * Returns the value of translation message for key `password.forceChange`.
   * Default value: `Obbliga l'utente a cambiare password`
   */
  get passwordForceChange(): string {
    return this._translations.get('password.forceChange');
  }

  /**
   * Returns the value of translation message for key `active.public`.
   * Default value: `Sei stato registrato con successo. Il tuo account è attivo.`
   */
  get activePublic(): string {
    return this._translations.get('active.public');
  }

  /**
   * Returns the value of translation message for key `inactive.public`.
   * Default value: `Il tuo account è stato creato con successo.<br>Tuttavia, dovrai essere attivato dall'amministrazione.<br>Sarai avvisato quando il tuo account è attivo.`
   */
  get inactivePublic(): string {
    return this._translations.get('inactive.public');
  }

  /**
   * Returns the value of translation message for key `pending.public`.
   * Default value: `La tua registrazione è stata inviata e deve essere verificata.<br>Dovresti ricevere una e-mail a breve con le istruzioni su come attivare il tuo account.<br>Se non riesci a trovare l'e-mail, assicurati di controllare la cartella spam.`
   */
  get pendingPublic(): string {
    return this._translations.get('pending.public');
  }

  /**
   * Returns the value of translation message for key `active.manager`.
   * Default value: `Il conto dell'utente {user} è stato attivato.`
   */
  activeManager(user: string | number): string {
    return this._translations.get('active.manager', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `inactive.manager`.
   * Default value: `L'utente {user} è stato registrato, tuttavia, ha ancora bisogno di essere attivato dall'amministrazione.`
   */
  inactiveManager(user: string | number): string {
    return this._translations.get('inactive.manager', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `pending.manager`.
   * Default value: `L'utente {user} è stato registrato, tuttavia, deve ancora convalidare la sua registrazione confermando l'e-mail.`
   */
  pendingManager(user: string | number): string {
    return this._translations.get('pending.manager', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `principal.single`.
   * Default value: `Puoi utilizzare il tuo {principal} ({value}) su {channels}`
   */
  principalSingle($: {principal: string | number, value: string | number, channels: string | number}): string {
    return this._translations.get('principal.single', {
      principal: $.principal,
      value: $.value,
      channels: $.channels
    });
  }

  /**
   * Returns the value of translation message for key `principal.multiple.preface`.
   * Default value: `Puoi accedere con i seguenti dati:`
   */
  get principalMultiplePreface(): string {
    return this._translations.get('principal.multiple.preface');
  }

  /**
   * Returns the value of translation message for key `principal.multiple.item`.
   * Default value: `<b>{principal}</b> ({value}): può essere utilizzato su {channels}`
   */
  principalMultipleItem($: {principal: string | number, value: string | number, channels: string | number}): string {
    return this._translations.get('principal.multiple.item', {
      principal: $.principal,
      value: $.value,
      channels: $.channels
    });
  }

  /**
   * Returns the value of translation message for key `generatedPasswords.none`.
   * Default value: `Ora puoi accedere con la password che hai.`
   */
  get generatedPasswordsNone(): string {
    return this._translations.get('generatedPasswords.none');
  }

  /**
   * Returns the value of translation message for key `generatedPasswords.single`.
   * Default value: `Riceverai una e-mail a breve con il tuo {type} generato.`
   */
  generatedPasswordsSingle(type: string | number): string {
    return this._translations.get('generatedPasswords.single', {
      type: type
    });
  }

  /**
   * Returns the value of translation message for key `generatedPasswords.multiple`.
   * Default value: `Riceverai una e-mail a breve con le seguenti password generate: {types}.`
   */
  generatedPasswordsMultiple(types: string | number): string {
    return this._translations.get('generatedPasswords.multiple', {
      types: types
    });
  }

  /**
   * Returns the value of translation message for key `viewProfile`.
   * Default value: `Mostra profilo`
   */
  get viewProfile(): string {
    return this._translations.get('viewProfile');
  }

  /**
   * Returns the value of translation message for key `registerAnotherUser`.
   * Default value: `Registra un altro utente`
   */
  get registerAnotherUser(): string {
    return this._translations.get('registerAnotherUser');
  }
}

export class I18n$User$OrderBy {

  private _translations: Translations = new Translations('user.orderBy');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `alphabeticallyAsc`.
   * Default value: `Nome (A-Z)`
   */
  get alphabeticallyAsc(): string {
    return this._translations.get('alphabeticallyAsc');
  }

  /**
   * Returns the value of translation message for key `alphabeticallyDesc`.
   * Default value: `Nome (Z-A)`
   */
  get alphabeticallyDesc(): string {
    return this._translations.get('alphabeticallyDesc');
  }

  /**
   * Returns the value of translation message for key `acceptedCurrencyAsc`.
   * Default value: `% crescente`
   */
  get acceptedCurrencyAsc(): string {
    return this._translations.get('acceptedCurrencyAsc');
  }

  /**
   * Returns the value of translation message for key `acceptedCurrencyDesc`.
   * Default value: `% decrescente`
   */
  get acceptedCurrencyDesc(): string {
    return this._translations.get('acceptedCurrencyDesc');
  }
}

export class I18n$OperatorGroup {

  private _translations: Translations = new Translations('operatorGroup');

  private _nested = {
    title: new I18n$OperatorGroup$Title(),
    mobileTitle: new I18n$OperatorGroup$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `accountAccess`.
   * Default value: `Visibilità conto`
   */
  get accountAccess(): string {
    return this._translations.get('accountAccess');
  }

  /**
   * Returns the value of translation message for key `accountAccess.full`.
   * Default value: `Completa`
   */
  get accountAccessFull(): string {
    return this._translations.get('accountAccess.full');
  }

  /**
   * Returns the value of translation message for key `accountAccess.incoming`.
   * Default value: `Solo pagamenti in arrivo`
   */
  get accountAccessIncoming(): string {
    return this._translations.get('accountAccess.incoming');
  }

  /**
   * Returns the value of translation message for key `accountAccess.outgoing`.
   * Default value: `Solo pagamenti in uscita`
   */
  get accountAccessOutgoing(): string {
    return this._translations.get('accountAccess.outgoing');
  }

  /**
   * Returns the value of translation message for key `accountAccess.own`.
   * Default value: `Ricevuto o pagato dall'operatore`
   */
  get accountAccessOwn(): string {
    return this._translations.get('accountAccess.own');
  }

  /**
   * Returns the value of translation message for key `accountAccess.none`.
   * Default value: `Non visibile`
   */
  get accountAccessNone(): string {
    return this._translations.get('accountAccess.none');
  }

  /**
   * Returns the value of translation message for key `performPayments`.
   * Default value: `Esegui pagamenti`
   */
  get performPayments(): string {
    return this._translations.get('performPayments');
  }

  /**
   * Returns the value of translation message for key `performPayments.requiresAuthorization.view`.
   * Default value: `, richiede autorizzazione`
   */
  get performPaymentsRequiresAuthorizationView(): string {
    return this._translations.get('performPayments.requiresAuthorization.view');
  }

  /**
   * Returns the value of translation message for key `performPayments.maxAmountPerDay.view`.
   * Default value: `, max. {max} al giorno`
   */
  performPaymentsMaxAmountPerDayView(max: string | number): string {
    return this._translations.get('performPayments.maxAmountPerDay.view', {
      max: max
    });
  }

  /**
   * Returns the value of translation message for key `performPayments.requiresAuthorization`.
   * Default value: `Richiede autorizzazione`
   */
  get performPaymentsRequiresAuthorization(): string {
    return this._translations.get('performPayments.requiresAuthorization');
  }

  /**
   * Returns the value of translation message for key `performPayments.maxAmountPerDay`.
   * Default value: `Limite giornaliero`
   */
  get performPaymentsMaxAmountPerDay(): string {
    return this._translations.get('performPayments.maxAmountPerDay');
  }

  /**
   * Returns the value of translation message for key `authorizePayments`.
   * Default value: `Autorizza pagamenti`
   */
  get authorizePayments(): string {
    return this._translations.get('authorizePayments');
  }

  /**
   * Returns the value of translation message for key `paymentNotifications`.
   * Default value: `Notifiche di pagamento`
   */
  get paymentNotifications(): string {
    return this._translations.get('paymentNotifications');
  }

  /**
   * Returns the value of translation message for key `paymentNotifications.above`.
   * Default value: `Notifica solo i pagamenti sopra`
   */
  get paymentNotificationsAbove(): string {
    return this._translations.get('paymentNotifications.above');
  }

  /**
   * Returns the value of translation message for key `paymentNotifications.below`.
   * Default value: `Notifica solo i pagamenti qui sotto`
   */
  get paymentNotificationsBelow(): string {
    return this._translations.get('paymentNotifications.below');
  }

  /**
   * Returns the value of translation message for key `chargebackPayments`.
   * Default value: `Storni`
   */
  get chargebackPayments(): string {
    return this._translations.get('chargebackPayments');
  }

  /**
   * Returns the value of translation message for key `restrictPaymentsToUsers`.
   * Default value: `Limita i pagamenti a`
   */
  get restrictPaymentsToUsers(): string {
    return this._translations.get('restrictPaymentsToUsers');
  }

  /**
   * Returns the value of translation message for key `editOwnProfile`.
   * Default value: `Modifica il proprio profilo`
   */
  get editOwnProfile(): string {
    return this._translations.get('editOwnProfile');
  }

  /**
   * Returns the value of translation message for key `notifications`.
   * Default value: `Notifiche`
   */
  get notifications(): string {
    return this._translations.get('notifications');
  }

  /**
   * Returns the value of translation message for key `viewAdvertisements`.
   * Default value: `Vedi annunci`
   */
  get viewAdvertisements(): string {
    return this._translations.get('viewAdvertisements');
  }

  /**
   * Returns the value of translation message for key `brokering`.
   * Default value: `Esegui operazioni come broker`
   */
  get brokering(): string {
    return this._translations.get('brokering');
  }

  /**
   * Returns the value of translation message for key `runOperations`.
   * Default value: `Esegui operazioni`
   */
  get runOperations(): string {
    return this._translations.get('runOperations');
  }

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `Il gruppo di operatore è stato creato`
   */
  get created(): string {
    return this._translations.get('created');
  }

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `Il gruppo di operatore è stato salvato`
   */
  get saved(): string {
    return this._translations.get('saved');
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$OperatorGroup$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$OperatorGroup$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$OperatorGroup$Title {

  private _translations: Translations = new Translations('operatorGroup.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Gruppi operatore`
   */
  get list(): string {
    return this._translations.get('list');
  }

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Gruppo Operatore`
   */
  get view(): string {
    return this._translations.get('view');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Nuovo gruppo di operatori`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Modifica gruppo di operatori`
   */
  get edit(): string {
    return this._translations.get('edit');
  }

  /**
   * Returns the value of translation message for key `permissions`.
   * Default value: `Permessi`
   */
  get permissions(): string {
    return this._translations.get('permissions');
  }

  /**
   * Returns the value of translation message for key `accountAccess`.
   * Default value: `Accesso al conto`
   */
  get accountAccess(): string {
    return this._translations.get('accountAccess');
  }

  /**
   * Returns the value of translation message for key `generalAccount`.
   * Default value: `Operazioni sul conto`
   */
  get generalAccount(): string {
    return this._translations.get('generalAccount');
  }
}

export class I18n$OperatorGroup$MobileTitle {

  private _translations: Translations = new Translations('operatorGroup.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Gruppi operatore`
   */
  get list(): string {
    return this._translations.get('list');
  }

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Gruppo Operatore`
   */
  get view(): string {
    return this._translations.get('view');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Nuovo gruppo`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Modifica gruppo`
   */
  get edit(): string {
    return this._translations.get('edit');
  }

  /**
   * Returns the value of translation message for key `permissions`.
   * Default value: `Permessi`
   */
  get permissions(): string {
    return this._translations.get('permissions');
  }

  /**
   * Returns the value of translation message for key `accountAccess`.
   * Default value: `Accesso al conto`
   */
  get accountAccess(): string {
    return this._translations.get('accountAccess');
  }

  /**
   * Returns the value of translation message for key `generalAccount`.
   * Default value: `Operazioni generali`
   */
  get generalAccount(): string {
    return this._translations.get('generalAccount');
  }
}

export class I18n$UserStatus {

  private _translations: Translations = new Translations('userStatus');

  private _nested = {
    confirm: new I18n$UserStatus$Confirm(),
    done: new I18n$UserStatus$Done(),
    title: new I18n$UserStatus$Title(),
    mobileTitle: new I18n$UserStatus$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.confirm.defaultValues = defaultValues['confirm'];
    this._nested.done.defaultValues = defaultValues['done'];
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.confirm.initialize(values['confirm']);
    this._nested.done.initialize(values['done']);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `current`.
   * Default value: `Stato attuale`
   */
  get current(): string {
    return this._translations.get('current');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Nuovo stato`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Attivo`
   */
  get active(): string {
    return this._translations.get('active');
  }

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Accesso bloccato`
   */
  get blocked(): string {
    return this._translations.get('blocked');
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabilitato`
   */
  get disabled(): string {
    return this._translations.get('disabled');
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `In attesa di attivazione`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `purged`.
   * Default value: `Rimozione totale (tutti i dati privati sono cancellati)`
   */
  get purged(): string {
    return this._translations.get('purged');
  }

  /**
   * Returns the value of translation message for key `removed`.
   * Default value: `Rimozione parziale (nessun dato privato è stato eliminato)`
   */
  get removed(): string {
    return this._translations.get('removed');
  }

  /**
   * Returns the value of translation message for key `shadow`.
   * Default value: `Azienda Shadow`
   */
  get shadow(): string {
    return this._translations.get('shadow');
  }

  /**
   * Returns the value of translation message for key `leaving`.
   * Default value: `Azienda Leaving (Azienda in Disdetta)`
   */
  get leaving(): string {
    return this._translations.get('leaving');
  }

  /**
   * Returns the value of translation message for key `recovering`.
   * Default value: `Azienda Recovering (Azienda in Recupero)`
   */
  get recovering(): string {
    return this._translations.get('recovering');
  }

  /**
   * Returns the nested accessor for translation messages in `confirm`.
   */
  get confirm(): I18n$UserStatus$Confirm {
    return this._nested.confirm;
  }

  /**
   * Returns the nested accessor for translation messages in `done`.
   */
  get done(): I18n$UserStatus$Done {
    return this._nested.done;
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$UserStatus$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$UserStatus$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$UserStatus$Confirm {

  private _translations: Translations = new Translations('userStatus.confirm');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Sei sicuro di voler attivare {user}?`
   */
  active(user: string | number): string {
    return this._translations.get('active', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Sei sicuro di bloccare l'accesso a {user}?`
   */
  blocked(user: string | number): string {
    return this._translations.get('blocked', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Sei sicuro di disattivare {user}?`
   */
  disabled(user: string | number): string {
    return this._translations.get('disabled', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `purged`.
   * Default value: `Sei sicuro di voler rimuovere definitivamente {user} e rimuovere tutti i dati privati?\nQuesta azione è irreversibile!`
   */
  purged(user: string | number): string {
    return this._translations.get('purged', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `removed`.
   * Default value: `Sei sicuro di voler rimuovere definitivamente {user}?\nQuesta azione è irreversibile!`
   */
  removed(user: string | number): string {
    return this._translations.get('removed', {
      user: user
    });
  }
}

export class I18n$UserStatus$Done {

  private _translations: Translations = new Translations('userStatus.done');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `{user} è stato attivato`
   */
  active(user: string | number): string {
    return this._translations.get('active', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `{user} è stato bloccato`
   */
  blocked(user: string | number): string {
    return this._translations.get('blocked', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `{user} è stato disabilitato`
   */
  disabled(user: string | number): string {
    return this._translations.get('disabled', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `purged`.
   * Default value: `{user} è stato rimosso permanentemente, insieme a tutti i dati personali`
   */
  purged(user: string | number): string {
    return this._translations.get('purged', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `removed`.
   * Default value: `{user} è stato rimosso permanentemente`
   */
  removed(user: string | number): string {
    return this._translations.get('removed', {
      user: user
    });
  }
}

export class I18n$UserStatus$Title {

  private _translations: Translations = new Translations('userStatus.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `status.user`.
   * Default value: `Stato utente`
   */
  get statusUser(): string {
    return this._translations.get('status.user');
  }

  /**
   * Returns the value of translation message for key `status.operator`.
   * Default value: `Stato operatore`
   */
  get statusOperator(): string {
    return this._translations.get('status.operator');
  }

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Cambia stato utente`
   */
  get changeUser(): string {
    return this._translations.get('change.user');
  }

  /**
   * Returns the value of translation message for key `change.operator`.
   * Default value: `Cambia lo stato dell'operatore`
   */
  get changeOperator(): string {
    return this._translations.get('change.operator');
  }

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `Cronologia stato utente`
   */
  get historyUser(): string {
    return this._translations.get('history.user');
  }

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Cronologia stato operatore`
   */
  get historyOperator(): string {
    return this._translations.get('history.operator');
  }
}

export class I18n$UserStatus$MobileTitle {

  private _translations: Translations = new Translations('userStatus.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `status.user`.
   * Default value: `Stato utente`
   */
  get statusUser(): string {
    return this._translations.get('status.user');
  }

  /**
   * Returns the value of translation message for key `status.operator`.
   * Default value: `Stato operatore`
   */
  get statusOperator(): string {
    return this._translations.get('status.operator');
  }

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Cambia stato`
   */
  get changeUser(): string {
    return this._translations.get('change.user');
  }

  /**
   * Returns the value of translation message for key `change.operator`.
   * Default value: `Cambia stato`
   */
  get changeOperator(): string {
    return this._translations.get('change.operator');
  }

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `Cronologia stato`
   */
  get historyUser(): string {
    return this._translations.get('history.user');
  }

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Cronologia stato`
   */
  get historyOperator(): string {
    return this._translations.get('history.operator');
  }
}

export class I18n$GroupMembership {

  private _translations: Translations = new Translations('groupMembership');

  private _nested = {
    title: new I18n$GroupMembership$Title(),
    mobileTitle: new I18n$GroupMembership$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `current`.
   * Default value: `Gruppo attuale`
   */
  get current(): string {
    return this._translations.get('current');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Nuovo gruppo`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `Sei sicuro di voler spostare {user} nel gruppo {group}?`
   */
  confirm($: {user: string | number, group: string | number}): string {
    return this._translations.get('confirm', {
      user: $.user,
      group: $.group
    });
  }

  /**
   * Returns the value of translation message for key `confirm.aliasOperator`.
   * Default value: `Sei sicuro di concedere a {user} le stesse autorizzazioni del proprietario?`
   */
  confirmAliasOperator(user: string | number): string {
    return this._translations.get('confirm.aliasOperator', {
      user: user
    });
  }

  /**
   * Returns the value of translation message for key `done.user`.
   * Default value: `Il gruppo dell'utente è stato modificato`
   */
  get doneUser(): string {
    return this._translations.get('done.user');
  }

  /**
   * Returns the value of translation message for key `done.operator`.
   * Default value: `Il gruppo dell'operatore è stato modificato`
   */
  get doneOperator(): string {
    return this._translations.get('done.operator');
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$GroupMembership$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$GroupMembership$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$GroupMembership$Title {

  private _translations: Translations = new Translations('groupMembership.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `group.user`.
   * Default value: `Gruppo utente`
   */
  get groupUser(): string {
    return this._translations.get('group.user');
  }

  /**
   * Returns the value of translation message for key `group.operator`.
   * Default value: `Gruppo Operatore`
   */
  get groupOperator(): string {
    return this._translations.get('group.operator');
  }

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Cambia gruppo utente`
   */
  get changeUser(): string {
    return this._translations.get('change.user');
  }

  /**
   * Returns the value of translation message for key `change.operator`.
   * Default value: `Cambia gruppo`
   */
  get changeOperator(): string {
    return this._translations.get('change.operator');
  }

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `Cronologia gruppi utente`
   */
  get historyUser(): string {
    return this._translations.get('history.user');
  }

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Cronologia gruppi operatore`
   */
  get historyOperator(): string {
    return this._translations.get('history.operator');
  }
}

export class I18n$GroupMembership$MobileTitle {

  private _translations: Translations = new Translations('groupMembership.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `group.user`.
   * Default value: `Gruppo utente`
   */
  get groupUser(): string {
    return this._translations.get('group.user');
  }

  /**
   * Returns the value of translation message for key `group.operator`.
   * Default value: `Gruppo Operatore`
   */
  get groupOperator(): string {
    return this._translations.get('group.operator');
  }

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Cambia gruppo`
   */
  get changeUser(): string {
    return this._translations.get('change.user');
  }

  /**
   * Returns the value of translation message for key `change.operator`.
   * Default value: `Cambia gruppo`
   */
  get changeOperator(): string {
    return this._translations.get('change.operator');
  }

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `Storico gruppo`
   */
  get historyUser(): string {
    return this._translations.get('history.user');
  }

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Storico gruppo`
   */
  get historyOperator(): string {
    return this._translations.get('history.operator');
  }
}

export class I18n$Brokers {

  private _translations: Translations = new Translations('brokers');

  private _nested = {
    title: new I18n$Brokers$Title(),
    mobileTitle: new I18n$Brokers$MobileTitle(),
    action: new I18n$Brokers$Action()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.action.defaultValues = defaultValues['action'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.action.initialize(values['action']);
  }

  /**
   * Returns the value of translation message for key `mainBroker`.
   * Default value: `Broker principale`
   */
  get mainBroker(): string {
    return this._translations.get('mainBroker');
  }

  /**
   * Returns the value of translation message for key `setMain`.
   * Default value: `Imposta principale`
   */
  get setMain(): string {
    return this._translations.get('setMain');
  }

  /**
   * Returns the value of translation message for key `broker`.
   * Default value: `Broker`
   */
  get broker(): string {
    return this._translations.get('broker');
  }

  /**
   * Returns the value of translation message for key `brokerAdded`.
   * Default value: `Il broker è stato aggiunto con successo`
   */
  get brokerAdded(): string {
    return this._translations.get('brokerAdded');
  }

  /**
   * Returns the value of translation message for key `administration`.
   * Default value: `Amministrazione`
   */
  get administration(): string {
    return this._translations.get('administration');
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Brokers$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Brokers$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  get action(): I18n$Brokers$Action {
    return this._nested.action;
  }
}

export class I18n$Brokers$Title {

  private _translations: Translations = new Translations('brokers.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Brokers`
   */
  get list(): string {
    return this._translations.get('list');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Aggiungi broker`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Cronologia Brokers`
   */
  get history(): string {
    return this._translations.get('history');
  }
}

export class I18n$Brokers$MobileTitle {

  private _translations: Translations = new Translations('brokers.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Brokers`
   */
  get list(): string {
    return this._translations.get('list');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Aggiungi broker`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Cronologia Brokers`
   */
  get history(): string {
    return this._translations.get('history');
  }
}

export class I18n$Brokers$Action {

  private _translations: Translations = new Translations('brokers.action');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `add`.
   * Default value: `Aggiunto come broker`
   */
  get add(): string {
    return this._translations.get('add');
  }

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Rimosso un broker`
   */
  get remove(): string {
    return this._translations.get('remove');
  }

  /**
   * Returns the value of translation message for key `setMain`.
   * Default value: `Imposta come principale`
   */
  get setMain(): string {
    return this._translations.get('setMain');
  }
}

export class I18n$Circuits {

  private _translations: Translations = new Translations('circuits');

  private _nested = {
    title: new I18n$Circuits$Title(),
    mobileTitle: new I18n$Circuits$MobileTitle(),
    action: new I18n$Circuits$Action()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.action.defaultValues = defaultValues['action'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.action.initialize(values['action']);
  }

  /**
   * Returns the value of translation message for key `circuit`.
   * Default value: `Circuito`
   */
  get circuit(): string {
    return this._translations.get('circuit');
  }

  /**
   * Returns the value of translation message for key `circuitAdded`.
   * Default value: `Il circuito è stato aggiunto con successo`
   */
  get circuitAdded(): string {
    return this._translations.get('circuitAdded');
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Circuits$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Circuits$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  get action(): I18n$Circuits$Action {
    return this._nested.action;
  }
}

export class I18n$Circuits$Title {

  private _translations: Translations = new Translations('circuits.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Circuiti`
   */
  get list(): string {
    return this._translations.get('list');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Aggiungi Circuito`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Cronologia Circuiti`
   */
  get history(): string {
    return this._translations.get('history');
  }
}

export class I18n$Circuits$MobileTitle {

  private _translations: Translations = new Translations('circuits.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Circuiti`
   */
  get list(): string {
    return this._translations.get('list');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Aggiungi Circuito`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Cronologia Circuiti`
   */
  get history(): string {
    return this._translations.get('history');
  }
}

export class I18n$Circuits$Action {

  private _translations: Translations = new Translations('circuits.action');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `add`.
   * Default value: `Assegnato un nuovo circuito`
   */
  get add(): string {
    return this._translations.get('add');
  }

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Rimosso un circuito`
   */
  get remove(): string {
    return this._translations.get('remove');
  }
}

export class I18n$Phone {

  private _translations: Translations = new Translations('phone');

  private _nested = {
    error: new I18n$Phone$Error(),
    verify: new I18n$Phone$Verify()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.error.defaultValues = defaultValues['error'];
    this._nested.verify.defaultValues = defaultValues['verify'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.error.initialize(values['error']);
    this._nested.verify.initialize(values['verify']);
  }

  /**
   * Returns the value of translation message for key `mobile`.
   * Default value: `Cellulare`
   */
  get mobile(): string {
    return this._translations.get('mobile');
  }

  /**
   * Returns the value of translation message for key `landLine`.
   * Default value: `Telefono fisso`
   */
  get landLine(): string {
    return this._translations.get('landLine');
  }

  /**
   * Returns the value of translation message for key `name.mobile`.
   * Default value: `Tag cellulare`
   */
  get nameMobile(): string {
    return this._translations.get('name.mobile');
  }

  /**
   * Returns the value of translation message for key `name.landLine`.
   * Default value: `Tag telefono fisso`
   */
  get nameLandLine(): string {
    return this._translations.get('name.landLine');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Numero`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `number.mobile`.
   * Default value: `Numero di cellulare`
   */
  get numberMobile(): string {
    return this._translations.get('number.mobile');
  }

  /**
   * Returns the value of translation message for key `number.landLine`.
   * Default value: `Numero telefono fisso`
   */
  get numberLandLine(): string {
    return this._translations.get('number.landLine');
  }

  /**
   * Returns the value of translation message for key `phoneNumber`.
   * Default value: `Telefono`
   */
  get phoneNumber(): string {
    return this._translations.get('phoneNumber');
  }

  /**
   * Returns the value of translation message for key `extension`.
   * Default value: `Estensione`
   */
  get extension(): string {
    return this._translations.get('extension');
  }

  /**
   * Returns the value of translation message for key `extensionValue`.
   * Default value: `est. {value}`
   */
  extensionValue(value: string | number): string {
    return this._translations.get('extensionValue', {
      value: value
    });
  }

  /**
   * Returns the value of translation message for key `numberExtensionValue`.
   * Default value: `{number} est. {extension}`
   */
  numberExtensionValue($: {number: string | number, extension: string | number}): string {
    return this._translations.get('numberExtensionValue', {
      number: $.number,
      extension: $.extension
    });
  }

  /**
   * Returns the value of translation message for key `enabledSms`.
   * Default value: `SMS abilitati`
   */
  get enabledSms(): string {
    return this._translations.get('enabledSms');
  }

  /**
   * Returns the value of translation message for key `verified`.
   * Default value: `Verificato`
   */
  get verified(): string {
    return this._translations.get('verified');
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Phone$Error {
    return this._nested.error;
  }

  /**
   * Returns the nested accessor for translation messages in `verify`.
   */
  get verify(): I18n$Phone$Verify {
    return this._nested.verify;
  }
}

export class I18n$Phone$Error {

  private _translations: Translations = new Translations('phone.error');

  private _nested = {
    verify: new I18n$Phone$Error$Verify()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.verify.defaultValues = defaultValues['verify'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.verify.initialize(values['verify']);
  }

  /**
   * Returns the nested accessor for translation messages in `verify`.
   */
  get verify(): I18n$Phone$Error$Verify {
    return this._nested.verify;
  }
}

export class I18n$Phone$Error$Verify {

  private _translations: Translations = new Translations('phone.error.verify');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Il codice di verifica non è stato inviato o è scaduto.<br> Per favore, invia nuovamente il codice al tuo telefono e riavvia il processo.`
   */
  get expired(): string {
    return this._translations.get('expired');
  }

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `Codice di verifica non valido`
   */
  get invalid(): string {
    return this._translations.get('invalid');
  }

  /**
   * Returns the value of translation message for key `maxAttempts`.
   * Default value: `Hai superato il numero di tentativi consentiti.<br>Per favore, invia nuovamente il codice al tuo telefono e riavvia il processo.`
   */
  get maxAttempts(): string {
    return this._translations.get('maxAttempts');
  }
}

export class I18n$Phone$Verify {

  private _translations: Translations = new Translations('phone.verify');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Verifica {number} per SMS`
   */
  title(number: string | number): string {
    return this._translations.get('title', {
      number: number
    });
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Verifica`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `code`.
   * Default value: `Codice`
   */
  get code(): string {
    return this._translations.get('code');
  }

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Invia`
   */
  get send(): string {
    return this._translations.get('send');
  }

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Clicca sul pulsante qui sopra per inviare il codice di verifica al tuo telefono`
   */
  get message(): string {
    return this._translations.get('message');
  }

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `Il codice di verifica è stato inviato a {number}`
   */
  done(number: string | number): string {
    return this._translations.get('done', {
      number: number
    });
  }
}

export class I18n$Address {

  private _translations: Translations = new Translations('address');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `address`.
   * Default value: `Indirizzo`
   */
  get address(): string {
    return this._translations.get('address');
  }

  /**
   * Returns the value of translation message for key `line1`.
   * Default value: `Riga indirizzo 1`
   */
  get line1(): string {
    return this._translations.get('line1');
  }

  /**
   * Returns the value of translation message for key `line2`.
   * Default value: `Riga indirizzo 2`
   */
  get line2(): string {
    return this._translations.get('line2');
  }

  /**
   * Returns the value of translation message for key `buildingNumber`.
   * Default value: `Edificio`
   */
  get buildingNumber(): string {
    return this._translations.get('buildingNumber');
  }

  /**
   * Returns the value of translation message for key `complement`.
   * Default value: `Complemento`
   */
  get complement(): string {
    return this._translations.get('complement');
  }

  /**
   * Returns the value of translation message for key `country`.
   * Default value: `Nazione`
   */
  get country(): string {
    return this._translations.get('country');
  }

  /**
   * Returns the value of translation message for key `neighborhood`.
   * Default value: `Quartiere`
   */
  get neighborhood(): string {
    return this._translations.get('neighborhood');
  }

  /**
   * Returns the value of translation message for key `poBox`.
   * Default value: `Casella postale`
   */
  get poBox(): string {
    return this._translations.get('poBox');
  }

  /**
   * Returns the value of translation message for key `region`.
   * Default value: `Provincia`
   */
  get region(): string {
    return this._translations.get('region');
  }

  /**
   * Returns the value of translation message for key `street`.
   * Default value: `Indirizzo`
   */
  get street(): string {
    return this._translations.get('street');
  }

  /**
   * Returns the value of translation message for key `zip`.
   * Default value: `CAP`
   */
  get zip(): string {
    return this._translations.get('zip');
  }

  /**
   * Returns the value of translation message for key `subcategory`.
   * Default value: `Sottocategoria`
   */
  get subcategory(): string {
    return this._translations.get('subcategory');
  }

  /**
   * Returns the value of translation message for key `town`.
   * Default value: `Comune`
   */
  get town(): string {
    return this._translations.get('town');
  }

  /**
   * Returns the value of translation message for key `mobilePhone`.
   * Default value: `Cellulare`
   */
  get mobilePhone(): string {
    return this._translations.get('mobilePhone');
  }

  /**
   * Returns the value of translation message for key `headquarter`.
   * Default value: `Sede Legale`
   */
  get headquarter(): string {
    return this._translations.get('headquarter');
  }
}

export class I18n$Ad {

  private _translations: Translations = new Translations('ad');

  private _nested = {
    adjustedQuantity: new I18n$Ad$AdjustedQuantity(),
    error: new I18n$Ad$Error(),
    type: new I18n$Ad$Type(),
    orderBy: new I18n$Ad$OrderBy(),
    status: new I18n$Ad$Status(),
    orderStatus: new I18n$Ad$OrderStatus(),
    title: new I18n$Ad$Title(),
    mobileTitle: new I18n$Ad$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.adjustedQuantity.defaultValues = defaultValues['adjustedQuantity'];
    this._nested.error.defaultValues = defaultValues['error'];
    this._nested.type.defaultValues = defaultValues['type'];
    this._nested.orderBy.defaultValues = defaultValues['orderBy'];
    this._nested.status.defaultValues = defaultValues['status'];
    this._nested.orderStatus.defaultValues = defaultValues['orderStatus'];
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.adjustedQuantity.initialize(values['adjustedQuantity']);
    this._nested.error.initialize(values['error']);
    this._nested.type.initialize(values['type']);
    this._nested.orderBy.initialize(values['orderBy']);
    this._nested.status.initialize(values['status']);
    this._nested.orderStatus.initialize(values['orderStatus']);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `accept`.
   * Default value: `Accept`
   */
  get accept(): string {
    return this._translations.get('accept');
  }

  /**
   * Returns the value of translation message for key `acceptOrder`.
   * Default value: `Accept order`
   */
  get acceptOrder(): string {
    return this._translations.get('acceptOrder');
  }

  /**
   * Returns the value of translation message for key `addedProduct`.
   * Default value: `The product was added to cart`
   */
  get addedProduct(): string {
    return this._translations.get('addedProduct');
  }

  /**
   * Returns the value of translation message for key `addedProductAlreadyInCart`.
   * Default value: `The product was added to cart (this item has been added previously to your cart)`
   */
  get addedProductAlreadyInCart(): string {
    return this._translations.get('addedProductAlreadyInCart');
  }

  /**
   * Returns the value of translation message for key `addToCart`.
   * Default value: `Add to cart`
   */
  get addToCart(): string {
    return this._translations.get('addToCart');
  }

  /**
   * Returns the value of translation message for key `adInterestCreated`.
   * Default value: `The ad interest was created`
   */
  get adInterestCreated(): string {
    return this._translations.get('adInterestCreated');
  }

  /**
   * Returns the value of translation message for key `adInterestSaved`.
   * Default value: `The ad interest was saved`
   */
  get adInterestSaved(): string {
    return this._translations.get('adInterestSaved');
  }

  /**
   * Returns the value of translation message for key `adSaved`.
   * Default value: `L'annuncio è stato salvato`
   */
  get adSaved(): string {
    return this._translations.get('adSaved');
  }

  /**
   * Returns the value of translation message for key `adHidden`.
   * Default value: `L'annuncio è stato nascosto`
   */
  get adHidden(): string {
    return this._translations.get('adHidden');
  }

  /**
   * Returns the value of translation message for key `adUnhidden`.
   * Default value: `L'annuncio è ora visibile`
   */
  get adUnhidden(): string {
    return this._translations.get('adUnhidden');
  }

  /**
   * Returns the value of translation message for key `allowDecimalQuantity`.
   * Default value: `Allow decimal quantity`
   */
  get allowDecimalQuantity(): string {
    return this._translations.get('allowDecimalQuantity');
  }

  /**
   * Returns the value of translation message for key `answer`.
   * Default value: `Answer`
   */
  get answer(): string {
    return this._translations.get('answer');
  }

  /**
   * Returns the value of translation message for key `articlesNotice`.
   * Default value: `Please notice, some articles in your shopping cart:<br> {notice}`
   */
  articlesNotice(notice: string | number): string {
    return this._translations.get('articlesNotice', {
      notice: notice
    });
  }

  /**
   * Returns the value of translation message for key `authorize`.
   * Default value: `Autorizza`
   */
  get authorize(): string {
    return this._translations.get('authorize');
  }

  /**
   * Returns the value of translation message for key `authorized`.
   * Default value: `L'annuncio è stato autorizzato`
   */
  get authorized(): string {
    return this._translations.get('authorized');
  }

  /**
   * Returns the value of translation message for key `askQuestion`.
   * Default value: `Fai una domanda`
   */
  get askQuestion(): string {
    return this._translations.get('askQuestion');
  }

  /**
   * Returns the value of translation message for key `available`.
   * Default value: `Available`
   */
  get available(): string {
    return this._translations.get('available');
  }

  /**
   * Returns the value of translation message for key `notAvailable`.
   * Default value: `Not available`
   */
  get notAvailable(): string {
    return this._translations.get('notAvailable');
  }

  /**
   * Returns the value of translation message for key `backToDraft`.
   * Default value: `L'annuncio è ora in modalità bozza`
   */
  get backToDraft(): string {
    return this._translations.get('backToDraft');
  }

  /**
   * Returns the value of translation message for key `beginDate`.
   * Default value: `Published begin date`
   */
  get beginDate(): string {
    return this._translations.get('beginDate');
  }

  /**
   * Returns the value of translation message for key `cartEmpty`.
   * Default value: `This cart is empty. Search marketplace for products and services to buy`
   */
  get cartEmpty(): string {
    return this._translations.get('cartEmpty');
  }

  /**
   * Returns the value of translation message for key `checkout`.
   * Default value: `Checkout`
   */
  get checkout(): string {
    return this._translations.get('checkout');
  }

  /**
   * Returns the value of translation message for key `changeQuantity`.
   * Default value: `Change quantity`
   */
  get changeQuantity(): string {
    return this._translations.get('changeQuantity');
  }

  /**
   * Returns the value of translation message for key `chargeType`.
   * Default value: `Charge type`
   */
  get chargeType(): string {
    return this._translations.get('chargeType');
  }

  /**
   * Returns the value of translation message for key `chooseSeller`.
   * Default value: `Choose a seller from the list below to continue with the shopping cart process`
   */
  get chooseSeller(): string {
    return this._translations.get('chooseSeller');
  }

  /**
   * Returns the value of translation message for key `chooseDeliveryMethod`.
   * Default value: `Choose delivery method`
   */
  get chooseDeliveryMethod(): string {
    return this._translations.get('chooseDeliveryMethod');
  }

  /**
   * Returns the value of translation message for key `chooseDeliveryAddress`.
   * Default value: `Choose delivery address`
   */
  get chooseDeliveryAddress(): string {
    return this._translations.get('chooseDeliveryAddress');
  }

  /**
   * Returns the value of translation message for key `choosePaymentType`.
   * Default value: `Choose payment type`
   */
  get choosePaymentType(): string {
    return this._translations.get('choosePaymentType');
  }

  /**
   * Returns the value of translation message for key `customAddress`.
   * Default value: `Custom address`
   */
  get customAddress(): string {
    return this._translations.get('customAddress');
  }

  /**
   * Returns the value of translation message for key `endDate`.
   * Default value: `Published end date`
   */
  get endDate(): string {
    return this._translations.get('endDate');
  }

  /**
   * Returns the value of translation message for key `deliveryAddress`.
   * Default value: `Delivery address`
   */
  get deliveryAddress(): string {
    return this._translations.get('deliveryAddress');
  }

  /**
   * Returns the value of translation message for key `deliveryInformation`.
   * Default value: `Delivery information`
   */
  get deliveryInformation(): string {
    return this._translations.get('deliveryInformation');
  }

  /**
   * Returns the value of translation message for key `deliveryPrice`.
   * Default value: `Delivery price`
   */
  get deliveryPrice(): string {
    return this._translations.get('deliveryPrice');
  }

  /**
   * Returns the value of translation message for key `deliveryMethod`.
   * Default value: `Delivery method`
   */
  get deliveryMethod(): string {
    return this._translations.get('deliveryMethod');
  }

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Modalità di consegna`
   */
  get deliveryMethods(): string {
    return this._translations.get('deliveryMethods');
  }

  /**
   * Returns the value of translation message for key `deliveryPriceToBeConfirmed`.
   * Default value: `The delivery price is not yet known and the total amount is not the final one. After the seller has set the delivery price, you still can reject or confirm the order.`
   */
  get deliveryPriceToBeConfirmed(): string {
    return this._translations.get('deliveryPriceToBeConfirmed');
  }

  /**
   * Returns the value of translation message for key `deliveryMethodCreated`.
   * Default value: `The delivery method was created`
   */
  get deliveryMethodCreated(): string {
    return this._translations.get('deliveryMethodCreated');
  }

  /**
   * Returns the value of translation message for key `deliveryMethodSaved`.
   * Default value: `The delivery method was saved`
   */
  get deliveryMethodSaved(): string {
    return this._translations.get('deliveryMethodSaved');
  }

  /**
   * Returns the value of translation message for key `fixed`.
   * Default value: `Fixed`
   */
  get fixed(): string {
    return this._translations.get('fixed');
  }

  /**
   * Returns the value of translation message for key `hasImages`.
   * Default value: `With images`
   */
  get hasImages(): string {
    return this._translations.get('hasImages');
  }

  /**
   * Returns the value of translation message for key `item`.
   * Default value: `Item`
   */
  get item(): string {
    return this._translations.get('item');
  }

  /**
   * Returns the value of translation message for key `items`.
   * Default value: `Items`
   */
  get items(): string {
    return this._translations.get('items');
  }

  /**
   * Returns the value of translation message for key `itemNotAvailable`.
   * Default value: `Not available`
   */
  get itemNotAvailable(): string {
    return this._translations.get('itemNotAvailable');
  }

  /**
   * Returns the value of translation message for key `itemsNoLongerAvailable`.
   * Default value: `- are no longer available.<br>`
   */
  get itemsNoLongerAvailable(): string {
    return this._translations.get('itemsNoLongerAvailable');
  }

  /**
   * Returns the value of translation message for key `itemsOutOfStock`.
   * Default value: `- are out of stock.<br>`
   */
  get itemsOutOfStock(): string {
    return this._translations.get('itemsOutOfStock');
  }

  /**
   * Returns the value of translation message for key `lowBalance`.
   * Default value: `- are exceeding your {currency} balance.`
   */
  lowBalance(currency: string | number): string {
    return this._translations.get('lowBalance', {
      currency: currency
    });
  }

  /**
   * Returns the value of translation message for key `minPrice`.
   * Default value: `Min. price`
   */
  get minPrice(): string {
    return this._translations.get('minPrice');
  }

  /**
   * Returns the value of translation message for key `maxPrice`.
   * Default value: `Max. price`
   */
  get maxPrice(): string {
    return this._translations.get('maxPrice');
  }

  /**
   * Returns the value of translation message for key `minDeliveryTime`.
   * Default value: `Min. delivery time`
   */
  get minDeliveryTime(): string {
    return this._translations.get('minDeliveryTime');
  }

  /**
   * Returns the value of translation message for key `maxDeliveryTime`.
   * Default value: `Max. delivery time`
   */
  get maxDeliveryTime(): string {
    return this._translations.get('maxDeliveryTime');
  }

  /**
   * Returns the value of translation message for key `matchFields`.
   * Default value: `Match the following ad fields`
   */
  get matchFields(): string {
    return this._translations.get('matchFields');
  }

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Titolo`
   */
  get name(): string {
    return this._translations.get('name');
  }

  /**
   * Returns the value of translation message for key `negotiated`.
   * Default value: `Negotiated`
   */
  get negotiated(): string {
    return this._translations.get('negotiated');
  }

  /**
   * Returns the value of translation message for key `noRemarksGiven`.
   * Default value: `No remarks given`
   */
  get noRemarksGiven(): string {
    return this._translations.get('noRemarksGiven');
  }

  /**
   * Returns the value of translation message for key `noDeliveryMethodsAvailable`.
   * Default value: `No delivery methods for the selected currency`
   */
  get noDeliveryMethodsAvailable(): string {
    return this._translations.get('noDeliveryMethodsAvailable');
  }

  /**
   * Returns the value of translation message for key `owner`.
   * Default value: `Pubblicato da`
   */
  get owner(): string {
    return this._translations.get('owner');
  }

  /**
   * Returns the value of translation message for key `price`.
   * Default value: `Prezzo`
   */
  get price(): string {
    return this._translations.get('price');
  }

  /**
   * Returns the value of translation message for key `questionAsked`.
   * Default value: `La domanda è stata presentata al venditore`
   */
  get questionAsked(): string {
    return this._translations.get('questionAsked');
  }

  /**
   * Returns the value of translation message for key `questionRemoved`.
   * Default value: `La domanda è stata rimossa`
   */
  get questionRemoved(): string {
    return this._translations.get('questionRemoved');
  }

  /**
   * Returns the value of translation message for key `category`.
   * Default value: `Categoria`
   */
  get category(): string {
    return this._translations.get('category');
  }

  /**
   * Returns the value of translation message for key `categories`.
   * Default value: `Categorie`
   */
  get categories(): string {
    return this._translations.get('categories');
  }

  /**
   * Returns the value of translation message for key `creationDate`.
   * Default value: `Data di creazione`
   */
  get creationDate(): string {
    return this._translations.get('creationDate');
  }

  /**
   * Returns the value of translation message for key `byOwner`.
   * Default value: `Da {owner}`
   */
  byOwner(owner: string | number): string {
    return this._translations.get('byOwner', {
      owner: owner
    });
  }

  /**
   * Returns the value of translation message for key `showAllCategories`.
   * Default value: `Mostra tutto`
   */
  get showAllCategories(): string {
    return this._translations.get('showAllCategories');
  }

  /**
   * Returns the value of translation message for key `rootCategory`.
   * Default value: `Principale`
   */
  get rootCategory(): string {
    return this._translations.get('rootCategory');
  }

  /**
   * Returns the value of translation message for key `imagesChanged`.
   * Default value: `Per salvare le modifiche alle immagini, l'annuncio deve essere salvato`
   */
  get imagesChanged(): string {
    return this._translations.get('imagesChanged');
  }

  /**
   * Returns the value of translation message for key `orderAccepted`.
   * Default value: `The order was processed`
   */
  get orderAccepted(): string {
    return this._translations.get('orderAccepted');
  }

  /**
   * Returns the value of translation message for key `orderRejected`.
   * Default value: `The order was rejected`
   */
  get orderRejected(): string {
    return this._translations.get('orderRejected');
  }

  /**
   * Returns the value of translation message for key `publicationPeriod`.
   * Default value: `Periodo di pubblicazione`
   */
  get publicationPeriod(): string {
    return this._translations.get('publicationPeriod');
  }

  /**
   * Returns the value of translation message for key `productNumberMask`.
   * Default value: `Mask`
   */
  get productNumberMask(): string {
    return this._translations.get('productNumberMask');
  }

  /**
   * Returns the value of translation message for key `promotionalPeriod`.
   * Default value: `Periodo promozionale`
   */
  get promotionalPeriod(): string {
    return this._translations.get('promotionalPeriod');
  }

  /**
   * Returns the value of translation message for key `promotionalPrice`.
   * Default value: `Prezzo promozionale`
   */
  get promotionalPrice(): string {
    return this._translations.get('promotionalPrice');
  }

  /**
   * Returns the value of translation message for key `products`.
   * Default value: `Products`
   */
  get products(): string {
    return this._translations.get('products');
  }

  /**
   * Returns the value of translation message for key `product`.
   * Default value: `Product`
   */
  get product(): string {
    return this._translations.get('product');
  }

  /**
   * Returns the value of translation message for key `generated`.
   * Default value: `Generated`
   */
  get generated(): string {
    return this._translations.get('generated');
  }

  /**
   * Returns the value of translation message for key `manual`.
   * Default value: `Manual`
   */
  get manual(): string {
    return this._translations.get('manual');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Number`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `orderNumber`.
   * Default value: `Order number`
   */
  get orderNumber(): string {
    return this._translations.get('orderNumber');
  }

  /**
   * Returns the value of translation message for key `prefix`.
   * Default value: `Prefix`
   */
  get prefix(): string {
    return this._translations.get('prefix');
  }

  /**
   * Returns the value of translation message for key `length`.
   * Default value: `Length`
   */
  get length(): string {
    return this._translations.get('length');
  }

  /**
   * Returns the value of translation message for key `suffix`.
   * Default value: `Suffix`
   */
  get suffix(): string {
    return this._translations.get('suffix');
  }

  /**
   * Returns the value of translation message for key `quantity`.
   * Default value: `Quantity`
   */
  get quantity(): string {
    return this._translations.get('quantity');
  }

  /**
   * Returns the value of translation message for key `question`.
   * Default value: `Question`
   */
  get question(): string {
    return this._translations.get('question');
  }

  /**
   * Returns the value of translation message for key `questionAnswered`.
   * Default value: `The answer was submitted`
   */
  get questionAnswered(): string {
    return this._translations.get('questionAnswered');
  }

  /**
   * Returns the value of translation message for key `reject`.
   * Default value: `Rifiuta`
   */
  get reject(): string {
    return this._translations.get('reject');
  }

  /**
   * Returns the value of translation message for key `rejectOrder`.
   * Default value: `Reject order`
   */
  get rejectOrder(): string {
    return this._translations.get('rejectOrder');
  }

  /**
   * Returns the value of translation message for key `rejected`.
   * Default value: `La pubblicità è stata rifiutata`
   */
  get rejected(): string {
    return this._translations.get('rejected');
  }

  /**
   * Returns the value of translation message for key `showAddressOnMap`.
   * Default value: `Mostra l'indirizzo sulla mappa`
   */
  get showAddressOnMap(): string {
    return this._translations.get('showAddressOnMap');
  }

  /**
   * Returns the value of translation message for key `saveAndInsertNew`.
   * Default value: `Salva e inserisci nuovo`
   */
  get saveAndInsertNew(): string {
    return this._translations.get('saveAndInsertNew');
  }

  /**
   * Returns the value of translation message for key `setAsDraft`.
   * Default value: `Imposta come bozza da modificare`
   */
  get setAsDraft(): string {
    return this._translations.get('setAsDraft');
  }

  /**
   * Returns the value of translation message for key `setPromotionalPeriod`.
   * Default value: `Set promotional period`
   */
  get setPromotionalPeriod(): string {
    return this._translations.get('setPromotionalPeriod');
  }

  /**
   * Returns the value of translation message for key `submitForAuthorization`.
   * Default value: `Invia per l'autorizzazione`
   */
  get submitForAuthorization(): string {
    return this._translations.get('submitForAuthorization');
  }

  /**
   * Returns the value of translation message for key `subtotal`.
   * Default value: `Subtotal`
   */
  get subtotal(): string {
    return this._translations.get('subtotal');
  }

  /**
   * Returns the value of translation message for key `stockType`.
   * Default value: `Stock type`
   */
  get stockType(): string {
    return this._translations.get('stockType');
  }

  /**
   * Returns the value of translation message for key `pendingForAuth`.
   * Default value: `L'annuncio è stato sottoposto a una ulteriore autorizzazione`
   */
  get pendingForAuth(): string {
    return this._translations.get('pendingForAuth');
  }

  /**
   * Returns the value of translation message for key `hide`.
   * Default value: `Nascondi`
   */
  get hide(): string {
    return this._translations.get('hide');
  }

  /**
   * Returns the value of translation message for key `unhide`.
   * Default value: `Mostra`
   */
  get unhide(): string {
    return this._translations.get('unhide');
  }

  /**
   * Returns the value of translation message for key `setDeliveryMethod`.
   * Default value: `Set delivery method`
   */
  get setDeliveryMethod(): string {
    return this._translations.get('setDeliveryMethod');
  }

  /**
   * Returns the value of translation message for key `lowStockNotification`.
   * Default value: `Low stock notification`
   */
  get lowStockNotification(): string {
    return this._translations.get('lowStockNotification');
  }

  /**
   * Returns the value of translation message for key `stockQuantity`.
   * Default value: `Quantità in magazzino`
   */
  get stockQuantity(): string {
    return this._translations.get('stockQuantity');
  }

  /**
   * Returns the value of translation message for key `unlimitedStock`.
   * Default value: `Unlimited stock`
   */
  get unlimitedStock(): string {
    return this._translations.get('unlimitedStock');
  }

  /**
   * Returns the value of translation message for key `unitPrice`.
   * Default value: `Unit price`
   */
  get unitPrice(): string {
    return this._translations.get('unitPrice');
  }

  /**
   * Returns the value of translation message for key `minAllowedInCart`.
   * Default value: `Minimo consentito nel carrello`
   */
  get minAllowedInCart(): string {
    return this._translations.get('minAllowedInCart');
  }

  /**
   * Returns the value of translation message for key `maxAllowedInCart`.
   * Default value: `Massimo consentito nel carrello`
   */
  get maxAllowedInCart(): string {
    return this._translations.get('maxAllowedInCart');
  }

  /**
   * Returns the value of translation message for key `productNumber`.
   * Default value: `Numero prodotto`
   */
  get productNumber(): string {
    return this._translations.get('productNumber');
  }

  /**
   * Returns the value of translation message for key `outOfStock`.
   * Default value: `Non disponibile`
   */
  get outOfStock(): string {
    return this._translations.get('outOfStock');
  }

  /**
   * Returns the value of translation message for key `authorizationNotes`.
   * Default value: `Autorizzazioni`
   */
  get authorizationNotes(): string {
    return this._translations.get('authorizationNotes');
  }

  /**
   * Returns the value of translation message for key `questionsAndAnswers`.
   * Default value: `Domande & risposte`
   */
  get questionsAndAnswers(): string {
    return this._translations.get('questionsAndAnswers');
  }

  /**
   * Returns the value of translation message for key `removeQuestion`.
   * Default value: `Rimuovi domanda`
   */
  get removeQuestion(): string {
    return this._translations.get('removeQuestion');
  }

  /**
   * Returns the value of translation message for key `reserveAmount`.
   * Default value: `This amount will be reserved when the order is confirmed, and will be debited from your account once the order is accepted by the seller.`
   */
  get reserveAmount(): string {
    return this._translations.get('reserveAmount');
  }

  /**
   * Returns the value of translation message for key `order`.
   * Default value: `Order`
   */
  get order(): string {
    return this._translations.get('order');
  }

  /**
   * Returns the value of translation message for key `orderWaitingForSellersApproval`.
   * Default value: `The order has been created and is waiting for sellers approval`
   */
  get orderWaitingForSellersApproval(): string {
    return this._translations.get('orderWaitingForSellersApproval');
  }

  /**
   * Returns the value of translation message for key `total`.
   * Default value: `Total`
   */
  get total(): string {
    return this._translations.get('total');
  }

  /**
   * Returns the value of translation message for key `toBeConfirmedBySeller`.
   * Default value: `To be confirmed by seller`
   */
  get toBeConfirmedBySeller(): string {
    return this._translations.get('toBeConfirmedBySeller');
  }

  /**
   * Returns the value of translation message for key `webshopSettingsSaved`.
   * Default value: `The webshop settings were saved`
   */
  get webshopSettingsSaved(): string {
    return this._translations.get('webshopSettingsSaved');
  }

  /**
   * Returns the value of translation message for key `requiringMyConfirmation`.
   * Default value: `Requiring my confirmation`
   */
  get requiringMyConfirmation(): string {
    return this._translations.get('requiringMyConfirmation');
  }

  /**
   * Returns the value of translation message for key `buyer`.
   * Default value: `Buyer`
   */
  get buyer(): string {
    return this._translations.get('buyer');
  }

  /**
   * Returns the value of translation message for key `seller`.
   * Default value: `Seller`
   */
  get seller(): string {
    return this._translations.get('seller');
  }

  /**
   * Returns the value of translation message for key `waitingForBuyersApproval`.
   * Default value: `Waiting for buyers approval`
   */
  get waitingForBuyersApproval(): string {
    return this._translations.get('waitingForBuyersApproval');
  }

  /**
   * Returns the value of translation message for key `waitingForSellersApproval`.
   * Default value: `Waiting for sellers approval`
   */
  get waitingForSellersApproval(): string {
    return this._translations.get('waitingForSellersApproval');
  }

  /**
   * Returns the value of translation message for key `remarks`.
   * Default value: `Remarks`
   */
  get remarks(): string {
    return this._translations.get('remarks');
  }

  /**
   * Returns the nested accessor for translation messages in `adjustedQuantity`.
   */
  get adjustedQuantity(): I18n$Ad$AdjustedQuantity {
    return this._nested.adjustedQuantity;
  }

  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  get error(): I18n$Ad$Error {
    return this._nested.error;
  }

  /**
   * Returns the nested accessor for translation messages in `type`.
   */
  get type(): I18n$Ad$Type {
    return this._nested.type;
  }

  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  get orderBy(): I18n$Ad$OrderBy {
    return this._nested.orderBy;
  }

  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  get status(): I18n$Ad$Status {
    return this._nested.status;
  }

  /**
   * Returns the nested accessor for translation messages in `orderStatus`.
   */
  get orderStatus(): I18n$Ad$OrderStatus {
    return this._nested.orderStatus;
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Ad$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Ad$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$Ad$AdjustedQuantity {

  private _translations: Translations = new Translations('ad.adjustedQuantity');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `max`.
   * Default value: `- the quantity demanded cannot be delivered, because there is a maximum of items you are allowed to have in your shopping cart. The quantity of this article is automatically reduced.<br>`
   */
  get max(): string {
    return this._translations.get('max');
  }

  /**
   * Returns the value of translation message for key `min`.
   * Default value: `- the quantity demanded cannot be delivered, because there is a minimum of items you are allowed to have in your shopping cart. The quantity of this article is automatically increased.<br>`
   */
  get min(): string {
    return this._translations.get('min');
  }

  /**
   * Returns the value of translation message for key `stock`.
   * Default value: `- the quantity demanded cannot be delivered, because the article is almost out of stock. The quantity of this article is automatically reduced.<br>`
   */
  get stock(): string {
    return this._translations.get('stock');
  }
}

export class I18n$Ad$Error {

  private _translations: Translations = new Translations('ad.error');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `cannotBuyFromSeller`.
   * Default value: `At the moment, you are not allowed to buy products from this user. Please contact your administrator.`
   */
  get cannotBuyFromSeller(): string {
    return this._translations.get('cannotBuyFromSeller');
  }

  /**
   * Returns the value of translation message for key `cannotProceedToCheckout`.
   * Default value: `You can not proceed with checkout because some items are unavailable or you do not have enough balance`
   */
  get cannotProceedToCheckout(): string {
    return this._translations.get('cannotProceedToCheckout');
  }

  /**
   * Returns the value of translation message for key `notEnoughStock`.
   * Default value: `The quantity demanded cannot be delivered, because the article is almost out of stock.`
   */
  get notEnoughStock(): string {
    return this._translations.get('notEnoughStock');
  }
}

export class I18n$Ad$Type {

  private _translations: Translations = new Translations('ad.type');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `simple`.
   * Default value: `Advertisement`
   */
  get simple(): string {
    return this._translations.get('simple');
  }

  /**
   * Returns the value of translation message for key `webshop`.
   * Default value: `Webshop`
   */
  get webshop(): string {
    return this._translations.get('webshop');
  }
}

export class I18n$Ad$OrderBy {

  private _translations: Translations = new Translations('ad.orderBy');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `I più recenti`
   */
  get date(): string {
    return this._translations.get('date');
  }

  /**
   * Returns the value of translation message for key `distance`.
   * Default value: `Distanza`
   */
  get distance(): string {
    return this._translations.get('distance');
  }

  /**
   * Returns the value of translation message for key `priceAsc`.
   * Default value: `Prezzo più basso`
   */
  get priceAsc(): string {
    return this._translations.get('priceAsc');
  }

  /**
   * Returns the value of translation message for key `priceDesc`.
   * Default value: `Prezzo più alto`
   */
  get priceDesc(): string {
    return this._translations.get('priceDesc');
  }

  /**
   * Returns the value of translation message for key `relevance`.
   * Default value: `Relevance`
   */
  get relevance(): string {
    return this._translations.get('relevance');
  }
}

export class I18n$Ad$Status {

  private _translations: Translations = new Translations('ad.status');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Published`
   */
  get active(): string {
    return this._translations.get('active');
  }

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabilitato`
   */
  get disabled(): string {
    return this._translations.get('disabled');
  }

  /**
   * Returns the value of translation message for key `draft`.
   * Default value: `Draft`
   */
  get draft(): string {
    return this._translations.get('draft');
  }

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Expired`
   */
  get expired(): string {
    return this._translations.get('expired');
  }

  /**
   * Returns the value of translation message for key `hidden`.
   * Default value: `Hidden`
   */
  get hidden(): string {
    return this._translations.get('hidden');
  }

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `In attesa`
   */
  get pending(): string {
    return this._translations.get('pending');
  }

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Scheduled`
   */
  get scheduled(): string {
    return this._translations.get('scheduled');
  }
}

export class I18n$Ad$OrderStatus {

  private _translations: Translations = new Translations('ad.orderStatus');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `draft`.
   * Default value: `Draft`
   */
  get draft(): string {
    return this._translations.get('draft');
  }

  /**
   * Returns the value of translation message for key `completed`.
   * Default value: `Completed`
   */
  get completed(): string {
    return this._translations.get('completed');
  }

  /**
   * Returns the value of translation message for key `rejectedByBuyer`.
   * Default value: `Rejected by buyer`
   */
  get rejectedByBuyer(): string {
    return this._translations.get('rejectedByBuyer');
  }

  /**
   * Returns the value of translation message for key `rejectedBySeller`.
   * Default value: `Rejected by seller`
   */
  get rejectedBySeller(): string {
    return this._translations.get('rejectedBySeller');
  }

  /**
   * Returns the value of translation message for key `paymentPending`.
   * Default value: `Payment pending`
   */
  get paymentPending(): string {
    return this._translations.get('paymentPending');
  }

  /**
   * Returns the value of translation message for key `paymentDenied`.
   * Default value: `Payment denied`
   */
  get paymentDenied(): string {
    return this._translations.get('paymentDenied');
  }

  /**
   * Returns the value of translation message for key `paymentCanceled`.
   * Default value: `Payment canceled`
   */
  get paymentCanceled(): string {
    return this._translations.get('paymentCanceled');
  }

  /**
   * Returns the value of translation message for key `paymentExpired`.
   * Default value: `Payment expired`
   */
  get paymentExpired(): string {
    return this._translations.get('paymentExpired');
  }

  /**
   * Returns the value of translation message for key `pendingBuyer`.
   * Default value: `Pending by buyer`
   */
  get pendingBuyer(): string {
    return this._translations.get('pendingBuyer');
  }

  /**
   * Returns the value of translation message for key `pendingSeller`.
   * Default value: `Pending by seller`
   */
  get pendingSeller(): string {
    return this._translations.get('pendingSeller');
  }

  /**
   * Returns the value of translation message for key `disposed`.
   * Default value: `Disposed`
   */
  get disposed(): string {
    return this._translations.get('disposed');
  }
}

export class I18n$Ad$Title {

  private _translations: Translations = new Translations('ad.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Ricerca annunci`
   */
  get search(): string {
    return this._translations.get('search');
  }

  /**
   * Returns the value of translation message for key `owner`.
   * Default value: `Annunci di {name}`
   */
  owner(name: string | number): string {
    return this._translations.get('owner', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `webshopOwner`.
   * Default value: `Webshop of {name}`
   */
  webshopOwner(name: string | number): string {
    return this._translations.get('webshopOwner', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New advertisement`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit advertisement`
   */
  get edit(): string {
    return this._translations.get('edit');
  }

  /**
   * Returns the value of translation message for key `purchases`.
   * Default value: `Purchases`
   */
  get purchases(): string {
    return this._translations.get('purchases');
  }

  /**
   * Returns the value of translation message for key `sales`.
   * Default value: `Sales`
   */
  get sales(): string {
    return this._translations.get('sales');
  }

  /**
   * Returns the value of translation message for key `order`.
   * Default value: `Order details`
   */
  get order(): string {
    return this._translations.get('order');
  }

  /**
   * Returns the value of translation message for key `shoppingCart`.
   * Default value: `Shopping cart`
   */
  get shoppingCart(): string {
    return this._translations.get('shoppingCart');
  }

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Order history`
   */
  get history(): string {
    return this._translations.get('history');
  }

  /**
   * Returns the value of translation message for key `deliveryAddress`.
   * Default value: `Delivery address`
   */
  get deliveryAddress(): string {
    return this._translations.get('deliveryAddress');
  }

  /**
   * Returns the value of translation message for key `deliveryMethod`.
   * Default value: `Delivery method`
   */
  get deliveryMethod(): string {
    return this._translations.get('deliveryMethod');
  }

  /**
   * Returns the value of translation message for key `deliveryMethodNew`.
   * Default value: `New delivery method`
   */
  get deliveryMethodNew(): string {
    return this._translations.get('deliveryMethodNew');
  }

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  get deliveryMethods(): string {
    return this._translations.get('deliveryMethods');
  }

  /**
   * Returns the value of translation message for key `paymentType`.
   * Default value: `Payment type`
   */
  get paymentType(): string {
    return this._translations.get('paymentType');
  }

  /**
   * Returns the value of translation message for key `confirmOrder`.
   * Default value: `Confirm order`
   */
  get confirmOrder(): string {
    return this._translations.get('confirmOrder');
  }

  /**
   * Returns the value of translation message for key `webshopSettings`.
   * Default value: `Webshop settings`
   */
  get webshopSettings(): string {
    return this._translations.get('webshopSettings');
  }

  /**
   * Returns the value of translation message for key `unansweredQuestions`.
   * Default value: `Unanswered questions`
   */
  get unansweredQuestions(): string {
    return this._translations.get('unansweredQuestions');
  }

  /**
   * Returns the value of translation message for key `answerQuestion`.
   * Default value: `Answer question`
   */
  get answerQuestion(): string {
    return this._translations.get('answerQuestion');
  }

  /**
   * Returns the value of translation message for key `adInterests`.
   * Default value: `Advertisement interests`
   */
  get adInterests(): string {
    return this._translations.get('adInterests');
  }

  /**
   * Returns the value of translation message for key `adInterestNew`.
   * Default value: `New advertisement interest`
   */
  get adInterestNew(): string {
    return this._translations.get('adInterestNew');
  }

  /**
   * Returns the value of translation message for key `adInterest`.
   * Default value: `Advertisement interest`
   */
  get adInterest(): string {
    return this._translations.get('adInterest');
  }
}

export class I18n$Ad$MobileTitle {

  private _translations: Translations = new Translations('ad.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Annunci`
   */
  get search(): string {
    return this._translations.get('search');
  }

  /**
   * Returns the value of translation message for key `owner`.
   * Default value: `Annunci`
   */
  get owner(): string {
    return this._translations.get('owner');
  }

  /**
   * Returns the value of translation message for key `webshopOwner`.
   * Default value: `Webshop`
   */
  get webshopOwner(): string {
    return this._translations.get('webshopOwner');
  }

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `Annuncio`
   */
  get details(): string {
    return this._translations.get('details');
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New advertisement`
   */
  get new(): string {
    return this._translations.get('new');
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit advertisement`
   */
  get edit(): string {
    return this._translations.get('edit');
  }

  /**
   * Returns the value of translation message for key `purchases`.
   * Default value: `Purchases`
   */
  get purchases(): string {
    return this._translations.get('purchases');
  }

  /**
   * Returns the value of translation message for key `sales`.
   * Default value: `Sales`
   */
  get sales(): string {
    return this._translations.get('sales');
  }

  /**
   * Returns the value of translation message for key `order`.
   * Default value: `Order details`
   */
  get order(): string {
    return this._translations.get('order');
  }

  /**
   * Returns the value of translation message for key `shoppingCart`.
   * Default value: `Shopping cart`
   */
  get shoppingCart(): string {
    return this._translations.get('shoppingCart');
  }

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Order history`
   */
  get history(): string {
    return this._translations.get('history');
  }

  /**
   * Returns the value of translation message for key `deliveryAddress`.
   * Default value: `Delivery address`
   */
  get deliveryAddress(): string {
    return this._translations.get('deliveryAddress');
  }

  /**
   * Returns the value of translation message for key `deliveryMethod`.
   * Default value: `Delivery method`
   */
  get deliveryMethod(): string {
    return this._translations.get('deliveryMethod');
  }

  /**
   * Returns the value of translation message for key `deliveryMethodNew`.
   * Default value: `New delivery method`
   */
  get deliveryMethodNew(): string {
    return this._translations.get('deliveryMethodNew');
  }

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  get deliveryMethods(): string {
    return this._translations.get('deliveryMethods');
  }

  /**
   * Returns the value of translation message for key `paymentType`.
   * Default value: `Payment type`
   */
  get paymentType(): string {
    return this._translations.get('paymentType');
  }

  /**
   * Returns the value of translation message for key `confirmOrder`.
   * Default value: `Confirm order`
   */
  get confirmOrder(): string {
    return this._translations.get('confirmOrder');
  }

  /**
   * Returns the value of translation message for key `webshopSettings`.
   * Default value: `Webshop settings`
   */
  get webshopSettings(): string {
    return this._translations.get('webshopSettings');
  }

  /**
   * Returns the value of translation message for key `unansweredQuestions`.
   * Default value: `Unanswered questions`
   */
  get unansweredQuestions(): string {
    return this._translations.get('unansweredQuestions');
  }

  /**
   * Returns the value of translation message for key `answerQuestion`.
   * Default value: `Answer question`
   */
  get answerQuestion(): string {
    return this._translations.get('answerQuestion');
  }

  /**
   * Returns the value of translation message for key `adInterests`.
   * Default value: `Advertisement interests`
   */
  get adInterests(): string {
    return this._translations.get('adInterests');
  }

  /**
   * Returns the value of translation message for key `adInterestNew`.
   * Default value: `New advertisement interest`
   */
  get adInterestNew(): string {
    return this._translations.get('adInterestNew');
  }

  /**
   * Returns the value of translation message for key `adInterest`.
   * Default value: `Advertisement interest`
   */
  get adInterest(): string {
    return this._translations.get('adInterest');
  }
}

export class I18n$Contact {

  private _translations: Translations = new Translations('contact');

  private _nested = {
    technicalAssistance: new I18n$Contact$TechnicalAssistance(),
    commercialAssistance: new I18n$Contact$CommercialAssistance(),
    administrativeAssistance: new I18n$Contact$AdministrativeAssistance()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.technicalAssistance.defaultValues = defaultValues['technicalAssistance'];
    this._nested.commercialAssistance.defaultValues = defaultValues['commercialAssistance'];
    this._nested.administrativeAssistance.defaultValues = defaultValues['administrativeAssistance'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.technicalAssistance.initialize(values['technicalAssistance']);
    this._nested.commercialAssistance.initialize(values['commercialAssistance']);
    this._nested.administrativeAssistance.initialize(values['administrativeAssistance']);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Contatti`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Contatti`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the nested accessor for translation messages in `technicalAssistance`.
   */
  get technicalAssistance(): I18n$Contact$TechnicalAssistance {
    return this._nested.technicalAssistance;
  }

  /**
   * Returns the nested accessor for translation messages in `commercialAssistance`.
   */
  get commercialAssistance(): I18n$Contact$CommercialAssistance {
    return this._nested.commercialAssistance;
  }

  /**
   * Returns the nested accessor for translation messages in `administrativeAssistance`.
   */
  get administrativeAssistance(): I18n$Contact$AdministrativeAssistance {
    return this._nested.administrativeAssistance;
  }
}

export class I18n$Contact$TechnicalAssistance {

  private _translations: Translations = new Translations('contact.technicalAssistance');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `intro`.
   * Default value: `Per assistenza tecnica`
   */
  get intro(): string {
    return this._translations.get('intro');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `0734 340303`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `timeTables`.
   * Default value: `(attivo dal lunedì al venerdì dalle 09:30 alle 17:30)`
   */
  get timeTables(): string {
    return this._translations.get('timeTables');
  }

  /**
   * Returns the value of translation message for key `mail`.
   * Default value: `supporto@mexnet.it`
   */
  get mail(): string {
    return this._translations.get('mail');
  }
}

export class I18n$Contact$CommercialAssistance {

  private _translations: Translations = new Translations('contact.commercialAssistance');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `intro`.
   * Default value: `Per assistenza commerciale`
   */
  get intro(): string {
    return this._translations.get('intro');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `0733 235147`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `mail`.
   * Default value: `broker@circuitomarchex.net`
   */
  get mail(): string {
    return this._translations.get('mail');
  }

  /**
   * Returns the value of translation message for key `whatsApp`.
   * Default value: `392 9828235`
   */
  get whatsApp(): string {
    return this._translations.get('whatsApp');
  }
}

export class I18n$Contact$AdministrativeAssistance {

  private _translations: Translations = new Translations('contact.administrativeAssistance');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `intro`.
   * Default value: `Per assistenza amministrativa`
   */
  get intro(): string {
    return this._translations.get('intro');
  }

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `0733 235147`
   */
  get number(): string {
    return this._translations.get('number');
  }

  /**
   * Returns the value of translation message for key `mail`.
   * Default value: `amministrazione@circuitomarchex.net`
   */
  get mail(): string {
    return this._translations.get('mail');
  }
}

export class I18n$Notification {

  private _translations: Translations = new Translations('notification');

  private _nested = {
    actions: new I18n$Notification$Actions(),
    admin: new I18n$Notification$Admin(),
    user: new I18n$Notification$User()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.actions.defaultValues = defaultValues['actions'];
    this._nested.admin.defaultValues = defaultValues['admin'];
    this._nested.user.defaultValues = defaultValues['user'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.actions.initialize(values['actions']);
    this._nested.admin.initialize(values['admin']);
    this._nested.user.initialize(values['user']);
  }

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Messaggio`
   */
  get message(): string {
    return this._translations.get('message');
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Notifiche`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Notifiche`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `onlyUnread`.
   * Default value: `Solo non letti`
   */
  get onlyUnread(): string {
    return this._translations.get('onlyUnread');
  }

  /**
   * Returns the nested accessor for translation messages in `actions`.
   */
  get actions(): I18n$Notification$Actions {
    return this._nested.actions;
  }

  /**
   * Returns the nested accessor for translation messages in `admin`.
   */
  get admin(): I18n$Notification$Admin {
    return this._nested.admin;
  }

  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  get user(): I18n$Notification$User {
    return this._nested.user;
  }
}

export class I18n$Notification$Actions {

  private _translations: Translations = new Translations('notification.actions');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Rimuovi notifica`
   */
  get remove(): string {
    return this._translations.get('remove');
  }

  /**
   * Returns the value of translation message for key `removeAll`.
   * Default value: `Rimuovi tutto`
   */
  get removeAll(): string {
    return this._translations.get('removeAll');
  }

  /**
   * Returns the value of translation message for key `markAllRead`.
   * Default value: `Segna tutti come già letti`
   */
  get markAllRead(): string {
    return this._translations.get('markAllRead');
  }
}

export class I18n$Notification$Admin {

  private _translations: Translations = new Translations('notification.admin');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `adPendingAuthorization`.
   * Default value: `Ad pending for authorization`
   */
  get adPendingAuthorization(): string {
    return this._translations.get('adPendingAuthorization');
  }

  /**
   * Returns the value of translation message for key `applicationErrors`.
   * Default value: `Application errors`
   */
  get applicationErrors(): string {
    return this._translations.get('applicationErrors');
  }

  /**
   * Returns the value of translation message for key `externalPaymentExpired`.
   * Default value: `External payment expired`
   */
  get externalPaymentExpired(): string {
    return this._translations.get('externalPaymentExpired');
  }

  /**
   * Returns the value of translation message for key `externalPaymentPerformedFailed`.
   * Default value: `External payment could not be delivered`
   */
  get externalPaymentPerformedFailed(): string {
    return this._translations.get('externalPaymentPerformedFailed');
  }

  /**
   * Returns the value of translation message for key `generatedVouchersAboutToExpire`.
   * Default value: `Generated vouchers about to expire`
   */
  get generatedVouchersAboutToExpire(): string {
    return this._translations.get('generatedVouchersAboutToExpire');
  }

  /**
   * Returns the value of translation message for key `generatedVouchersExpired`.
   * Default value: `Generated vouchers expiration`
   */
  get generatedVouchersExpired(): string {
    return this._translations.get('generatedVouchersExpired');
  }

  /**
   * Returns the value of translation message for key `networkCreated`.
   * Default value: `Network created`
   */
  get networkCreated(): string {
    return this._translations.get('networkCreated');
  }

  /**
   * Returns the value of translation message for key `paymentAwaitingAuthorization`.
   * Default value: `Payments pending by authorization`
   */
  get paymentAwaitingAuthorization(): string {
    return this._translations.get('paymentAwaitingAuthorization');
  }

  /**
   * Returns the value of translation message for key `paymentPerformed`.
   * Default value: `Payments`
   */
  get paymentPerformed(): string {
    return this._translations.get('paymentPerformed');
  }

  /**
   * Returns the value of translation message for key `systemAlert`.
   * Default value: `System alerts`
   */
  get systemAlert(): string {
    return this._translations.get('systemAlert');
  }

  /**
   * Returns the value of translation message for key `userAlert`.
   * Default value: `User alerts`
   */
  get userAlert(): string {
    return this._translations.get('userAlert');
  }

  /**
   * Returns the value of translation message for key `userRegistration`.
   * Default value: `New registered users`
   */
  get userRegistration(): string {
    return this._translations.get('userRegistration');
  }

  /**
   * Returns the value of translation message for key `voucherBuyingAboutToExpire`.
   * Default value: `Voucher buying about to expire`
   */
  get voucherBuyingAboutToExpire(): string {
    return this._translations.get('voucherBuyingAboutToExpire');
  }
}

export class I18n$Notification$User {

  private _translations: Translations = new Translations('notification.user');

  private _nested = {
    account: new I18n$Notification$User$Account(),
    brokering: new I18n$Notification$User$Brokering(),
    buyer: new I18n$Notification$User$Buyer(),
    feedback: new I18n$Notification$User$Feedback(),
    personal: new I18n$Notification$User$Personal(),
    reference: new I18n$Notification$User$Reference(),
    seller: new I18n$Notification$User$Seller()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.account.defaultValues = defaultValues['account'];
    this._nested.brokering.defaultValues = defaultValues['brokering'];
    this._nested.buyer.defaultValues = defaultValues['buyer'];
    this._nested.feedback.defaultValues = defaultValues['feedback'];
    this._nested.personal.defaultValues = defaultValues['personal'];
    this._nested.reference.defaultValues = defaultValues['reference'];
    this._nested.seller.defaultValues = defaultValues['seller'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.account.initialize(values['account']);
    this._nested.brokering.initialize(values['brokering']);
    this._nested.buyer.initialize(values['buyer']);
    this._nested.feedback.initialize(values['feedback']);
    this._nested.personal.initialize(values['personal']);
    this._nested.reference.initialize(values['reference']);
    this._nested.seller.initialize(values['seller']);
  }

  /**
   * Returns the nested accessor for translation messages in `account`.
   */
  get account(): I18n$Notification$User$Account {
    return this._nested.account;
  }

  /**
   * Returns the nested accessor for translation messages in `brokering`.
   */
  get brokering(): I18n$Notification$User$Brokering {
    return this._nested.brokering;
  }

  /**
   * Returns the nested accessor for translation messages in `buyer`.
   */
  get buyer(): I18n$Notification$User$Buyer {
    return this._nested.buyer;
  }

  /**
   * Returns the nested accessor for translation messages in `feedback`.
   */
  get feedback(): I18n$Notification$User$Feedback {
    return this._nested.feedback;
  }

  /**
   * Returns the nested accessor for translation messages in `personal`.
   */
  get personal(): I18n$Notification$User$Personal {
    return this._nested.personal;
  }

  /**
   * Returns the nested accessor for translation messages in `reference`.
   */
  get reference(): I18n$Notification$User$Reference {
    return this._nested.reference;
  }

  /**
   * Returns the nested accessor for translation messages in `seller`.
   */
  get seller(): I18n$Notification$User$Seller {
    return this._nested.seller;
  }
}

export class I18n$Notification$User$Account {

  private _translations: Translations = new Translations('notification.user.account');

  private _nested = {
    operator: new I18n$Notification$User$Account$Operator()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.operator.defaultValues = defaultValues['operator'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.operator.initialize(values['operator']);
  }

  /**
   * Returns the value of translation message for key `allNonSmsPerformedPayments`.
   * Default value: `Payment performed`
   */
  get allNonSmsPerformedPayments(): string {
    return this._translations.get('allNonSmsPerformedPayments');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentCanceled`.
   * Default value: `Authorized payment canceled`
   */
  get authorizedPaymentCanceled(): string {
    return this._translations.get('authorizedPaymentCanceled');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentDenied`.
   * Default value: `Authorized payment denied`
   */
  get authorizedPaymentDenied(): string {
    return this._translations.get('authorizedPaymentDenied');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentExpired`.
   * Default value: `Authorized payment expired`
   */
  get authorizedPaymentExpired(): string {
    return this._translations.get('authorizedPaymentExpired');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentSucceeded`.
   * Default value: `Authorized payment successful`
   */
  get authorizedPaymentSucceeded(): string {
    return this._translations.get('authorizedPaymentSucceeded');
  }

  /**
   * Returns the value of translation message for key `boughtVouchersAboutToExpire`.
   * Default value: `Bought vouchers will expire in a few days`
   */
  get boughtVouchersAboutToExpire(): string {
    return this._translations.get('boughtVouchersAboutToExpire');
  }

  /**
   * Returns the value of translation message for key `boughtVouchersExpirationDateChanged`.
   * Default value: `Bought voucher expiration date was changed`
   */
  get boughtVouchersExpirationDateChanged(): string {
    return this._translations.get('boughtVouchersExpirationDateChanged');
  }

  /**
   * Returns the value of translation message for key `boughtVouchersExpired`.
   * Default value: `Bought voucher have expired`
   */
  get boughtVouchersExpired(): string {
    return this._translations.get('boughtVouchersExpired');
  }

  /**
   * Returns the value of translation message for key `externalPaymentExpired`.
   * Default value: `External payment expired`
   */
  get externalPaymentExpired(): string {
    return this._translations.get('externalPaymentExpired');
  }

  /**
   * Returns the value of translation message for key `externalPaymentPerformedFailed`.
   * Default value: `External payment could not be delivered`
   */
  get externalPaymentPerformedFailed(): string {
    return this._translations.get('externalPaymentPerformedFailed');
  }

  /**
   * Returns the value of translation message for key `externalPaymentReceivedFailed`.
   * Default value: `External payment could not be received`
   */
  get externalPaymentReceivedFailed(): string {
    return this._translations.get('externalPaymentReceivedFailed');
  }

  /**
   * Returns the value of translation message for key `incomingRecurringPaymentCanceled`.
   * Default value: `Incoming recurring payment was canceled`
   */
  get incomingRecurringPaymentCanceled(): string {
    return this._translations.get('incomingRecurringPaymentCanceled');
  }

  /**
   * Returns the value of translation message for key `incomingRecurringPaymentFailed`.
   * Default value: `Incoming recurring payment has failed`
   */
  get incomingRecurringPaymentFailed(): string {
    return this._translations.get('incomingRecurringPaymentFailed');
  }

  /**
   * Returns the value of translation message for key `incomingRecurringPaymentReceived`.
   * Default value: `Incoming recurring payment was received`
   */
  get incomingRecurringPaymentReceived(): string {
    return this._translations.get('incomingRecurringPaymentReceived');
  }

  /**
   * Returns the value of translation message for key `incomingScheduledPaymentCanceled`.
   * Default value: `Incoming scheduled payment canceled`
   */
  get incomingScheduledPaymentCanceled(): string {
    return this._translations.get('incomingScheduledPaymentCanceled');
  }

  /**
   * Returns the value of translation message for key `incomingScheduledPaymentFailed`.
   * Default value: `Incoming scheduled payment failed`
   */
  get incomingScheduledPaymentFailed(): string {
    return this._translations.get('incomingScheduledPaymentFailed');
  }

  /**
   * Returns the value of translation message for key `incomingScheduledPaymentReceived`.
   * Default value: `Scheduled payment received`
   */
  get incomingScheduledPaymentReceived(): string {
    return this._translations.get('incomingScheduledPaymentReceived');
  }

  /**
   * Returns the value of translation message for key `limitChange`.
   * Default value: `Account limit changed`
   */
  get limitChange(): string {
    return this._translations.get('limitChange');
  }

  /**
   * Returns the value of translation message for key `paymentAwaitingAuthorization`.
   * Default value: `Payment awaiting authorization`
   */
  get paymentAwaitingAuthorization(): string {
    return this._translations.get('paymentAwaitingAuthorization');
  }

  /**
   * Returns the value of translation message for key `paymentReceived`.
   * Default value: `Payment received`
   */
  get paymentReceived(): string {
    return this._translations.get('paymentReceived');
  }

  /**
   * Returns the value of translation message for key `paymentRequestCanceled`.
   * Default value: `Payment request canceled`
   */
  get paymentRequestCanceled(): string {
    return this._translations.get('paymentRequestCanceled');
  }

  /**
   * Returns the value of translation message for key `paymentRequestDenied`.
   * Default value: `Payment request denied`
   */
  get paymentRequestDenied(): string {
    return this._translations.get('paymentRequestDenied');
  }

  /**
   * Returns the value of translation message for key `paymentRequestExpirationDateChanged`.
   * Default value: `Payment request expiration date changed`
   */
  get paymentRequestExpirationDateChanged(): string {
    return this._translations.get('paymentRequestExpirationDateChanged');
  }

  /**
   * Returns the value of translation message for key `paymentRequestExpired`.
   * Default value: `Payment request expired`
   */
  get paymentRequestExpired(): string {
    return this._translations.get('paymentRequestExpired');
  }

  /**
   * Returns the value of translation message for key `paymentRequestProcessed`.
   * Default value: `Payment request processed`
   */
  get paymentRequestProcessed(): string {
    return this._translations.get('paymentRequestProcessed');
  }

  /**
   * Returns the value of translation message for key `paymentRequestReceived`.
   * Default value: `Richiesta di pagamento ricevuta`
   */
  get paymentRequestReceived(): string {
    return this._translations.get('paymentRequestReceived');
  }

  /**
   * Returns the value of translation message for key `recurringPaymentFailed`.
   * Default value: `Il pagamento ricorrente non è riuscito`
   */
  get recurringPaymentFailed(): string {
    return this._translations.get('recurringPaymentFailed');
  }

  /**
   * Returns the value of translation message for key `recurringPaymentOcurrenceProcessed`.
   * Default value: `Il pagamento ricorrente è stato elaborato`
   */
  get recurringPaymentOcurrenceProcessed(): string {
    return this._translations.get('recurringPaymentOcurrenceProcessed');
  }

  /**
   * Returns the value of translation message for key `scheduledPaymentFailed`.
   * Default value: `Pagamento pianificato fallito`
   */
  get scheduledPaymentFailed(): string {
    return this._translations.get('scheduledPaymentFailed');
  }

  /**
   * Returns the value of translation message for key `scheduledPaymentInstallmentProcessed`.
   * Default value: `La rate di pagamento pianificata è stata elaborata`
   */
  get scheduledPaymentInstallmentProcessed(): string {
    return this._translations.get('scheduledPaymentInstallmentProcessed');
  }

  /**
   * Returns the value of translation message for key `scheduledPaymentRequestFailed`.
   * Default value: `Richiesta di pagamento pianificata fallita`
   */
  get scheduledPaymentRequestFailed(): string {
    return this._translations.get('scheduledPaymentRequestFailed');
  }

  /**
   * Returns the value of translation message for key `sentPaymentRequestExpirationDateChanged`.
   * Default value: `Data di scadenza della richiesta di pagamento inviata modificata`
   */
  get sentPaymentRequestExpirationDateChanged(): string {
    return this._translations.get('sentPaymentRequestExpirationDateChanged');
  }

  /**
   * Returns the value of translation message for key `smsPerformedPayment`.
   * Default value: `Pagamento SMS eseguito`
   */
  get smsPerformedPayment(): string {
    return this._translations.get('smsPerformedPayment');
  }

  /**
   * Returns the value of translation message for key `ticketWebhookFailed`.
   * Default value: `L'approvazione del ticket non può essere notificata tramite webhook`
   */
  get ticketWebhookFailed(): string {
    return this._translations.get('ticketWebhookFailed');
  }

  /**
   * Returns the value of translation message for key `amountGraterThanCalculated`.
   * Default value: `Attenzione! L'importo non può essere maggiore di quello calcolato`
   */
  get amountGraterThanCalculated(): string {
    return this._translations.get('amountGraterThanCalculated');
  }

  /**
   * Returns the value of translation message for key `amountGraterThanFiatAmount`.
   * Default value: `Attenzione! L'importo non può essere maggiore di quello in euro`
   */
  get amountGraterThanFiatAmount(): string {
    return this._translations.get('amountGraterThanFiatAmount');
  }

  /**
   * Returns the value of translation message for key `amountLowerThanMin`.
   * Default value: `Attenzione! L'importo non può essere inferiore di quello minimo`
   */
  get amountLowerThanMin(): string {
    return this._translations.get('amountLowerThanMin');
  }

  /**
   * Returns the nested accessor for translation messages in `operator`.
   */
  get operator(): I18n$Notification$User$Account$Operator {
    return this._nested.operator;
  }
}

export class I18n$Notification$User$Account$Operator {

  private _translations: Translations = new Translations('notification.user.account.operator');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentApprovedStillPending`.
   * Default value: `Pagamento approvato da operatore ma ancora in attesa di ulteriore autorizzazione`
   */
  get authorizedPaymentApprovedStillPending(): string {
    return this._translations.get('authorizedPaymentApprovedStillPending');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentCanceled`.
   * Default value: `Il pagamento è stato annullato dall'operatore`
   */
  get authorizedPaymentCanceled(): string {
    return this._translations.get('authorizedPaymentCanceled');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentDenied`.
   * Default value: `Pending payment by operator was denied`
   */
  get authorizedPaymentDenied(): string {
    return this._translations.get('authorizedPaymentDenied');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentExpired`.
   * Default value: `Authorized payment by operator has expired`
   */
  get authorizedPaymentExpired(): string {
    return this._translations.get('authorizedPaymentExpired');
  }

  /**
   * Returns the value of translation message for key `authorizedPaymentSucceeded`.
   * Default value: `Pending payment by operator successful`
   */
  get authorizedPaymentSucceeded(): string {
    return this._translations.get('authorizedPaymentSucceeded');
  }

  /**
   * Returns the value of translation message for key `paymentAwaitingAuthorization`.
   * Default value: `Operator payment awaiting authorization`
   */
  get paymentAwaitingAuthorization(): string {
    return this._translations.get('paymentAwaitingAuthorization');
  }
}

export class I18n$Notification$User$Brokering {

  private _translations: Translations = new Translations('notification.user.brokering');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `adPendingAuthorization`.
   * Default value: `Ad is pending for authorization`
   */
  get adPendingAuthorization(): string {
    return this._translations.get('adPendingAuthorization');
  }

  /**
   * Returns the value of translation message for key `memberAssigned`.
   * Default value: `Member assigned`
   */
  get memberAssigned(): string {
    return this._translations.get('memberAssigned');
  }

  /**
   * Returns the value of translation message for key `memberUnassigned`.
   * Default value: `Member unassigned`
   */
  get memberUnassigned(): string {
    return this._translations.get('memberUnassigned');
  }
}

export class I18n$Notification$User$Buyer {

  private _translations: Translations = new Translations('notification.user.buyer');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `adInterestNotification`.
   * Default value: `New ad matching interest`
   */
  get adInterestNotification(): string {
    return this._translations.get('adInterestNotification');
  }

  /**
   * Returns the value of translation message for key `adQuestionAnswered`.
   * Default value: `Ad question answered`
   */
  get adQuestionAnswered(): string {
    return this._translations.get('adQuestionAnswered');
  }

  /**
   * Returns the value of translation message for key `orderCanceled`.
   * Default value: `Purchase order canceled`
   */
  get orderCanceled(): string {
    return this._translations.get('orderCanceled');
  }

  /**
   * Returns the value of translation message for key `orderPaymentCanceled`.
   * Default value: `Purchase order payment canceled`
   */
  get orderPaymentCanceled(): string {
    return this._translations.get('orderPaymentCanceled');
  }

  /**
   * Returns the value of translation message for key `orderPaymentDenied`.
   * Default value: `Purchase order payment denied`
   */
  get orderPaymentDenied(): string {
    return this._translations.get('orderPaymentDenied');
  }

  /**
   * Returns the value of translation message for key `orderPaymentExpired`.
   * Default value: `Purchase order payment expired`
   */
  get orderPaymentExpired(): string {
    return this._translations.get('orderPaymentExpired');
  }

  /**
   * Returns the value of translation message for key `orderPending`.
   * Default value: `Pending order`
   */
  get orderPending(): string {
    return this._translations.get('orderPending');
  }

  /**
   * Returns the value of translation message for key `orderPendingAuthorization`.
   * Default value: `Purchase order awaiting payment authorization`
   */
  get orderPendingAuthorization(): string {
    return this._translations.get('orderPendingAuthorization');
  }

  /**
   * Returns the value of translation message for key `orderPendingDeliveryData`.
   * Default value: `Order awaiting delivery information`
   */
  get orderPendingDeliveryData(): string {
    return this._translations.get('orderPendingDeliveryData');
  }

  /**
   * Returns the value of translation message for key `orderProcessedBySeller`.
   * Default value: `Order accepted by seller`
   */
  get orderProcessedBySeller(): string {
    return this._translations.get('orderProcessedBySeller');
  }

  /**
   * Returns the value of translation message for key `orderRejectedBySeller`.
   * Default value: `Order rejected by seller`
   */
  get orderRejectedBySeller(): string {
    return this._translations.get('orderRejectedBySeller');
  }

  /**
   * Returns the value of translation message for key `buyerSalePending`.
   * Default value: `Order created`
   */
  get buyerSalePending(): string {
    return this._translations.get('buyerSalePending');
  }

  /**
   * Returns the value of translation message for key `buyerSaleRejectedBySeller`.
   * Default value: `Sale order canceled`
   */
  get buyerSaleRejectedBySeller(): string {
    return this._translations.get('buyerSaleRejectedBySeller');
  }
}

export class I18n$Notification$User$Feedback {

  private _translations: Translations = new Translations('notification.user.feedback');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `changed`.
   * Default value: `Feedback changed`
   */
  get changed(): string {
    return this._translations.get('changed');
  }

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `Feedback received`
   */
  get created(): string {
    return this._translations.get('created');
  }

  /**
   * Returns the value of translation message for key `expirationReminder`.
   * Default value: `Feedback reminder`
   */
  get expirationReminder(): string {
    return this._translations.get('expirationReminder');
  }

  /**
   * Returns the value of translation message for key `optional`.
   * Default value: `Feedback optional`
   */
  get optional(): string {
    return this._translations.get('optional');
  }

  /**
   * Returns the value of translation message for key `replyCreated`.
   * Default value: `Feedback reply received`
   */
  get replyCreated(): string {
    return this._translations.get('replyCreated');
  }

  /**
   * Returns the value of translation message for key `required`.
   * Default value: `Feedback required`
   */
  get required(): string {
    return this._translations.get('required');
  }
}

export class I18n$Notification$User$Personal {

  private _translations: Translations = new Translations('notification.user.personal');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `brokerAssigned`.
   * Default value: `Broker assigned`
   */
  get brokerAssigned(): string {
    return this._translations.get('brokerAssigned');
  }

  /**
   * Returns the value of translation message for key `brokerUnassigned`.
   * Default value: `Broker unassigned`
   */
  get brokerUnassigned(): string {
    return this._translations.get('brokerUnassigned');
  }

  /**
   * Returns the value of translation message for key `maxSmsPerMonthReached`.
   * Default value: `Maximum SMS per month reached`
   */
  get maxSmsPerMonthReached(): string {
    return this._translations.get('maxSmsPerMonthReached');
  }

  /**
   * Returns the value of translation message for key `newToken`.
   * Default value: `New token / card`
   */
  get newToken(): string {
    return this._translations.get('newToken');
  }

  /**
   * Returns the value of translation message for key `newTokenPendingActivation`.
   * Default value: `New token / card pending activation`
   */
  get newTokenPendingActivation(): string {
    return this._translations.get('newTokenPendingActivation');
  }

  /**
   * Returns the value of translation message for key `passwordStatusChanged`.
   * Default value: `Password status changed`
   */
  get passwordStatusChanged(): string {
    return this._translations.get('passwordStatusChanged');
  }

  /**
   * Returns the value of translation message for key `tokenStatusChanged`.
   * Default value: `Token / card status changed`
   */
  get tokenStatusChanged(): string {
    return this._translations.get('tokenStatusChanged');
  }

  /**
   * Returns the value of translation message for key `userStatusChanged`.
   * Default value: `User status changed`
   */
  get userStatusChanged(): string {
    return this._translations.get('userStatusChanged');
  }
}

export class I18n$Notification$User$Reference {

  private _translations: Translations = new Translations('notification.user.reference');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `changed`.
   * Default value: `Reference changed`
   */
  get changed(): string {
    return this._translations.get('changed');
  }

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `Reference received`
   */
  get created(): string {
    return this._translations.get('created');
  }
}

export class I18n$Notification$User$Seller {

  private _translations: Translations = new Translations('notification.user.seller');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `adAuthorized`.
   * Default value: `Advertisement authorized`
   */
  get adAuthorized(): string {
    return this._translations.get('adAuthorized');
  }

  /**
   * Returns the value of translation message for key `adExpired`.
   * Default value: `Advertisement expired`
   */
  get adExpired(): string {
    return this._translations.get('adExpired');
  }

  /**
   * Returns the value of translation message for key `adLowStock`.
   * Default value: `Low quantity of article`
   */
  get adLowStock(): string {
    return this._translations.get('adLowStock');
  }

  /**
   * Returns the value of translation message for key `adOutOfStock`.
   * Default value: `Article out of stock`
   */
  get adOutOfStock(): string {
    return this._translations.get('adOutOfStock');
  }

  /**
   * Returns the value of translation message for key `adQuestionCreated`.
   * Default value: `New ad question`
   */
  get adQuestionCreated(): string {
    return this._translations.get('adQuestionCreated');
  }

  /**
   * Returns the value of translation message for key `adRejected`.
   * Default value: `Advertisement rejected`
   */
  get adRejected(): string {
    return this._translations.get('adRejected');
  }

  /**
   * Returns the value of translation message for key `orderCanceled`.
   * Default value: `Sale order canceled`
   */
  get orderCanceled(): string {
    return this._translations.get('orderCanceled');
  }

  /**
   * Returns the value of translation message for key `orderCreated`.
   * Default value: `New web shop order`
   */
  get orderCreated(): string {
    return this._translations.get('orderCreated');
  }

  /**
   * Returns the value of translation message for key `orderPaymentCanceled`.
   * Default value: `Sale order payment canceled`
   */
  get orderPaymentCanceled(): string {
    return this._translations.get('orderPaymentCanceled');
  }

  /**
   * Returns the value of translation message for key `orderPaymentDenied`.
   * Default value: `Sale order payment denied`
   */
  get orderPaymentDenied(): string {
    return this._translations.get('orderPaymentDenied');
  }

  /**
   * Returns the value of translation message for key `orderPaymentExpired`.
   * Default value: `Sale order payment expired`
   */
  get orderPaymentExpired(): string {
    return this._translations.get('orderPaymentExpired');
  }

  /**
   * Returns the value of translation message for key `orderPendingAuthorization`.
   * Default value: `Sale order awaiting payment authorization`
   */
  get orderPendingAuthorization(): string {
    return this._translations.get('orderPendingAuthorization');
  }

  /**
   * Returns the value of translation message for key `orderPendingDeliveryData`.
   * Default value: `Delivery information requested`
   */
  get orderPendingDeliveryData(): string {
    return this._translations.get('orderPendingDeliveryData');
  }

  /**
   * Returns the value of translation message for key `orderProcessedByBuyer`.
   * Default value: `Order accepted by buyer`
   */
  get orderProcessedByBuyer(): string {
    return this._translations.get('orderProcessedByBuyer');
  }

  /**
   * Returns the value of translation message for key `orderRejectedByBuyer`.
   * Default value: `Order rejected`
   */
  get orderRejectedByBuyer(): string {
    return this._translations.get('orderRejectedByBuyer');
  }

  /**
   * Returns the value of translation message for key `saleProcessedByBuyer`.
   * Default value: `Sale realized`
   */
  get saleProcessedByBuyer(): string {
    return this._translations.get('saleProcessedByBuyer');
  }
}

export class I18n$NotificationSettings {

  private _translations: Translations = new Translations('notificationSettings');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Notification settings`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Notification settings`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `notAvailableSettings`.
   * Default value: `You are not allowed to change any settings.`
   */
  get notAvailableSettings(): string {
    return this._translations.get('notAvailableSettings');
  }

  /**
   * Returns the value of translation message for key `forwardMessages`.
   * Default value: `Forward internal messages to your email address`
   */
  get forwardMessages(): string {
    return this._translations.get('forwardMessages');
  }

  /**
   * Returns the value of translation message for key `notifications`.
   * Default value: `Notifications`
   */
  get notifications(): string {
    return this._translations.get('notifications');
  }

  /**
   * Returns the value of translation message for key `internalNotification`.
   * Default value: `Internal notification`
   */
  get internalNotification(): string {
    return this._translations.get('internalNotification');
  }

  /**
   * Returns the value of translation message for key `accounts`.
   * Default value: `Accounts`
   */
  get accounts(): string {
    return this._translations.get('accounts');
  }

  /**
   * Returns the value of translation message for key `personal`.
   * Default value: `Personal`
   */
  get personal(): string {
    return this._translations.get('personal');
  }

  /**
   * Returns the value of translation message for key `payments`.
   * Default value: `Payments`
   */
  get payments(): string {
    return this._translations.get('payments');
  }

  /**
   * Returns the value of translation message for key `marketplaceAsBuyer`.
   * Default value: `Marketplace as buyer`
   */
  get marketplaceAsBuyer(): string {
    return this._translations.get('marketplaceAsBuyer');
  }

  /**
   * Returns the value of translation message for key `marketplaceAsSeller`.
   * Default value: `Marketplace as seller`
   */
  get marketplaceAsSeller(): string {
    return this._translations.get('marketplaceAsSeller');
  }

  /**
   * Returns the value of translation message for key `feedbackAndReferences`.
   * Default value: `Feedback and references`
   */
  get feedbackAndReferences(): string {
    return this._translations.get('feedbackAndReferences');
  }

  /**
   * Returns the value of translation message for key `smsCount`.
   * Default value: `You have used {count} of {total} free SMS messages this month.`
   */
  smsCount($: {count: string | number, total: string | number}): string {
    return this._translations.get('smsCount', {
      count: $.count,
      total: $.total
    });
  }

  /**
   * Returns the value of translation message for key `paymentNotifications.above`.
   * Default value: `Only notify payments above`
   */
  get paymentNotificationsAbove(): string {
    return this._translations.get('paymentNotifications.above');
  }

  /**
   * Returns the value of translation message for key `paymentNotifications.below`.
   * Default value: `Only notify payments below`
   */
  get paymentNotificationsBelow(): string {
    return this._translations.get('paymentNotifications.below');
  }

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The notification settings were saved`
   */
  get saved(): string {
    return this._translations.get('saved');
  }

  /**
   * Returns the value of translation message for key `enableOrDisable`.
   * Default value: `Enable or disable {section}`
   */
  enableOrDisable(section: string | number): string {
    return this._translations.get('enableOrDisable', {
      section: section
    });
  }

  /**
   * Returns the value of translation message for key `enableAll`.
   * Default value: `Enable all`
   */
  get enableAll(): string {
    return this._translations.get('enableAll');
  }

  /**
   * Returns the value of translation message for key `disableAll`.
   * Default value: `Disable all`
   */
  get disableAll(): string {
    return this._translations.get('disableAll');
  }
}

export class I18n$ConnectedUser {

  private _translations: Translations = new Translations('connectedUser');

  private _nested = {
    viewConnected: new I18n$ConnectedUser$ViewConnected()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.viewConnected.defaultValues = defaultValues['viewConnected'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.viewConnected.initialize(values['viewConnected']);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Utenti connessi`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Utenti connessi`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `loggedInAt`.
   * Default value: `Accesso effettuato il`
   */
  get loggedInAt(): string {
    return this._translations.get('loggedInAt');
  }

  /**
   * Returns the value of translation message for key `channel`.
   * Default value: `Canale`
   */
  get channel(): string {
    return this._translations.get('channel');
  }

  /**
   * Returns the value of translation message for key `ipAddress`.
   * Default value: `Indirizzo IP`
   */
  get ipAddress(): string {
    return this._translations.get('ipAddress');
  }

  /**
   * Returns the value of translation message for key `disconnect`.
   * Default value: `Disconnetti utente`
   */
  get disconnect(): string {
    return this._translations.get('disconnect');
  }

  /**
   * Returns the value of translation message for key `showConnected`.
   * Default value: `Mostra connesso`
   */
  get showConnected(): string {
    return this._translations.get('showConnected');
  }

  /**
   * Returns the value of translation message for key `disconnected`.
   * Default value: `{user} è stato disconnesso`
   */
  disconnected(user: string | number): string {
    return this._translations.get('disconnected', {
      user: user
    });
  }

  /**
   * Returns the nested accessor for translation messages in `viewConnected`.
   */
  get viewConnected(): I18n$ConnectedUser$ViewConnected {
    return this._nested.viewConnected;
  }
}

export class I18n$ConnectedUser$ViewConnected {

  private _translations: Translations = new Translations('connectedUser.viewConnected');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `operator`.
   * Default value: `Visualizza operatori connessi`
   */
  get operator(): string {
    return this._translations.get('operator');
  }

  /**
   * Returns the value of translation message for key `broker`.
   * Default value: `Visualizza broker connessi`
   */
  get broker(): string {
    return this._translations.get('broker');
  }

  /**
   * Returns the value of translation message for key `admin`.
   * Default value: `Visualizza amministratori connessi`
   */
  get admin(): string {
    return this._translations.get('admin');
  }

  /**
   * Returns the value of translation message for key `member`.
   * Default value: `Visualizza membri connessi`
   */
  get member(): string {
    return this._translations.get('member');
  }
}

export class I18n$IdentityProvider {

  private _translations: Translations = new Translations('identityProvider');

  private _nested = {
    title: new I18n$IdentityProvider$Title(),
    mobileTitle: new I18n$IdentityProvider$MobileTitle(),
    action: new I18n$IdentityProvider$Action()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
    this._nested.action.defaultValues = defaultValues['action'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
    this._nested.action.initialize(values['action']);
  }

  /**
   * Returns the value of translation message for key `popup`.
   * Default value: `Contacting {provider}. Please, wait...`
   */
  popup(provider: string | number): string {
    return this._translations.get('popup', {
      provider: provider
    });
  }

  /**
   * Returns the value of translation message for key `login`.
   * Default value: `Continue with <b>{provider}</b>`
   */
  login(provider: string | number): string {
    return this._translations.get('login', {
      provider: provider
    });
  }

  /**
   * Returns the value of translation message for key `login.noMatch`.
   * Default value: `No user found with e-mail {email}. You will need to login with your regular user and password. After logging-in, your {app} account will be automatically linked, so, on the next time, the 'Continue with {provider}' functionality will work directly.`
   */
  loginNoMatch($: {email: string | number, app: string | number, provider: string | number}): string {
    return this._translations.get('login.noMatch', {
      email: $.email,
      app: $.app,
      provider: $.provider
    });
  }

  /**
   * Returns the value of translation message for key `login.noEmail`.
   * Default value: `Couldn't read an e-mail address from your {provider} account. You will need to login with your regular user and password. After logging-in, your {app} account will be automatically linked, so, on the next time, the 'Continue with {provider}' functionality will work directly.`
   */
  loginNoEmail($: {provider: string | number, app: string | number}): string {
    return this._translations.get('login.noEmail', {
      provider: $.provider,
      app: $.app
    });
  }

  /**
   * Returns the value of translation message for key `registration`.
   * Default value: `Register faster with your identity provider:`
   */
  get registration(): string {
    return this._translations.get('registration');
  }

  /**
   * Returns the value of translation message for key `registration.or`.
   * Default value: `Or`
   */
  get registrationOr(): string {
    return this._translations.get('registration.or');
  }

  /**
   * Returns the value of translation message for key `registration.fillForm`.
   * Default value: `Fill-in the registration form`
   */
  get registrationFillForm(): string {
    return this._translations.get('registration.fillForm');
  }

  /**
   * Returns the value of translation message for key `registration.disclaimer`.
   * Default value: `<b>Disclaimer:</b> None of your {app} data will be shared with these providers, and the only purpose of reading your personal data from them is to fill in the registration form and logging you in after you're registered.`
   */
  registrationDisclaimer(app: string | number): string {
    return this._translations.get('registration.disclaimer', {
      app: app
    });
  }

  /**
   * Returns the value of translation message for key `disclaimer`.
   * Default value: `<b>Disclaimer</b>: None of your {app} data will be shared with any provider. The only data read from the providers are your display name and e-mail, with the sole purpose to logging-in faster on {app}.`
   */
  disclaimer(app: string | number): string {
    return this._translations.get('disclaimer', {
      app: app
    });
  }

  /**
   * Returns the value of translation message for key `connectedOn`.
   * Default value: `Connected on {date}`
   */
  connectedOn(date: string | number): string {
    return this._translations.get('connectedOn', {
      date: date
    });
  }

  /**
   * Returns the value of translation message for key `disconnected`.
   * Default value: `Disconnected`
   */
  get disconnected(): string {
    return this._translations.get('disconnected');
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$IdentityProvider$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$IdentityProvider$MobileTitle {
    return this._nested.mobileTitle;
  }

  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  get action(): I18n$IdentityProvider$Action {
    return this._nested.action;
  }
}

export class I18n$IdentityProvider$Title {

  private _translations: Translations = new Translations('identityProvider.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `manage.self`.
   * Default value: `Manage your identity providers`
   */
  get manageSelf(): string {
    return this._translations.get('manage.self');
  }

  /**
   * Returns the value of translation message for key `manage.user`.
   * Default value: `Manage user identity providers`
   */
  get manageUser(): string {
    return this._translations.get('manage.user');
  }
}

export class I18n$IdentityProvider$MobileTitle {

  private _translations: Translations = new Translations('identityProvider.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `manage.self`.
   * Default value: `Identity providers`
   */
  get manageSelf(): string {
    return this._translations.get('manage.self');
  }

  /**
   * Returns the value of translation message for key `manage.user`.
   * Default value: `Identity providers`
   */
  get manageUser(): string {
    return this._translations.get('manage.user');
  }
}

export class I18n$IdentityProvider$Action {

  private _translations: Translations = new Translations('identityProvider.action');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `connect`.
   * Default value: `Connect`
   */
  get connect(): string {
    return this._translations.get('connect');
  }

  /**
   * Returns the value of translation message for key `connect.done`.
   * Default value: `You can now login as {name} using {provider}`
   */
  connectDone($: {name: string | number, provider: string | number}): string {
    return this._translations.get('connect.done', {
      name: $.name,
      provider: $.provider
    });
  }

  /**
   * Returns the value of translation message for key `disconnect`.
   * Default value: `Disconnect`
   */
  get disconnect(): string {
    return this._translations.get('disconnect');
  }

  /**
   * Returns the value of translation message for key `disconnect.confirm`.
   * Default value: `This will disable the 'Continue with {provider}' functionality. Are you sure?`
   */
  disconnectConfirm(provider: string | number): string {
    return this._translations.get('disconnect.confirm', {
      provider: provider
    });
  }

  /**
   * Returns the value of translation message for key `disconnect.done`.
   * Default value: `The login with {provider} has been disabled`
   */
  disconnectDone(provider: string | number): string {
    return this._translations.get('disconnect.done', {
      provider: provider
    });
  }
}

export class I18n$Operation {

  private _translations: Translations = new Translations('operation');

  private _nested = {
    mobileTitle: new I18n$Operation$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `Utente`
   */
  get user(): string {
    return this._translations.get('user');
  }

  /**
   * Returns the value of translation message for key `ad`.
   * Default value: `Annunci`
   */
  get ad(): string {
    return this._translations.get('ad');
  }

  /**
   * Returns the value of translation message for key `transfer`.
   * Default value: `Trasferimenti`
   */
  get transfer(): string {
    return this._translations.get('transfer');
  }

  /**
   * Returns the value of translation message for key `fileUpload`.
   * Default value: `Carica file`
   */
  get fileUpload(): string {
    return this._translations.get('fileUpload');
  }

  /**
   * Returns the value of translation message for key `redirecting`.
   * Default value: `Stai per essere reindirizzato...`
   */
  get redirecting(): string {
    return this._translations.get('redirecting');
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Operation$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$Operation$MobileTitle {

  private _translations: Translations = new Translations('operation.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `result`.
   * Default value: `Risultato`
   */
  get result(): string {
    return this._translations.get('result');
  }
}

export class I18n$Setting {

  private _translations: Translations = new Translations('setting');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Impostazioni`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Impostazioni`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the value of translation message for key `theme`.
   * Default value: `Tema`
   */
  get theme(): string {
    return this._translations.get('theme');
  }

  /**
   * Returns the value of translation message for key `theme.light`.
   * Default value: `Chiaro`
   */
  get themeLight(): string {
    return this._translations.get('theme.light');
  }

  /**
   * Returns the value of translation message for key `theme.dark`.
   * Default value: `Scuro`
   */
  get themeDark(): string {
    return this._translations.get('theme.dark');
  }
}

export class I18n$Record {

  private _translations: Translations = new Translations('record');

  private _nested = {
    title: new I18n$Record$Title(),
    mobileTitle: new I18n$Record$MobileTitle()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.title.defaultValues = defaultValues['title'];
    this._nested.mobileTitle.defaultValues = defaultValues['mobileTitle'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.title.initialize(values['title']);
    this._nested.mobileTitle.initialize(values['mobileTitle']);
  }

  /**
   * Returns the value of translation message for key `createdBy`.
   * Default value: `Creato da`
   */
  get createdBy(): string {
    return this._translations.get('createdBy');
  }

  /**
   * Returns the value of translation message for key `lastModifiedBy`.
   * Default value: `Ultima modifica da`
   */
  get lastModifiedBy(): string {
    return this._translations.get('lastModifiedBy');
  }

  /**
   * Returns the value of translation message for key `lastModificationDate`.
   * Default value: `Ultima modifica il`
   */
  get lastModificationDate(): string {
    return this._translations.get('lastModificationDate');
  }

  /**
   * Returns the value of translation message for key `action`.
   * Default value: `{type} ({count})`
   */
  action($: {type: string | number, count: string | number}): string {
    return this._translations.get('action', {
      type: $.type,
      count: $.count
    });
  }

  /**
   * Returns the value of translation message for key `fields`.
   * Default value: `Campi`
   */
  get fields(): string {
    return this._translations.get('fields');
  }

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `Il {name} è stato creato`
   */
  created(name: string | number): string {
    return this._translations.get('created', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `{name} è stato salvato`
   */
  saved(name: string | number): string {
    return this._translations.get('saved', {
      name: name
    });
  }

  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  get title(): I18n$Record$Title {
    return this._nested.title;
  }

  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  get mobileTitle(): I18n$Record$MobileTitle {
    return this._nested.mobileTitle;
  }
}

export class I18n$Record$Title {

  private _translations: Translations = new Translations('record.title');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `{name} dettagli`
   */
  details(name: string | number): string {
    return this._translations.get('details', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Crea nuovo {name}`
   */
  new(name: string | number): string {
    return this._translations.get('new', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Modifica {name}`
   */
  edit(name: string | number): string {
    return this._translations.get('edit', {
      name: name
    });
  }
}

export class I18n$Record$MobileTitle {

  private _translations: Translations = new Translations('record.mobileTitle');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `{name} dettagli`
   */
  details(name: string | number): string {
    return this._translations.get('details', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Crea nuovo {name}`
   */
  new(name: string | number): string {
    return this._translations.get('new', {
      name: name
    });
  }

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Modifica {name}`
   */
  edit(name: string | number): string {
    return this._translations.get('edit', {
      name: name
    });
  }
}

export class I18n$UserAlert {

  private _translations: Translations = new Translations('userAlert');

  private _nested = {
    type: new I18n$UserAlert$Type()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.type.defaultValues = defaultValues['type'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.type.initialize(values['type']);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Cerca avvisi utente`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `text`.
   * Default value: `Testo`
   */
  get text(): string {
    return this._translations.get('text');
  }

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Avvisi utente`
   */
  get mobileTitle(): string {
    return this._translations.get('mobileTitle');
  }

  /**
   * Returns the nested accessor for translation messages in `type`.
   */
  get type(): I18n$UserAlert$Type {
    return this._nested.type;
  }
}

export class I18n$UserAlert$Type {

  private _translations: Translations = new Translations('userAlert.type');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Tipo`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `custom`.
   * Default value: `Personalizzato`
   */
  get custom(): string {
    return this._translations.get('custom');
  }

  /**
   * Returns the value of translation message for key `givenVeryBadRefs`.
   * Default value: `L'utente ha dato troppi feedback 'molto negativi'`
   */
  get givenVeryBadRefs(): string {
    return this._translations.get('givenVeryBadRefs');
  }

  /**
   * Returns the value of translation message for key `insufficientBalanceForInitialCredit`.
   * Default value: `Saldo insufficiente per il credito iniziale del conto`
   */
  get insufficientBalanceForInitialCredit(): string {
    return this._translations.get('insufficientBalanceForInitialCredit');
  }

  /**
   * Returns the value of translation message for key `maxDeviceActivationAttemptsReached`.
   * Default value: `Numero massimo di tentativi di attivazione raggiunto`
   */
  get maxDeviceActivationAttemptsReached(): string {
    return this._translations.get('maxDeviceActivationAttemptsReached');
  }

  /**
   * Returns the value of translation message for key `maxDeviceConfirmationCheckAttemptsReached`.
   * Default value: `Numero massimo di tentativi di verifica raggiunto`
   */
  get maxDeviceConfirmationCheckAttemptsReached(): string {
    return this._translations.get('maxDeviceConfirmationCheckAttemptsReached');
  }

  /**
   * Returns the value of translation message for key `maxTokenActivationAttemptsReached`.
   * Default value: `Numero massimo di tentativi di attivazione raggiunto`
   */
  get maxTokenActivationAttemptsReached(): string {
    return this._translations.get('maxTokenActivationAttemptsReached');
  }

  /**
   * Returns the value of translation message for key `maxUserLocalizationAttemptsReached`.
   * Default value: `Numero massimo di tentativi di localizzazione raggiunto`
   */
  get maxUserLocalizationAttemptsReached(): string {
    return this._translations.get('maxUserLocalizationAttemptsReached');
  }

  /**
   * Returns the value of translation message for key `maxVoucherRedeemAttemptsReached`.
   * Default value: `Numero massimo di tentativi di riscatto voucher raggiunto`
   */
  get maxVoucherRedeemAttemptsReached(): string {
    return this._translations.get('maxVoucherRedeemAttemptsReached');
  }

  /**
   * Returns the value of translation message for key `moveUserAutomaticallyFailed`.
   * Default value: `L'utente non è stato postato automaticamente di gruppo`
   */
  get moveUserAutomaticallyFailed(): string {
    return this._translations.get('moveUserAutomaticallyFailed');
  }

  /**
   * Returns the value of translation message for key `passwordDisabledByTries`.
   * Default value: `La password è bloccata indefinitamente per troppi tentativi`
   */
  get passwordDisabledByTries(): string {
    return this._translations.get('passwordDisabledByTries');
  }

  /**
   * Returns the value of translation message for key `passwordTemporarilyBlocked`.
   * Default value: `Password temporaneamente bloccata`
   */
  get passwordTemporarilyBlocked(): string {
    return this._translations.get('passwordTemporarilyBlocked');
  }

  /**
   * Returns the value of translation message for key `receivedVeryBadRefs`.
   * Default value: `Utente ha ricevuto troppi feedback 'molto negativi'`
   */
  get receivedVeryBadRefs(): string {
    return this._translations.get('receivedVeryBadRefs');
  }

  /**
   * Returns the value of translation message for key `scheduledPaymentFailed`.
   * Default value: `Pagamento pianificato fallito`
   */
  get scheduledPaymentFailed(): string {
    return this._translations.get('scheduledPaymentFailed');
  }
}

export class I18n$SystemAlert {

  private _translations: Translations = new Translations('systemAlert');

  private _nested = {
    type: new I18n$SystemAlert$Type()
  };

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
    this._nested.type.defaultValues = defaultValues['type'];
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
    this._nested.type.initialize(values['type']);
  }

  /**
   * Returns the nested accessor for translation messages in `type`.
   */
  get type(): I18n$SystemAlert$Type {
    return this._nested.type;
  }
}

export class I18n$SystemAlert$Type {

  private _translations: Translations = new Translations('systemAlert.type');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `accountFeeChargedNoFailures`.
   * Default value: `Account fee has finished without any failures`
   */
  get accountFeeChargedNoFailures(): string {
    return this._translations.get('accountFeeChargedNoFailures');
  }

  /**
   * Returns the value of translation message for key `accountFeeChargedWithFailures`.
   * Default value: `Account fee has finished with at least one failure`
   */
  get accountFeeChargedWithFailures(): string {
    return this._translations.get('accountFeeChargedWithFailures');
  }

  /**
   * Returns the value of translation message for key `applicationRestarted`.
   * Default value: `Application restarted`
   */
  get applicationRestarted(): string {
    return this._translations.get('applicationRestarted');
  }

  /**
   * Returns the value of translation message for key `custom`.
   * Default value: `Custom`
   */
  get custom(): string {
    return this._translations.get('custom');
  }

  /**
   * Returns the value of translation message for key `maxGlobalSmsReached`.
   * Default value: `The global maximum of SMS messages per month has been reached`
   */
  get maxGlobalSmsReached(): string {
    return this._translations.get('maxGlobalSmsReached');
  }

  /**
   * Returns the value of translation message for key `maxIncorrectLoginAttempts`.
   * Default value: `Max incorrect login attempts for invalid user`
   */
  get maxIncorrectLoginAttempts(): string {
    return this._translations.get('maxIncorrectLoginAttempts');
  }
}

export class I18n$Reports {

  private _translations: Translations = new Translations('reports');

  /**
   * Sets the default translations, that is, the values returned in case the specific
   * keys are not found.
   */
  set defaultValues(defaultValues: Object) {
    this._translations.defaultValues = defaultValues;
  }

  /**
   * Initializes the translation values.
   * @param values The translations values.
   */
  initialize(values: Object) {
    this._translations.initialize(values);
  }

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Report`
   */
  get title(): string {
    return this._translations.get('title');
  }

  /**
   * Returns the value of translation message for key `info`.
   * Default value: `Una volta aperto il report, è disponibile in basso a destra una freccia per il download dei dati.`
   */
  get info(): string {
    return this._translations.get('info');
  }

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Vedi`
   */
  get view(): string {
    return this._translations.get('view');
  }

  /**
   * Returns the value of translation message for key `dailyGraphic`.
   * Default value: `Grafico giornaliero`
   */
  get dailyGraphic(): string {
    return this._translations.get('dailyGraphic');
  }

  /**
   * Returns the value of translation message for key `totalTransactions`.
   * Default value: `Totale transazioni per arco temporale`
   */
  get totalTransactions(): string {
    return this._translations.get('totalTransactions');
  }

  /**
   * Returns the value of translation message for key `avgTransactions`.
   * Default value: `Transazione media per arco temporale`
   */
  get avgTransactions(): string {
    return this._translations.get('avgTransactions');
  }

  /**
   * Returns the value of translation message for key `companyTransactions`.
   * Default value: `Transato Marchex/Euro per azienda in un arco temporale`
   */
  get companyTransactions(): string {
    return this._translations.get('companyTransactions');
  }

  /**
   * Returns the value of translation message for key `provinceTransactions`.
   * Default value: `Transato per provincia in un arco temporale`
   */
  get provinceTransactions(): string {
    return this._translations.get('provinceTransactions');
  }

  /**
   * Returns the value of translation message for key `subcategoryTransactions`.
   * Default value: `Transato per sottocategoria in un arco temporale`
   */
  get subcategoryTransactions(): string {
    return this._translations.get('subcategoryTransactions');
  }

  /**
   * Returns the value of translation message for key `totalMoneySupply`.
   * Default value: `Massa monetaria complessiva (aggiornamento ad ogni mezzanotte)`
   */
  get totalMoneySupply(): string {
    return this._translations.get('totalMoneySupply');
  }

  /**
   * Returns the value of translation message for key `companyBrokers`.
   * Default value: `Broker per ogni azienda`
   */
  get companyBrokers(): string {
    return this._translations.get('companyBrokers');
  }

  /**
   * Returns the value of translation message for key `companiesList`.
   * Default value: `Lista aziende`
   */
  get companiesList(): string {
    return this._translations.get('companiesList');
  }
}

