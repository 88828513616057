/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExternalPaymentStatusEnum } from '../models/external-payment-status-enum';
import { PaymentRequestStatusEnum } from '../models/payment-request-status-enum';
import { RecurringPaymentStatusEnum } from '../models/recurring-payment-status-enum';
import { ScheduledPaymentStatusEnum } from '../models/scheduled-payment-status-enum';
import { TicketStatusEnum } from '../models/ticket-status-enum';
import { TransOrderByEnum } from '../models/trans-order-by-enum';
import { TransactionAuthorizationStatusEnum } from '../models/transaction-authorization-status-enum';
import { TransactionDataForSearch } from '../models/transaction-data-for-search';
import { TransactionKind } from '../models/transaction-kind';
import { TransactionOverviewDataForSearch } from '../models/transaction-overview-data-for-search';
import { TransactionOverviewResult } from '../models/transaction-overview-result';
import { TransactionResult } from '../models/transaction-result';
import { TransactionView } from '../models/transaction-view';
import { TransferDirectionEnum } from '../models/transfer-direction-enum';


/**
 * Provides access to transactions. A transaction is an intent to transfer balance between accounts. A transaction will generate zero or more balance transfers between accounts. For example, a direct payment beween 2 users can initially be pending administration authorization. Until it is authorized, no transfer will be generated. Once authorized, a transfer will be created. Another kind of transaction, a scheduled payment, will generate one transfer per processed installment. The kinds of transactions are:
 *
 * - __Direct payment__: A simple payment from / to a user or system
 *   account. Depending on the configuration, can require authorization
 *   to be processed.
 *
 * - __Scheduled payment__: A payment processed either on a single future
 *   date or in several installments. Depending on the configuration, can
 *   require authorization to be processed.
 *
 * - __Recurring payment__: A payment which is repeated for a fixed number
 *   of occurrences or until manually canceled. Depending on the
 * configuration,
 *   can require authorization to be processed.
 *
 * - __Chargeback__: A chargeback triggers a transfer with the same origin
 *   and destination as an original transfer, but with negative amount,
 *   effectively returning the balance to the original account.
 *
 * - __Payment request__: A payment filled-in by the payee. Once the payer
 *   accepts the reqiest, either a direct or scheduled payment is created
 *   (which could, depending on the configuration, be pending authorization).
 *
 * - __External payment__: A payment to a user not yet registered on the
 *   system. The payment amount is reserved to either an e-mail address or
 *   mobile phone number. Once a user registers using either data, the
 * payment
 *   will be performed.
 *
 * - __Import__: A transaction that was imported from file.
 * - __Ticket__: A payment filled-in by the payee but without a defined
 *   payer. Once a user (the payer) approces the ticket and the ticket owner
 *   (receiver) processes it, a direct payment is created (which could,
 *   depending on the configuration, be pending authorization).
 *
 * - __Order__: Payment generated by finishing the shopping cart checkout
 *   or confirming a webshop order. Depending on the configuration, can
 * require
 *   authorization to be processed.
 */
@Injectable({
  providedIn: 'root',
})
export class TransactionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTransactionsDataForSearch
   */
  static readonly GetTransactionsDataForSearchPath = '/{owner}/transactions/data-for-search';

  /**
   * Returns data which can be used to filter a transaction search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionsDataForSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionsDataForSearch$Response(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * Can be one of:
     *
     * - a user identification value, such as id, username, e-mail, phone, etc.
     *   Id is always allowed, others depend on Cyclos configuration. Note that
     *   a valid numeric value is always considered as id. For example, when
     *   using another identification method that can be numeric only, prefix\
     *   the value with a single quote (like in Excel spreadsheets);
     *
     * -  &#x60;self&#x60; for the currently authenticated user;
     * - &#x60;system&#x60; for the system owner.
     */
    owner: string;

  }): Observable<StrictHttpResponse<TransactionDataForSearch>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.GetTransactionsDataForSearchPath, 'get');
    if (params) {

      rb.query('fields', params.fields);
      rb.path('owner', params.owner);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionDataForSearch>;
      })
    );
  }

  /**
   * Returns data which can be used to filter a transaction search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTransactionsDataForSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionsDataForSearch(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * Can be one of:
     *
     * - a user identification value, such as id, username, e-mail, phone, etc.
     *   Id is always allowed, others depend on Cyclos configuration. Note that
     *   a valid numeric value is always considered as id. For example, when
     *   using another identification method that can be numeric only, prefix\
     *   the value with a single quote (like in Excel spreadsheets);
     *
     * -  &#x60;self&#x60; for the currently authenticated user;
     * - &#x60;system&#x60; for the system owner.
     */
    owner: string;

  }): Observable<TransactionDataForSearch> {

    return this.getTransactionsDataForSearch$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionDataForSearch>) => r.body as TransactionDataForSearch)
    );
  }

  /**
   * Path part for operation searchTransactions
   */
  static readonly SearchTransactionsPath = '/{owner}/transactions';

  /**
   * Returns the transactions of a given account owner that match the specified criteria. Each result will will be relative to this owner. The amount may be positive or negative, depending on whether this owner has performed or received the transaction.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTransactions$Response(params: {

    /**
     * Can be one of:
     *
     * - a user identification value, such as id, username, e-mail, phone, etc.
     *   Id is always allowed, others depend on Cyclos configuration. Note that
     *   a valid numeric value is always considered as id. For example, when
     *   using another identification method that can be numeric only, prefix\
     *   the value with a single quote (like in Excel spreadsheets);
     *
     * -  &#x60;self&#x60; for the currently authenticated user;
     * - &#x60;system&#x60; for the system owner.
     */
    owner: string;

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The account types
     */
    accountTypes?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;
    direction?: TransferDirectionEnum;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<StrictHttpResponse<Array<TransactionResult>>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.SearchTransactionsPath, 'get');
    if (params) {

      rb.path('owner', params.owner);
      rb.query('fields', params.fields);
      rb.query('accessClients', params.accessClients);
      rb.query('accountTypes', params.accountTypes);
      rb.query('amountRange', params.amountRange);
      rb.query('authorizationPerformedBy', params.authorizationPerformedBy);
      rb.query('authorizationStatuses', params.authorizationStatuses);
      rb.query('authorized', params.authorized);
      rb.query('broker', params.broker);
      rb.query('brokers', params.brokers);
      rb.query('by', params.by);
      rb.query('channels', params.channels);
      rb.query('datePeriod', params.datePeriod);
      rb.query('direction', params.direction);
      rb.query('excludedIds', params.excludedIds);
      rb.query('externalPaymentStatuses', params.externalPaymentStatuses);
      rb.query('fromCurrentAccessClient', params.fromCurrentAccessClient);
      rb.query('groups', params.groups);
      rb.query('includeGeneratedByAccessClient', params.includeGeneratedByAccessClient);
      rb.query('kinds', params.kinds);
      rb.query('orderBy', params.orderBy);
      rb.query('page', params.page);
      rb.query('pageSize', params.pageSize);
      rb.query('paymentRequestExpiration', params.paymentRequestExpiration);
      rb.query('paymentRequestStatuses', params.paymentRequestStatuses);
      rb.query('recurringPaymentStatuses', params.recurringPaymentStatuses);
      rb.query('scheduledPaymentStatuses', params.scheduledPaymentStatuses);
      rb.query('skipTotalCount', params.skipTotalCount);
      rb.query('ticketStatuses', params.ticketStatuses);
      rb.query('transactionNumber', params.transactionNumber);
      rb.query('transferFilters', params.transferFilters);
      rb.query('transferTypes', params.transferTypes);
      rb.query('user', params.user);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TransactionResult>>;
      })
    );
  }

  /**
   * Returns the transactions of a given account owner that match the specified criteria. Each result will will be relative to this owner. The amount may be positive or negative, depending on whether this owner has performed or received the transaction.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTransactions(params: {

    /**
     * Can be one of:
     *
     * - a user identification value, such as id, username, e-mail, phone, etc.
     *   Id is always allowed, others depend on Cyclos configuration. Note that
     *   a valid numeric value is always considered as id. For example, when
     *   using another identification method that can be numeric only, prefix\
     *   the value with a single quote (like in Excel spreadsheets);
     *
     * -  &#x60;self&#x60; for the currently authenticated user;
     * - &#x60;system&#x60; for the system owner.
     */
    owner: string;

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The account types
     */
    accountTypes?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;
    direction?: TransferDirectionEnum;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<Array<TransactionResult>> {

    return this.searchTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TransactionResult>>) => r.body as Array<TransactionResult>)
    );
  }

  /**
   * Path part for operation exportTransactions
   */
  static readonly ExportTransactionsPath = '/{owner}/transactions/export/{format}';

  /**
   * Generates a file containing the search results. The available export formats are returned in `GET /{owner}/transactions/data-for-search`.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportTransactions$Response(params: {

    /**
     * Can be one of:
     *
     * - a user identification value, such as id, username, e-mail, phone, etc.
     *   Id is always allowed, others depend on Cyclos configuration. Note that
     *   a valid numeric value is always considered as id. For example, when
     *   using another identification method that can be numeric only, prefix\
     *   the value with a single quote (like in Excel spreadsheets);
     *
     * -  &#x60;self&#x60; for the currently authenticated user;
     * - &#x60;system&#x60; for the system owner.
     */
    owner: string;

    /**
     * The format to export the data
     */
    format: string;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The account types
     */
    accountTypes?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;
    direction?: TransferDirectionEnum;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.ExportTransactionsPath, 'get');
    if (params) {

      rb.path('owner', params.owner);
      rb.path('format', params.format);
      rb.query('accessClients', params.accessClients);
      rb.query('accountTypes', params.accountTypes);
      rb.query('amountRange', params.amountRange);
      rb.query('authorizationPerformedBy', params.authorizationPerformedBy);
      rb.query('authorizationStatuses', params.authorizationStatuses);
      rb.query('authorized', params.authorized);
      rb.query('broker', params.broker);
      rb.query('brokers', params.brokers);
      rb.query('by', params.by);
      rb.query('channels', params.channels);
      rb.query('datePeriod', params.datePeriod);
      rb.query('direction', params.direction);
      rb.query('excludedIds', params.excludedIds);
      rb.query('externalPaymentStatuses', params.externalPaymentStatuses);
      rb.query('fromCurrentAccessClient', params.fromCurrentAccessClient);
      rb.query('groups', params.groups);
      rb.query('includeGeneratedByAccessClient', params.includeGeneratedByAccessClient);
      rb.query('kinds', params.kinds);
      rb.query('orderBy', params.orderBy);
      rb.query('page', params.page);
      rb.query('pageSize', params.pageSize);
      rb.query('paymentRequestExpiration', params.paymentRequestExpiration);
      rb.query('paymentRequestStatuses', params.paymentRequestStatuses);
      rb.query('recurringPaymentStatuses', params.recurringPaymentStatuses);
      rb.query('scheduledPaymentStatuses', params.scheduledPaymentStatuses);
      rb.query('skipTotalCount', params.skipTotalCount);
      rb.query('ticketStatuses', params.ticketStatuses);
      rb.query('transactionNumber', params.transactionNumber);
      rb.query('transferFilters', params.transferFilters);
      rb.query('transferTypes', params.transferTypes);
      rb.query('user', params.user);

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates a file containing the search results. The available export formats are returned in `GET /{owner}/transactions/data-for-search`.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportTransactions(params: {

    /**
     * Can be one of:
     *
     * - a user identification value, such as id, username, e-mail, phone, etc.
     *   Id is always allowed, others depend on Cyclos configuration. Note that
     *   a valid numeric value is always considered as id. For example, when
     *   using another identification method that can be numeric only, prefix\
     *   the value with a single quote (like in Excel spreadsheets);
     *
     * -  &#x60;self&#x60; for the currently authenticated user;
     * - &#x60;system&#x60; for the system owner.
     */
    owner: string;

    /**
     * The format to export the data
     */
    format: string;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The account types
     */
    accountTypes?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;
    direction?: TransferDirectionEnum;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<Blob> {

    return this.exportTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getTransactionsOverviewDataForSearch
   */
  static readonly GetTransactionsOverviewDataForSearchPath = '/transactions/data-for-search';

  /**
   * Returns data which can be used to filter a transaction search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionsOverviewDataForSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionsOverviewDataForSearch$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * When set to true will search for transactions which are currently pending authorization, and the authenticated user can authorize.
     */
    pendingMyAuthorization?: boolean;

  }): Observable<StrictHttpResponse<TransactionOverviewDataForSearch>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.GetTransactionsOverviewDataForSearchPath, 'get');
    if (params) {

      rb.query('fields', params.fields);
      rb.query('pendingMyAuthorization', params.pendingMyAuthorization);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionOverviewDataForSearch>;
      })
    );
  }

  /**
   * Returns data which can be used to filter a transaction search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTransactionsOverviewDataForSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionsOverviewDataForSearch(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * When set to true will search for transactions which are currently pending authorization, and the authenticated user can authorize.
     */
    pendingMyAuthorization?: boolean;

  }): Observable<TransactionOverviewDataForSearch> {

    return this.getTransactionsOverviewDataForSearch$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionOverviewDataForSearch>) => r.body as TransactionOverviewDataForSearch)
    );
  }

  /**
   * Path part for operation searchTransactionsOverview
   */
  static readonly SearchTransactionsOverviewPath = '/transactions';

  /**
   * Searches transactions regardless of a owner that match the specified criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTransactionsOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTransactionsOverview$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The currencies internal names or ids.
     */
    currencies?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    fromAccountTypes?: Array<string>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * When set to true will only return transactions (&#x60;payment&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;) in pending authorization state that the logged user can authorize
     */
    pendingMyAuthorization?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    toAccountTypes?: Array<string>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<StrictHttpResponse<Array<TransactionOverviewResult>>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.SearchTransactionsOverviewPath, 'get');
    if (params) {

      rb.query('fields', params.fields);
      rb.query('accessClients', params.accessClients);
      rb.query('amountRange', params.amountRange);
      rb.query('authorizationPerformedBy', params.authorizationPerformedBy);
      rb.query('authorizationStatuses', params.authorizationStatuses);
      rb.query('authorized', params.authorized);
      rb.query('broker', params.broker);
      rb.query('brokers', params.brokers);
      rb.query('by', params.by);
      rb.query('channels', params.channels);
      rb.query('currencies', params.currencies);
      rb.query('datePeriod', params.datePeriod);
      rb.query('excludedIds', params.excludedIds);
      rb.query('externalPaymentStatuses', params.externalPaymentStatuses);
      rb.query('fromAccountTypes', params.fromAccountTypes);
      rb.query('fromCurrentAccessClient', params.fromCurrentAccessClient);
      rb.query('groups', params.groups);
      rb.query('includeGeneratedByAccessClient', params.includeGeneratedByAccessClient);
      rb.query('kinds', params.kinds);
      rb.query('orderBy', params.orderBy);
      rb.query('page', params.page);
      rb.query('pageSize', params.pageSize);
      rb.query('paymentRequestExpiration', params.paymentRequestExpiration);
      rb.query('paymentRequestStatuses', params.paymentRequestStatuses);
      rb.query('pendingMyAuthorization', params.pendingMyAuthorization);
      rb.query('recurringPaymentStatuses', params.recurringPaymentStatuses);
      rb.query('scheduledPaymentStatuses', params.scheduledPaymentStatuses);
      rb.query('skipTotalCount', params.skipTotalCount);
      rb.query('ticketStatuses', params.ticketStatuses);
      rb.query('toAccountTypes', params.toAccountTypes);
      rb.query('transactionNumber', params.transactionNumber);
      rb.query('transferFilters', params.transferFilters);
      rb.query('transferTypes', params.transferTypes);
      rb.query('user', params.user);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TransactionOverviewResult>>;
      })
    );
  }

  /**
   * Searches transactions regardless of a owner that match the specified criteria.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchTransactionsOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTransactionsOverview(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The currencies internal names or ids.
     */
    currencies?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    fromAccountTypes?: Array<string>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * When set to true will only return transactions (&#x60;payment&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;) in pending authorization state that the logged user can authorize
     */
    pendingMyAuthorization?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    toAccountTypes?: Array<string>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<Array<TransactionOverviewResult>> {

    return this.searchTransactionsOverview$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TransactionOverviewResult>>) => r.body as Array<TransactionOverviewResult>)
    );
  }

  /**
   * Path part for operation exportTransactionsOverview
   */
  static readonly ExportTransactionsOverviewPath = '/transactions/export/{format}';

  /**
   * Generates a file containing the search results. The available export formats are returned in `GET /transactions/data-for-search`.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTransactionsOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportTransactionsOverview$Response(params: {

    /**
     * The format to export the data
     */
    format: string;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The currencies internal names or ids.
     */
    currencies?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    fromAccountTypes?: Array<string>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * When set to true will only return transactions (&#x60;payment&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;) in pending authorization state that the logged user can authorize
     */
    pendingMyAuthorization?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    toAccountTypes?: Array<string>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.ExportTransactionsOverviewPath, 'get');
    if (params) {

      rb.path('format', params.format);
      rb.query('accessClients', params.accessClients);
      rb.query('amountRange', params.amountRange);
      rb.query('authorizationPerformedBy', params.authorizationPerformedBy);
      rb.query('authorizationStatuses', params.authorizationStatuses);
      rb.query('authorized', params.authorized);
      rb.query('broker', params.broker);
      rb.query('brokers', params.brokers);
      rb.query('by', params.by);
      rb.query('channels', params.channels);
      rb.query('currencies', params.currencies);
      rb.query('datePeriod', params.datePeriod);
      rb.query('excludedIds', params.excludedIds);
      rb.query('externalPaymentStatuses', params.externalPaymentStatuses);
      rb.query('fromAccountTypes', params.fromAccountTypes);
      rb.query('fromCurrentAccessClient', params.fromCurrentAccessClient);
      rb.query('groups', params.groups);
      rb.query('includeGeneratedByAccessClient', params.includeGeneratedByAccessClient);
      rb.query('kinds', params.kinds);
      rb.query('orderBy', params.orderBy);
      rb.query('page', params.page);
      rb.query('pageSize', params.pageSize);
      rb.query('paymentRequestExpiration', params.paymentRequestExpiration);
      rb.query('paymentRequestStatuses', params.paymentRequestStatuses);
      rb.query('pendingMyAuthorization', params.pendingMyAuthorization);
      rb.query('recurringPaymentStatuses', params.recurringPaymentStatuses);
      rb.query('scheduledPaymentStatuses', params.scheduledPaymentStatuses);
      rb.query('skipTotalCount', params.skipTotalCount);
      rb.query('ticketStatuses', params.ticketStatuses);
      rb.query('toAccountTypes', params.toAccountTypes);
      rb.query('transactionNumber', params.transactionNumber);
      rb.query('transferFilters', params.transferFilters);
      rb.query('transferTypes', params.transferTypes);
      rb.query('user', params.user);

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates a file containing the search results. The available export formats are returned in `GET /transactions/data-for-search`.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportTransactionsOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportTransactionsOverview(params: {

    /**
     * The format to export the data
     */
    format: string;

    /**
     * References to access clients (id or token) used to perform / receive the transfer.
     */
    accessClients?: Array<string>;

    /**
     * The minimum / maximum amount. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    amountRange?: Array<string>;

    /**
     * Id or other identifier (login name, email, etc) of the user that performed an authorization action (authorize, deny or cancel).
     */
    authorizationPerformedBy?: string;

    /**
     * Authorization statuses used as search criteria. When set, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorizationStatuses?: Array<TransactionAuthorizationStatusEnum>;

    /**
     * When set, will only return transactions that went through the authorization process (if true) or that never went through it (when false). In either case, only kinds that can go through authorization are returned (&#x60;payment&#x60;, &#x60;order&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;).
     */
    authorized?: boolean;

    /**
     * Use &#x60;brokers&#x60; instead.
     *
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    broker?: string;

    /**
     * Reference to the broker of users involved in transfers. Is only taken into account if authenticated as administrator.
     */
    brokers?: Array<string>;

    /**
     * Reference to the user that was authenticated when the transfer was performed. Is only taken into account if authenticated as administrator.
     */
    by?: string;

    /**
     * Reference to the channel used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    channels?: Array<string>;

    /**
     * The currencies internal names or ids.
     */
    currencies?: Array<string>;

    /**
     * The minimum / maximum transfer date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    datePeriod?: Array<string>;

    /**
     * List of transfers ids to be excluded from the result.
     */
    excludedIds?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;externalPayment&#x60;.
     */
    externalPaymentStatuses?: Array<ExternalPaymentStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    fromAccountTypes?: Array<string>;

    /**
     * Flag indicating whether to include only transfers by the current access client.
     */
    fromCurrentAccessClient?: boolean;

    /**
     * Reference to the user group used to perform / receive the transfer. Only taken into account if authenticated as administrator.
     */
    groups?: Array<string>;

    /**
     * Flag indicating whether to include or not the generated transfer. Only valid if there is at least one access client specified. For example if a &#x60;ticket&#x60; or &#x60;paymentRequest&#x60; was processed then a new transfer will be generated.
     */
    includeGeneratedByAccessClient?: boolean;
    kinds?: Array<TransactionKind>;
    orderBy?: TransOrderByEnum;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date for payment request expiration. Only affects payment requests. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    paymentRequestExpiration?: Array<string>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;paymentRequest&#x60;.
     */
    paymentRequestStatuses?: Array<PaymentRequestStatusEnum>;

    /**
     * When set to true will only return transactions (&#x60;payment&#x60;, &#x60;recurringPayment&#x60; or &#x60;scheduledPayment&#x60;) in pending authorization state that the logged user can authorize
     */
    pendingMyAuthorization?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;recurringPayment&#x60;. If this filter is not empty then pending recurring payments will be excluded from the result. Pending recurring payments does not have a status.
     */
    recurringPaymentStatuses?: Array<RecurringPaymentStatusEnum>;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;scheduledPayment&#x60;. If this filter is not empty then pending scheduled payments will be excluded from the result. Pending scheduled payments does not have a status.
     */
    scheduledPaymentStatuses?: Array<ScheduledPaymentStatusEnum>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Statuses used as search criteria applied only to transactions of kind &#x60;ticket&#x60;.
     */
    ticketStatuses?: Array<TicketStatusEnum>;

    /**
     * The source account types internal names or ids.
     */
    toAccountTypes?: Array<string>;

    /**
     * The transaction number of the matching transfer
     */
    transactionNumber?: string;

    /**
     * Reference to the transfer filters, which filters transfers by type. May be either the internal id or qualified transfer filter internal name, in the format &#x60;accountType.transferFilter&#x60;.
     */
    transferFilters?: Array<string>;

    /**
     * Reference to the transfer types for filter. May be either the internal id or qualified transfer type internal name, in the format &#x60;accountType.transferType&#x60;.
     */
    transferTypes?: Array<string>;

    /**
     * Reference a user that should have either received / performed the transfer.
     */
    user?: string;

  }): Observable<Blob> {

    return this.exportTransactionsOverview$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation viewTransaction
   */
  static readonly ViewTransactionPath = '/transactions/{key}';

  /**
   * Returns details about a transaction.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewTransaction()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewTransaction$Response(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * Either the id or transaction number
     */
    key: string;

    /**
     * The payment origin account. Either the string &#x60;system&#x60; for a payment from system or a user identification.
     */
    selectedAccountId?: string;

  }): Observable<StrictHttpResponse<TransactionView>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.ViewTransactionPath, 'get');
    if (params) {

      rb.query('fields', params.fields);
      rb.path('key', params.key);
      rb.query('selectedAccountId', params.selectedAccountId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionView>;
      })
    );
  }

  /**
   * Returns details about a transaction.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewTransaction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewTransaction(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * Either the id or transaction number
     */
    key: string;

    /**
     * The payment origin account. Either the string &#x60;system&#x60; for a payment from system or a user identification.
     */
    selectedAccountId?: string;

  }): Observable<TransactionView> {

    return this.viewTransaction$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionView>) => r.body as TransactionView)
    );
  }

  /**
   * Path part for operation exportTransaction
   */
  static readonly ExportTransactionPath = '/transactions/{key}/export/{format}';

  /**
   * Exports the transaction details to a file. The available formats are available in `TransactionView`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTransaction()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportTransaction$Response(params: {

    /**
     * Either the id or transaction number
     */
    key: string;

    /**
     * The format to export the data
     */
    format: string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, TransactionsService.ExportTransactionPath, 'get');
    if (params) {

      rb.path('key', params.key);
      rb.path('format', params.format);

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Exports the transaction details to a file. The available formats are available in `TransactionView`
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportTransaction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportTransaction(params: {

    /**
     * Either the id or transaction number
     */
    key: string;

    /**
     * The format to export the data
     */
    format: string;

  }): Observable<Blob> {

    return this.exportTransaction$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
