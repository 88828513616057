import {
  Component, Output, EventEmitter, ChangeDetectionStrategy, SkipSelf, Host,
  Optional, ViewChild, ElementRef, Injector
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseFormFieldComponent } from 'app/shared/base-form-field.component';
import { OnInit } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';


/**
 * Component used to view and input new chips.
 */
@Component({
  selector: 'mat-chips-input',
  templateUrl: 'mat-chips-input.component.html',
  styleUrls: ['mat-chips-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: MatChipsInputComponent, multi: true }
  ]
})
export class MatChipsInputComponent
    extends BaseFormFieldComponent<string> implements OnInit{

  @Output() private onFormGroupChange = new EventEmitter<any>();

  @ViewChild('input', { static: false }) inputRef: ElementRef;

  /** HTML text message when adding chips **/
  textBox: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
      injector: Injector,
      @Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
    super(injector, controlContainer);
  }

  ngOnInit() {
    if (this.formControl == null) {
      this.formControl = new FormControl();
    }
    this.textBox = this.i18n.general.add  + " " + this.translatedName(this.name);
    this.onFormGroupChange.emit(this.formControl);
  }

  separatedValues(): string[] {
    return this.formControl.value ? this.formControl.value.split(' | ') : null;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.formControl.value == null || !this.formControl.value) {
        this.formControl.patchValue(value.trim());
      } else {
        this.formControl.patchValue(this.formControl.value.concat(" | " + value.trim()));
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(keywords: string): void {
    if (this.formControl.value.indexOf('|') > -1) {
      this.formControl.patchValue(this.formControl.value.replace(" | " + keywords, ""));
      this.formControl.patchValue(this.formControl.value.replace(keywords + " | ", ""));
    } else {
      this.formControl.patchValue(this.formControl.value.replace(keywords, ""));
    }
  }

  translatedName(name: string): string {
    switch (name) {
      case 'keywords': return this.i18n.account.keywords.toLowerCase();
      default: return name;
    }
  }

  protected getFocusableControl() {
    return (<any>(this.inputRef || {})).nativeElement;
  }

  protected getDisabledValue(): string {
    return this.value;
  }
}
