/* tslint:disable */

/**
 * The kind of a transaction
 * Possible values are:
 * - `chargeback`: Chargeback of a given transfer
 * - `externalPayment`: A payment to an external user
 * - `import`: An imported transaction
 * - `order`: Transaction generated by confirming an order
 * - `payment`: A direct payment
 * - `paymentRequest`: A request for another user to accept a payment
 * - `recurringPayment`: A payment which is processed again periodically
 * - `scheduledPayment`: A scheduled payment which is either a payment scheduled for a future date or has multiple installments
 * - `ticket`: A payment whose the payer is unknown
 */
export enum TransactionKind {
  CHARGEBACK = 'chargeback',
  EXTERNAL_PAYMENT = 'externalPayment',
  IMPORT = 'import',
  ORDER = 'order',
  PAYMENT = 'payment',
  PAYMENT_REQUEST = 'paymentRequest',
  RECURRING_PAYMENT = 'recurringPayment',
  SCHEDULED_PAYMENT = 'scheduledPayment',
  TICKET = 'ticket'
}
