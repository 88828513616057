import { Component, OnInit } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";
import { ReportComponent } from '../report/report.component';
import { AbstractComponent } from '../../shared/abstract.component';
import { Injector } from '@angular/core';
import { SecurityContext } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent extends AbstractComponent implements OnInit {

  metabaseUrl: string;

  constructor(
      injector: Injector,
      private modal: BsModalService,
      private sanitizer: DomSanitizer
  ) { super (injector)}

  ngOnInit() {
    this.metabaseUrl = environment.metabaseUrl;
  }

  openReport(name: string, url: string) {
    this.modal.show(ReportComponent, {
      class: 'modal-xl',
      initialState: {
        name: name,
        url: url
      }
    });
  }

  safeUrl(url: string) {
    return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(url));
  }
}
